import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Message, Button } from "semantic-ui-react";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { resetTempPassword } from "../actions/user";

const ResetTempPasswordModal = ({}) => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.user.loading);
    const open = useSelector((state) => state.user.isTempPassword);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.RESET_PASSWORD));

    const [formState, setFormState] = React.useState({
        password: "",
        confirmPassword: "",
    });

    let err;

    return (
        <Modal
            open={open}
            className="login-modal"
        >
            <Modal.Header>Reset Your Password</Modal.Header>
            <Modal.Content>
                <p>You are using a temporary password and must reset it before continuing</p>
                <Form error={getFormError(errors)} className="login-form">
                    <Form.Input
                        icon="lock"
                        iconPosition="left"
                        label="Password"
                        placeholder="Password"
                        type="Password"
                        value={formState.password}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, password: value }))}
                        error={(err = getFieldError("password", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <Form.Input
                        icon="lock"
                        iconPosition="left"
                        label="Confirm Password"
                        placeholder="Confirm Password"
                        type="Password"
                        value={formState.confirmPassword}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, confirmPassword: value }))}
                        error={(err = getFieldError("confirmPassword", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />


                    <Message
                        error
                        header="Login Error"
                        content={getFormError(errors)}
                    />

                    <Button
                        primary
                        content="Submit"
                        size="big"
                        onClick={() => dispatch(resetTempPassword(formState))}
                        loading={loading}
                        style={{ width: "100%" }}
                    />

                </Form>
            </Modal.Content>
        </Modal>
    );
};

export default ResetTempPasswordModal;
