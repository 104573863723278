import React, { useEffect } from "react";
import {
    Item,
    Button,
    Form,
    Dropdown,
} from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";

import AccountInfoForm from "../components/AccountInfoForm";
import KeyValue from "./KeyValue";

import { AlertType, ErrorTag } from "../utils/enums";
import { adminUpdateUser, getCompetitionUsers } from "../actions/user";
import { clearErrors } from "../actions/errors";
import { getFieldError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { openAdminResetPasswordModal, openAlert } from "../actions/ui";
import { useResendParentEmail } from "../queries/resend-parent-email";

const UserItem = ({ user, competitionList, selectedCompetition, userType }) => {

    const updateLoading = useSelector((state) => state.user.updateLoading.all);
    const dispatch = useDispatch();

    const resendParentEmail = useResendParentEmail();
    const handleResendParentEmail = (userId) => {
        resendParentEmail.mutate(userId)
    }
    useEffect(() => {
        if (!resendParentEmail.isLoading && resendParentEmail.isSuccess) {
            dispatch(openAlert({
                type: AlertType.SUCCESS,
                header: "Email Sent!",
                message: "The parent consent email has been resent",
            }));
        }
    }, [resendParentEmail.isLoading, resendParentEmail.isSuccess]);

    const baseURL = process.env.REACT_APP_WEBSITE_URL;
    const activationLink = baseURL + `/activate-user-account?hash=${user.hash}&email=${user.email}`;
    const activeText = user.active ? "True" : "False";
    const competitionNames = competitionList
        .filter(c => user.competitions.some(uc => uc.competitionId === c.id))
        .map(c => c.name);

    const userComp = user.competitions.find(uc => uc.competitionId === selectedCompetition.id);
    const platformCode = user.learningPlatformCode || "Not Assigned";
    const teams = useSelector((state) => state.teams);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.UPDATE_USER));
    const paymentStatus = userComp
        ? userComp.competitionStatus
        : "UNREGISTERED";
    const accessCode = paymentStatus === "UNREGISTERED"
        ? "UNREGISTERED"
        : userComp.accessCode && userComp.accessCode.code || "Self Pay"
    const chatEnabled = userComp
        ? userComp.chatEnabled ? "ENABLED" : "DISABLED"
        : "UNREGISTERED";
    const accountActive = user.active ? "ACTIVE" : "INACTIVE";

    const [isEditing, setIsEditing] = React.useState(false);
    const initialFormState = {
        username: user.username || "",
        email: user.email || "",
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        dateOfBirth: isNaN(new Date(user.dateOfBirth)) ? null : new Date(user.dateOfBirth),
        address: user.address || "",
        city: user.city || "",
        zipCode: user.zipCode || "",
        stateOrProvince: user.stateOrProvince || "",
        country: user.country || "",
        schoolOrOrganization: user.schoolOrOrganization || "",
        paymentStatus,
        chatEnabled,
        accountActive,
    };

    const currentTeamId = userComp?.teamId;
    const teamOptions = teams.list ? teams.list.map(t => ({ text: t.name, value: t.id })) : [];
    const [formState, setFormState] = React.useState({ ...initialFormState });
    const [newTeam, setNewTeam] = React.useState(currentTeamId);

    const handleEditButtonClick = () => {
        if (isEditing) {
            dispatch(adminUpdateUser(user.id, formState, currentTeamId, newTeam, () => {
                setIsEditing(false);
                dispatch(getCompetitionUsers(selectedCompetition.id, userType));
                setFormState({ ...initialFormState });
            }));
        } else {
            dispatch(clearErrors(ErrorTag.UPDATE_USER));
            setIsEditing(true);
        }
    }

    const renderEditButton = () => (
        <Item.Extra>
            <Button
                onClick={() => handleEditButtonClick()}
                primary={!!isEditing}
                content={isEditing ? "Done" : "Edit"}
                loading={updateLoading}
            />
        </Item.Extra>
    );

    if (isEditing) {
        let err;
        return (
            <Item>
                <Item.Content>
                    <Item.Header>{`${user.firstName || ""} ${user.lastName || ""}`}</Item.Header>
                    <Item.Meta>{user.teamName}</Item.Meta>
                    <Item.Description>
                        <AccountInfoForm
                            formState={formState}
                            setFormState={setFormState}
                            adminEdit
                        />
                        {userComp && userComp.competitionRole === "COMPETITOR" && (
                            <Form>
                                <Form.Field>
                                    <label>Team</label>
                                    <Dropdown
                                        selection
                                        search
                                        placeholder="Select Team"
                                        value={newTeam}
                                        onChange={(e, { value }) => setNewTeam(value)}
                                        options={teamOptions}
                                        loading={teams.loading}
                                        error={(err = getFieldError("team", errors)) && {
                                            content: err,
                                            pointing: "below"
                                        }}
                                    />
                                </Form.Field>
                            </Form>
                        )}
                    </Item.Description>
                    {renderEditButton()}
                    {isEditing && (
                        <Button
                            content="Cancel"
                            onClick={() => {
                                setIsEditing(false);
                                setFormState(initialFormState);
                            }}
                        />
                    )}
                </Item.Content>
            </Item>
        );
    }

    return (
        <Item>
            <Item.Content>
                <Item.Header>{`${user.firstName || ""} ${user.lastName || ""}`}</Item.Header>
                <Item.Meta>{user.teamName}</Item.Meta>
                <Item.Description>
                    <KeyValue keyName="ID" value={user.id} />
                    <KeyValue keyName="User Type" value={user.type} />
                    <KeyValue keyName="Username" value={user.username} />
                    <KeyValue keyName="Email" value={user.email} />
                    <KeyValue keyName="Name" value={(user.firstName || "") + " " + (user.lastName || "")} />
                    <KeyValue keyName="Team" value={user.teamName} />
                    <KeyValue keyName="Organization" value={user.organizationName || "NONE"} />
                    <KeyValue keyName="Event Role" value={(userComp && userComp.competitionRole) || "UNREGISTERED"} />
                    <KeyValue keyName="Learning Platform Code" value={platformCode} />
                    <KeyValue keyName="Access Code" value={accessCode} />
                    <KeyValue keyName="Payment Status" value={paymentStatus} />
                    <KeyValue keyName="Parent Approval Status" value={user.parent ? user.parent.approvalStatus : "Over age limit"} />
                    <KeyValue keyName="Parent Phone" value={user.parent ? user.parent.phoneNumber : "Over age limit"} />
                    <KeyValue keyName="Parent Email" value={user.parent ? user.parent.email : "Over age limit"} />
                    <KeyValue keyName="Account Status" value={accountActive} />
                    <KeyValue keyName="Chat Enabled" value={chatEnabled} />
                    {user.hash && !user.active && (
                        <KeyValue keyName="Activation Link" value={activationLink} />
                    )}
                </Item.Description>
                {renderEditButton()}
                <Button onClick={() => dispatch(openAdminResetPasswordModal(user.email))}>Reset Password</Button>
                <br />
                {user.parent && (<Button
                    style={{ marginTop: 4 }}
                    onClick={() => handleResendParentEmail(user.id)}
                    disabled={resendParentEmail.isLoading}
                >
                    Resend Parent Consent Email
                </Button>)}
            </Item.Content>
        </Item>
    );
};

export default UserItem;
