// UI
export const APP_LOADING                               = "APP_LOADING";
export const OPEN_ALERT                                = "OPEN_ALERT";
export const CLOSE_ALERT                               = "CLOSE_ALERT";
export const OPEN_CONFIRM_MODAL                        = "OPEN_CONFIRM_MODAL";
export const CLOSE_CONFIRM_MODAL                       = "CLOSE_CONFIRM_MODAL";
export const SET_ACTIVE_NAV_TAB                        = "SET_ACTIVE_NAV_TAB";
export const SET_LOGIN_MODAL_OPEN                      = "SET_LOGIN_MODAL_OPEN";
export const SET_CREATE_TEAM_MODAL_OPEN                = "SET_CREATE_TEAM_MODAL_OPEN";
export const SET_TEAM_INVITE_MODAL_OPEN                = "SET_TEAM_INVITE_MODAL_OPEN";
export const SET_JOIN_TEAM_MODAL_OPEN                  = "SET_JOIN_TEAM_MODAL_OPEN";
export const SET_JOIN_COMPETITION_MODAL_OPEN           = "SET_JOIN_COMPETITION_MODAL_OPEN";
export const SET_RESET_PASSWORD_MODAL_OPEN             = "SET_RESET_PASSWORD_MODAL_OPEN";
export const SET_ADMIN_RESET_PASSWORD_MODAL_OPEN       = "SET_ADMIN_RESET_PASSWORD_MODAL_OPEN";
export const SET_NOTIFICATION_LIST_OPEN                = "SET_NOTIFICATION_LIST_OPEN";
export const SET_ADD_ANNOUNCEMENT_MODAL_OPEN           = "SET_ADD_ANNOUNCEMENT_MODAL_OPEN";
export const SET_SCORECARD_MODAL_OPEN                  = "SET_SCORECARD_MODAL_OPEN";
export const SET_SCORECARD_COMPARISON_MODAL_OPEN       = "SET_SCORECARD_COMPARISON_MODAL_OPEN";
export const SET_SELECTED_COMPETITION_INFO             = "SET_SELECTED_COMPETITION_INFO";
export const SET_COMPETITION_INFO_MODAL_OPEN           = "SET_COMPETITION_INFO_MODAL_OPEN";
export const SET_PAYMENT_CONFIRMATION_MODAL_OPEN       = "SET_PAYMENT_CONFIRMATION_MODAL_OPEN";
export const SET_BULK_PAYMENT_CONFIRMATION_MODAL_OPEN  = "SET_BULK_PAYMENT_CONFIRMATION_MODAL_OPEN";
export const SET_SIGNUP_TYPE_MODAL_OPEN                = "SET_SIGNUP_TYPE_MODAL_OPEN";
export const SET_TEAM_INVITE_TYPE_MODAL_OPEN           = "SET_TEAM_INVITE_TYPE_MODAL_OPEN";
export const SET_MENTOR_INVITE_MODAL_OPEN              = "SET_MENTOR_INVITE_MODAL_OPEN";
export const SET_INVITE_TO_SIM_MODAL_OPEN              = "SET_INVITE_TO_SIM_MODAL_OPEN";
export const SET_ADD_ACCESS_CODES_MODAL_OPEN           = "SET_ADD_ACCESS_CODES_MODAL_OPEN";
export const SET_ADD_ORG_MANAGER_MODAL_OPEN            = "SET_ADD_ORG_MANAGER_MODAL_OPEN";
export const SET_ADMIN_ADD_LICENSE_MODAL_OPEN          = "SET_ADMIN_ADD_LICENSE_MODAL_OPEN";
export const SET_INVITE_MENTOR_MODAL_OPEN              = "SET_INVITE_MENTOR_MODAL_OPEN";
export const SET_TRANSFER_OWNERSHIP_MODAL_OPEN         = "SET_TRANSFER_OWNERSHIP_MODAL_OPEN";
export const SET_ADD_AFFILIATE_MODAL_OPEN              = "SET_ADD_AFFILIATE_MODAL_OPEN";
export const SET_ADD_ORG_MODAL_OPEN                    = "SET_ADD_ORG_MODAL_OPEN";

// ERRORS
export const SET_ERRORS                = "SET_ERRORS";
export const CLEAR_ERRORS              = "CLEAR_ERRORS";
export const SET_BUG_REPORT_MODAL_OPEN = "SET_BUG_REPORT_MODAL_OPEN";
export const BUG_REPORT_LOADING        = "BUG_REPORT_LOADING";

// USER
export const SET_USER                           = "SET_USER";
export const USER_LOADING                       = "USER_LOADING";
export const ALL_USERS_LOADING                  = "ALL_USERS_LOADING";
export const SET_ALL_USERS                      = "SET_ALL_USERS";
export const USER_UPDATE_EDITING                = "USER_UPDATE_EDITING";
export const USER_UPDATE_LOADING                = "USER_UPDATE_LOADING";
export const RESET_PASSWORD_LOADING             = "RESET_PASSWORD_LOADING";
export const TEMP_PASSWORD_CHANGED              = "TEMP_PASSWORD_CHANGED";
export const LOGOUT                             = "LOGOUT";
export const SET_SCHOOLS_AND_ORGS               = "SET_SCHOOLS_AND_ORGS";
export const SET_SCHOOLS_AND_ORGS_LOADING       = "SET_SCHOOLS_AND_ORGS_LOADING";
export const SET_PARENT_INFO_LOADING            = "SET_PARENT_INFO_LOADING";
export const SET_SUBMIT_PARENT_LOADING          = "SET_SUBMIT_PARENT_LOADING";
export const SET_PARENT_DATA                    = "SET_PARENT_DATA";
export const SET_USER_PARENT_DATA               = "SET_USER_PATENT_DATA";
export const SET_PARENT_CONSENT_LOADING         = "SET_PARENT_CONSENT_LOADING";
export const SET_PARENT_CONSENT_STATUS          = "SET_PARENT_CONSENT_STATUS";
export const SET_PARTIAL_USER                   = "SET_PARTIAL_USER";
export const SET_COMPETITION_USERS_LOADING      = "SET_COMPETITION_USERS_LOADING";
export const SET_COMPETITION_USERS              = "SET_COMPETITION_USERS";
export const SET_COMPETITION_MENTORS_LOADING    = "SET_COMPETITION_MENTORS_LOADING";
export const SET_COMPETITION_MENTORS            = "SET_COMPETITION_MENTORS";
export const SET_INVITE_MENTOR_LOADING          = "SET_INVITE_MENTOR_LOADING";
export const SET_LEARNING_PLATFORM_CODE_LOADING = "SET_LEARNING_PLATFORM_CODE_LOADING";
export const SET_LEARNING_PLATFORM_CODE         = "SET_LEARNING_PLATFORM_CODE";
export const CLEAR_LEARNING_PLATFORM_CODE       = "CLEAR_LEARNING_PLATFORM_CODE";
export const SET_REMOVE_GUEST_LOADING           = "SET_REMOVE_GUEST_LOADING";
export const REDEEM_ACCESS_CODE_LOADING         = "REDEEM_ACCESS_CODE_LOADING";
export const SET_ACADEMY_LICENSES               = "SET_ACADEMY_LICENSES";
export const SET_USER_EMAIL_TO_RESET_PASSWORD   = "SET_USER_EMAIL_TO_RESET_PASSWORD";

// TEAMS
export const SET_TEAMS                      = "SET_TEAMS";
export const TEAMS_LOADING                  = "TEAMS_LOADING";
export const SET_TEAM_TO_CREATE             = "SET_TEAM_TO_CREATE";
export const CREATE_TEAM_LOADING            = "CREATE_TEAM_LOADING";
export const SET_TEAM_INVITE                = "SET_TEAM_INVITE";
export const SET_INVITE_BY                  = "SET_INVITE_BY";
export const CLEAR_INVITE_BY                = "CLEAR_INVITE_BY";
export const TEAM_INVITE_LOADING            = "TEAM_INVITE_LOADING";
export const SET_TEAM_TO_JOIN               = "SET_TEAM_TO_JOIN";
export const JOIN_TEAM_LOADING              = "JOIN_TEAM_LOADING";
export const SET_TEAM_MEMBERS_LOADING       = "SET_TEAM_MEMBERS_LOADING";
export const SET_TEAM_MEMBERS               = "SET_TEAM_MEMBERS";
export const CLEAR_TEAM_MEMBERS             = "CLEAR_TEAM_MEMBERS";
export const SET_SIM_INVITE_LOADING         = "SET_SIM_INVITE_LOADING";
export const SET_SIM_INVITE                 = "SET_SIM_INVITE";
export const CLEAR_SIM_INVITE               = "CLEAR_SIM_INVITE";
export const TEAM_UPDATE_LOADING            = "TEAM_UPDATE_LOADING";
export const SET_TEAM_TO_LEAVE              = "SET_TEAM_TO_LEAVE";
export const SET_TRANSFER_OWNERSHIP_LOADING = "SET_TRANSFER_OWNERSHIP_LOADING";

// ORGANIZATIONS
export const SET_ORGANIZATION_LOADING           = "SET_ORGANIZATION_LOADING";
export const SET_CREATE_ORGANIZATION_LOADING    = "SET_CREATE_ORGANIZATION_LOADING";
export const SET_ORGANIZATION_DATA              = "SET_ORGANIZATION_DATA";
export const SET_ACCESS_CODES_LOADING           = "SET_ACCESS_CODES_LOADING";
export const SET_ADD_ACCESS_CODES_LOADING       = "SET_ADD_ACCESS_CODES_LOADING";
export const SET_ACCESS_CODES                   = "SET_ACCESS_CODES";
export const SET_ADD_ORG_MANAGER_LOADING        = "SET_ADD_ORG_MANAGER_LOADING";
export const SET_ORGANIZATION_LIST              = "SET_ORGANIZATION_LIST";
export const SET_ORGANIZATION_UPDATE_LOADING    = "SET_ORGANIZATION_UPDATE_LOADING";
export const SET_CURRENTLY_EDITING_ORGANIZATION = "SET_CURRENTLY_EDITING_ORGANIZATION";
export const SET_LICENSE_INFO                   = "SET_LICENSE_INFO";
export const SET_LICENSE_INFO_LOADING           = "SET_LICENSE_INFO_LOADING";
export const SET_ADD_LICENSE_LOADING            = "SET_ADD_LICENSE_LOADING";
export const SET_ACADEMY_STUDENTS               = "SET_ACADEMY_STUDENTS";
export const SET_CREATE_EVENT_LOADING           = "SET_CREATE_EVENT_LOADING";
export const ADD_EVENT                          = "ADD_EVENT";
export const ACADEMY_EVENTS_LOADING             = "ACADEMY_EVENTS_LOADING";
export const SET_ACADEMY_EVENTS                 = "SET_ACADEMY_EVENTS";
export const STUDENT_UPLOAD_LOADING             = "STUDENT_UPLOAD_LOADING";
export const UPDATE_EVENT_LOADING               = "UPDATE_EVENT_LOADING";
export const UPDATE_EVENT                       = "UPDATE_EVENT";
export const SET_ORGANIZATION_MODE              = "SET_ORGANIZATION_MODE";

// AFFILIATES
export const SET_AFFILIATES               = "SET_AFFILIATES";
export const SET_AFFILIATES_LOADING       = "SET_AFFILIATES_LOADING";
export const SET_AFFILIATE_UPDATE_LOADING = "SET_AFFILIATE_UPDATE_LOADING";
export const SET_AFFILIATE_TO_ADD         = "SET_AFFILIATE_TO_ADD";

// SIMS
export const SET_SIMS        = "SET_SIMS";
export const SIMS_LOADING    = "SIMS_LOADING";
export const SIMS_REFRESHING = "SIMS_REFRESHING";

// COMPETITIONS
export const SET_USER_COMPETITIONS            = "SET_USER_COMPETITIONS";
export const COMPETITIONS_LOADING             = "COMPETITIONS_LOADING";
export const ACTIVITIES_LOADING               = "ACTIVITIES_LOADING";
export const SET_ACTIVE_COMPETITIONS          = "SET_ACTIVE_COMPETITIONS";
export const SET_COMPETITION_BY_ID            = "SET_COMPETITION_BY_ID";
export const LEADERBOARD_LOADING              = "LEADERBOARD_LOADING";
export const SET_LEADERBOARD                  = "SET_LEADERBOARD";
export const SET_SELECTED_LEADERBOARD         = "SET_SELECTED_LEADERBOARD";
export const SET_SELECTED_ACTIVITY            = "SET_SELECTED_ACTIVITY";
export const SET_SCORECARD                    = "SET_SCORECARD";
export const SET_SCORECARD_COMPARISON         = "SET_SCORECARD_COMPARISON";
export const CLEAR_SCORECARD_COMPARISON       = "CLEAR_SCORECARD_COMPARISON";
export const SET_SCORECARD_COMPARISON_LOADING = "SET_SCORECARD_COMPARISON_LOADING";
export const ACTIVITY_DATA_LOADING            = "ACTIVITY_DATA_LOADING";
export const SET_COMPETITION_TO_CREATE        = "SET_COMPETITION_TO_CREATE";
export const SET_COMPETITIONS_ACTIVITIES      = "SET_COMPETITIONS_ACTIVITIES";
export const COMPETITIONS_ACTIVITIES_LOADING  = "COMPETITIONS_ACTIVITIES_LOADING";
export const SET_CURRENT_COMPETITION          = "SET_CURRENT_COMPETITION";
export const UPDATE_COMPETITION_LOADING       = "UPDATE_COMPETITION_LOADING";
export const UPDATE_ACTIVITY_LOADING          = "UPDATE_ACTIVITY_LOADING";

// SEARCH
export const USER_SEARCH         = "USER_SEARCH";
export const USER_SEARCH_LOADING = "SEARCH_LOADING";
export const CLEAR_USER_SEARCH   = "CLEAR_USER_SEARCH";

// NOTIFICATIONS
export const SET_NOTIFICATIONS     = "SET_NOTIFICATIONS";
export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";

// MESSAGING
export const ANNOUNCEMENTS_LOADING          = "ANNOUNCEMENTS_LOADING";
export const SET_ANNOUNCEMENTS              = "SET_ANNOUNCEMENTS";
export const SET_IS_EDITING_ANNOUNCEMENT    = "SET_IS_EDITING_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_TO_ADD        = "SET_ANNOUNCEMENT_TO_ADD";
export const CLEAR_ANNOUNCEMENT_TO_ADD      = "CLEAR_ANNOUNCEMENT_TO_ADD";
export const CHAT_LOADING                   = "CHAT_LOADING";
export const SEND_CHAT_MESSAGE_LOADING      = "SEND_CHAT_MESSAGE_LOADING";
export const DELETE_CHAT_MESSAGE_LOADING    = "DELETE_CHAT_MESSAGE_LOADING";
export const UPDATE_CHAT_MESSAGE_LOADING    = "UPDATE_CHAT_MESSAGE_LOADING";
export const SET_CHAT_MESSAGE_EDITING       = "SET_CHAT_MESSAGE_EDITING";
export const REFRESH_CHAT                   = "REFRESH_CHAT";
export const SET_CHAT_MESSAGES              = "SET_CHAT_MESSAGES";
export const SET_MODERATION_CHAT_MESSAGES   = "SET_MODERATION_CHAT_MESSAGES";
export const NEW_CHAT_MESSAGE               = "NEW_CHAT_MESSAGE";
export const NEW_MODERATION_CHAT_MESSAGE    = "NEW_MODERATION_CHAT_MESSAGE";
export const SEND_CHAT_MESSAGE              = "SEND_CHAT_MESSAGE";
export const EDIT_CHAT_MESSAGE              = "EDIT_CHAT_MESSAGE";
export const EDIT_MODERATION_CHAT_MESSAGE   = "EDIT_MODERATION_CHAT_MESSAGE";
export const REMOVE_CHAT_MESSAGE            = "REMOVE_CHAT_MESSAGE";
export const REMOVE_MODERATION_CHAT_MESSAGE = "REMOVE_MODERATION_CHAT_MESSAGE";

// BILLING
export const COMPETITION_LIST_LOADING      = "COMPETITION_LIST_LOADING";
export const SET_COMPETITION_LIST          = "SET_COMPETITION_LIST";
export const ADD_TO_CART                   = "ADD_TO_CART";
export const REMOVE_FROM_CART              = "REMOVE_FROM_CART";
export const CLEAR_ITEM_FROM_CART          = "CLEAR_ITEM_FROM_CART";
export const DISCOUNT_LOADING              = "DISCOUNT_LOADING";
export const ADD_DISCOUNT                  = "ADD_DISCOUNT";
export const SET_COMPETITION_PRICE         = "SET_COMPETITION_PRICE";
export const SET_INVOICE_PRICE             = "SET_INVOICE_PRICE";
export const SET_PRICE_LOADING             = "SET_PRICE_LOADING";
export const SET_PAYMENT_LOADING           = "SET_PAYMENT_LOADING";
export const SET_RECEIPT                   = "SET_RECEIPT";
export const CLEAR_RECEIPT                 = "CLEAR_RECEIPT";
export const RECEIPT_LOADING               = "RECEIPT_LOADING";
export const SET_COMPETITION_PAYMENT_INFO  = "SET_COMPETITION_PAYMENT_INFO";
export const SET_ORGANIZATION_PAYMENT_INFO = "SET_ORGANIZATION_PAYMENT_INFO";
export const CLEAR_CART                    = "CLEAR_CART";
export const LICENSE_ITEMS_LOADING         = "LICENSE_ITEMS_LOADING";
export const SET_LICENSE_ITEMS             = "SET_LICENSE_ITEMS";

// RESOURCES
export const SET_DOWNLOAD_LINK_LOADING = "SET_DOWNLOAD_LINK_LOADING";
export const SET_DOWNLOAD_LINK         = "SET_DOWNLOAD_LINK";
