import authServer from '../api/auth';
import { useMutation } from 'react-query';
import { getToken } from './token';

export const useResendParentEmail = () => useMutation({
  mutationFn: resendParentEmail,
  refetchOnMount: false,
});

async function resendParentEmail(userId) {
  try {
    const token = getToken();
    if (!token) {
      return [];
    }
    const response = await authServer.post(
      "/users/admin-resend-parent-email", { userId }, { headers: { Authorization: token } },
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}
