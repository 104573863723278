import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    Container,
    Card,
    Item,
    Loader,
    Input,
    Dropdown,
    Button,
    Form,
    Modal,
    Message,
} from "semantic-ui-react";

import { getAllTeams, adminUpdateTeam, adminUpdateActivityScore } from "../actions/teams";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";

const Search = ({ search, setSearch, clear }) => {
    return (
        <Grid style={{ marginTop: "1rem" }}>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Input
                        placeholder="Search"
                        fluid
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button icon="close" onClick={clear} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const KeyValue = ({ keyName, value }) => {
    return (
        <p>
            <span
                style={{
                    fontWeight: "bold"
                }}
            >
                {`${keyName}: `}
            </span>
            {value}
        </p>
    );
};

const TeamItem = ({
    team,
    selectedCompetition,
    onScoreModalOpen,
}) => {
    const updateLoading = useSelector((state) => state.teams.updateLoading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.TEAM_UPDATE));
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = React.useState(false);
    const initialFormState = {
        name: team.name || "",
    };
    const [formState, setFormState] = React.useState({ ...initialFormState });

    const handleEditButtonClick = () => {
        if (isEditing) {
            dispatch(adminUpdateTeam(team.id, formState, () => {
                setIsEditing(false);
                dispatch(getAllTeams(selectedCompetition.id));
                setFormState({ ...initialFormState });
            }));
        } else {
            setIsEditing(true);
        }
    }

    const handleOpenScoreModal = () => {
        onScoreModalOpen(team);
    }

    const renderEditButton = () => (
        <Item.Extra>
            <Button
                onClick={() => handleEditButtonClick()}
                primary={!!isEditing}
                content={isEditing ? "Done" : "Edit"}
                loading={updateLoading}
            />
        </Item.Extra>
    );

    if (isEditing) {
        let err;
        return (
            <Item>
                <Item.Content>
                    <Item.Header>{team.name}</Item.Header>
                    <Item.Description>
                        <Form error={!!getFormError(errors)}>
                            <Form.Input
                                label="Team Name"
                                placeholder="Team Name"
                                value={formState.name}
                                onChange={({ target: { value } }) => setFormState(s => ({ ...s, name: value }))}
                                error={(err = getFieldError("name", errors)) && {
                                    content: err,
                                    pointing: "below"
                                }}
                            />
                        </Form>
                    </Item.Description>
                    {renderEditButton()}
                    {isEditing && (
                        <Button
                            content="Cancel"
                            onClick={() => {
                                setIsEditing(false);
                                setFormState(initialFormState);
                            }}
                        />
                    )}
                </Item.Content>
            </Item>
        );
    }

    return (
        <Item>
            <Item.Content>
                <Item.Header>{team.name}</Item.Header>
                <Item.Description>
                    <KeyValue keyName="ID" value={team.id} />
                    <KeyValue keyName="Name" value={team.name} />
                    <KeyValue keyName="League" value={team.league} />
                    <Item.Group divided>
                        <Item.Header>Members</Item.Header>
                        {team.members.map(tm => (
                            <Item>
                                <Item.Description>
                                    <KeyValue keyName="Username" value={tm.username} />
                                    <KeyValue keyName="Email" value={tm.email} />
                                    <KeyValue keyName="Name" value={tm.name} />
                                    <KeyValue keyName="Organization" value={tm.organizationName ?? "NONE"} />
                                </Item.Description>
                            </Item>
                        ))}
                    </Item.Group>
                </Item.Description>
                {renderEditButton()}
                <Button onClick={handleOpenScoreModal}>
                    Edit Activity Score
                </Button>
            </Item.Content>
        </Item>
    );
};

const TeamsList = ({ teams, search, selectedLeague, loading, selectedCompetition }) => {

    const dispatch = useDispatch();

    const [teamsList, setTeamsList] = React.useState(teams);
    const [isFiltered, setIsFiltered] = React.useState(false);
    const [scoreFormState, setScoreFormState] = React.useState({
        activityName: '',
        score: 0,
    });
    const [selectedTeam, setSelectedTeam] = React.useState(false);
    const [scoreModalOpen, setScoreModalOpen] = React.useState(false);

    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.UPDATE_ACTIVITY_SCORE));

    React.useEffect(() => {
        if (search !== "") {
            const regex = new RegExp(search, "i");
            setTeamsList(teams.filter(t => regex.test(t.name)));
            setIsFiltered(true);
        } else if (isFiltered && search === "") {
            setTeamsList(teams);
            setIsFiltered(false);
        }
    }, [search]);

    const handleOpenScoreModal = (team) => {
        setScoreFormState({ activityName: '', score: 0 });
        setSelectedTeam(team);
        setScoreModalOpen(true);
    }

    const handleCloseScoreModal = () => {
        setScoreModalOpen(false);
    }

    const handleUpdateActivityScore = () => {
        dispatch(adminUpdateActivityScore({
            teamId: selectedTeam.id,
            competitionId: selectedCompetition.id,
            activityName: scoreFormState.activityName,
            score: scoreFormState.score,
        }, () => {
            setScoreFormState({ activityName: '', score: 0 });
            setScoreModalOpen(false);
        }));
    }

    const activityOptions = [
        'Spacecraft Builder', 'Orbit Designer', 'Orbital Descent', 'Habitat Builder', 'Surface Operations'
    ].map(a => ({ key: a, text: a, value: a }));

    let err;

    return (
        <>
            <Item.Group divided>
                {teamsList
                    .filter(t => selectedLeague === "ALL" || t.league === selectedLeague)
                    .map(t => (
                        <TeamItem
                            key={t.id}
                            team={t}
                            selectedCompetition={selectedCompetition}
                            onScoreModalOpen={handleOpenScoreModal}
                        />
                    ))}
            </Item.Group>
            <Modal
                open={scoreModalOpen}
                onOpen={handleOpenScoreModal}
                onClose={handleCloseScoreModal}
            >
                <Modal.Header>Update Activity Score</Modal.Header>
                <Modal.Content>
                    <Form
                        error={!!getFormError(errors)}
                    >
                        <Form.Select
                            label="Activity"
                            options={activityOptions}
                            placeholder="Activity"
                            value={scoreFormState.activityName}
                            onChange={(e, { value }) => setScoreFormState(s => ({ ...s, activityName: value }))}
                            error={(err = getFieldError("activityName", errors)) && {
                                content: err,
                                pointing: "below"
                            }}
                        />
                        <Form.Input
                            label="Score"
                            placeholder="Score"
                            value={scoreFormState.score}
                            onChange={(e, { value }) => setScoreFormState(s => ({ ...s, score: value }))}
                            error={(err = getFieldError("score", errors)) && {
                                content: err,
                                pointing: "below"
                            }}
                        />
                        <Message
                            error
                            header="Update Activity Score"
                            content={getFormError(errors)}
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button primary onClick={handleUpdateActivityScore}>
                        Update
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};


const AdminTeamManagement = () => {

    const dispatch = useDispatch();

    const currentComp = useSelector((state) => state.competitions.currentCompetition);
    const leagues = currentComp && currentComp.leagues;
    const loading = useSelector((state) => state.teams.loading);
    const teams = useSelector((state) => state.teams.list.filter(t => t.competitionId === currentComp.id));

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [lastLoadedComp, setLastLoadedComp] = React.useState(currentComp);
    const [selectedLeague, setSelectedLeague] = React.useState("ALL");
    const [search, setSearch] = React.useState("");

    const clearSearch = () => {
        setSearch("");
    };

    // Loading
    React.useEffect(() => {
        if (currentComp && !loading && !hasLoaded) {
            setLastLoadedComp(currentComp);
            dispatch(getAllTeams(currentComp.id));
            setHasLoaded(true);
        }
    }, [currentComp, loading, hasLoaded]);
    // Change competition
    React.useEffect(() => {
        if (hasLoaded && currentComp && currentComp.id !== lastLoadedComp.id && !loading) {
            setHasLoaded(false);
            setLastLoadedComp(currentComp);
            dispatch(getAllTeams(currentComp.id));
        }
    }, [currentComp, lastLoadedComp, hasLoaded, loading]);

    let leagueOpts = [{ key: "ALL", text: "All", value: "ALL" }];
    if (leagues)
        leagueOpts = [...leagueOpts, ...leagues.map(l => ({
            key: l.id,
            text: l.name,
            value: l.name
        }))];

    let teamOpts = [{ key: "ALL", text: "All", value: "ALL" }];
    if (teams)
        teamOpts = [...teamOpts, ...teams.map(t => ({
            key: t.id,
            text: t.name,
            value: t.id
        }))];

    const renderTeamsList = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <TeamsList
                teams={teams}
                search={search}
                selectedLeague={selectedLeague}
                loading={loading}
                selectedCompetition={currentComp}
            />
        );
    };

    return (
        <Container>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Grid style={{ marginTop: "1rem" }}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <h3>Team Management</h3>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Dropdown
                                    selection
                                    search
                                    fluid
                                    placeholder="League"
                                    value={selectedLeague}
                                    onChange={(e, { value }) => setSelectedLeague(value)}
                                    options={leagueOpts}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button
                                    icon="refresh"
                                    onClick={() => dispatch(getAllTeams(currentComp.id))}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
                <Card.Content>
                    <h4>Search</h4>
                    <Search
                        search={search}
                        setSearch={setSearch}
                        clear={clearSearch}
                    />
                </Card.Content>
                <Card.Content>
                    {renderTeamsList()}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default AdminTeamManagement;
