import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Message } from "semantic-ui-react";
import { getCompetitionList, submitPayment, submitSelfPay } from "../actions/billing";
import { getCart, getSubtotal, getTotalDiscount } from "../selectors/billing";
import { setActiveNavTab } from "../actions/ui";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag, UserType } from "../utils/enums";

import CartTotal from "../components/CartTotal";
import { clearErrors } from "../actions/errors";
import { useOrganization } from "../queries/get-organization";

const APPLICATION_ID = process.env.REACT_APP_SQUARE_APPLICATION_ID;
const LOCATION_ID = process.env.REACT_APP_SQUARE_LOCATION_ID;

const Checkout = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isIndividual = useSelector((state) => state.user.type !== UserType.ORG_MANAGER);

    const user = useSelector((state) => state.user);
    const orgData = useOrganization(user.organizationId);
    const cart = useSelector(getCart);
    const cartLoading = useSelector((state) => state.billing.competitions.loading);
    const subtotal = useSelector(getSubtotal(orgData.data));
    const totalDiscount = useSelector(getTotalDiscount);
    const total = subtotal - totalDiscount;
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.PAYMENT));
    const paymentProcessing = useSelector((state) => state.billing.paymentLoading);
    const currency = useSelector((state) => {
        const country = state.user.country;
        const alternatePrice = state.billing.competitions.list.reduce((acc, c) => {
            const altPrice = c.alternatePrices.find(ap => ap.country === country);
            if (altPrice) {
                return altPrice;
            }
            return acc;
        }, null);
        return alternatePrice ? alternatePrice.currency : "USD";
    });

    const [waitingOnSquare, setWaitingOnSquare] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadError, setLoadError] = useState(null);
    const [paymentLoading, setPaymentLoading] = useState(false);

    let timeout;
    useEffect(() => {
        dispatch(setActiveNavTab(null));
        timeout = setTimeout(() => {
            if (loading) {
                setLoadError("Error loading payment processor. Wait a few minutes and try again. " +
                    "If the problem persists, click the 'Help' icon to the right and submit " +
                    "a support ticket.");
            }
        }, 5000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        dispatch(clearErrors(ErrorTag.PAYMENT));
        dispatch(getCompetitionList());
    }, []);

    useEffect(() => {
        if (paymentLoading) {
            setPaymentLoading(false);
        }
    }, [paymentProcessing]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = process.env.REACT_APP_SQUARE_APPLICATION_URL;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);

    useEffect(() => {
        if (window.Square && waitingOnSquare) {
            setWaitingOnSquare(false);
            setLoadError(null);
            window.payments = window.Square.payments(APPLICATION_ID, LOCATION_ID);
            window.payments.card()
                .then(card => {
                    window.card = card;
                    card.attach("#card-container")
                        .then(() => {
                            clearTimeout(timeout);
                            setLoading(false);
                        })
                        .catch(err => console.error(err));
                })
                .catch(err => console.error(err));
        }
    }, [window.Square, waitingOnSquare]);

    useEffect(() => {
        if (!loading && !cartLoading && cart.items.length === 0) {
            history.push("/");
        }
    }, [loading, cartLoading, cart.items]);

    const renderContent = () => {
        if (loading || cartLoading) {
            return (
                <Card.Content>
                    Payment Form Loading (this may take up to 60 seconds)
                </Card.Content>
            );
        }
        if (loadError) {
            return (
                <Card.Content>
                    {loadError}
                </Card.Content>
            );
        }
        return (
            <Card.Content>
                <CartTotal
                    subtotal={subtotal}
                    discount={totalDiscount}
                    total={total}
                    currency={currency}
                />
            </Card.Content>
        );
    };

    const handlePayment = async () => {
        setPaymentLoading(true);
        let paymentToken;
        if (total > 0) {
            paymentToken = await window.card.tokenize();
        }
        if (isIndividual) {
            const discountCode = cart.discounts.length > 0 ? cart.discounts[0].code : undefined;
            dispatch(submitSelfPay(paymentToken, total, discountCode, history));
        } else {
            dispatch(submitPayment(paymentToken, { ...cart, subtotal, totalDiscount, total }, history));
        }
    };

    useEffect(() => {
        if (total === 0) {
            handlePayment();
        }
    }, [total]);

    return (
        <Card className="dashboard-card">
            {renderContent()}
            <Card.Content>
                <div id="card-container"></div>
                <Button
                    onClick={handlePayment}
                    primary
                    fluid
                    disabled={loading || cartLoading || paymentLoading}
                >
                    Pay
                </Button>
                {errors && (
                    <Message
                        error
                        header="Payment Error"
                        content={errors[0].message}
                    />
                )}
            </Card.Content>
        </Card>
    );
};

export default Checkout;
