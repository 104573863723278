/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Item, Button, Icon } from "semantic-ui-react";
import { openInviteMentorModal } from "../actions/ui";
import { getCompetitionMentors, inviteMentor } from "../actions/user";

const MentorItem = mentor => {
    const mentorName = mentor.firstName
          ? `${mentor.firstName} ${mentor.lastName}`
          : "Pending Activation";
    return (
        <Item>
            <Item.Content>
                <Item.Header>{mentor.username || "Pending Activation"}</Item.Header>
                <Item.Meta>{mentor.competitionRole}</Item.Meta>
                <Item.Description>
                    <p>{`Name: ${mentorName}`}</p>
                    <p>{`Email: ${mentor.email}`}</p>
                </Item.Description>
            </Item.Content>
        </Item>
    );
};

const MentorList = () => {

    const dispatch = useDispatch();
    const selectedComp = useSelector((state) => state.competitions.currentCompetition);
    const loading = useSelector((state) => state.user.mentors.loading);
    const mentors = useSelector((state) => state.user.mentors.list);
    const compId = useSelector((state) => state.user.mentors.competitionId);

    React.useEffect(() => {
        if ((selectedComp && selectedComp.id !== compId) && !loading) {
            dispatch(getCompetitionMentors(selectedComp.id));
        }
    }, [selectedComp, loading]);

    const renderMentors = () => {
        if (loading) {
            return "Loading...";
        }
        return mentors.map(m => <MentorItem key={m.id} {...m}/>);
    };

    const openAddMentor = () => {
        dispatch(openInviteMentorModal());
    };

    const renderList = () => {
        return (
            <Card className="dashboard-card" centered>
                <Card.Content>
                    <Card.Header>Mentors</Card.Header>
                </Card.Content>
                <Card.Content>
                    {renderMentors()}
                </Card.Content>
                <Card.Content>
                    <Button primary onClick={openAddMentor}>
                        <Icon name="plus" />
                        Add Mentor
                    </Button>
                </Card.Content>
            </Card>
        );
    };

    return (
        <>
            {renderList()}
        </>
    );
};

export default MentorList;
