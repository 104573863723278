import authServer from '../api/auth';
import { useMutation } from 'react-query';
import { getToken } from './token';

export const useAcceptTerms = () => useMutation({
  mutationFn: acceptTerms,
  refetchOnMount: false,
});

async function acceptTerms(accepted) {
  try {
    const token = getToken();
    if (!token) {
      return [];
    }
    const response = await authServer.patch(
      "/users/accept-study-terms", { accepted }, { headers: { Authorization: token } },
    );
    return response.data;
  } catch (err) {
    throw err;
  }
}
