//https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
export const uniqueById = (array) => {
    const unique = (id, index, arr) => arr.indexOf(id) === index;
    return array.filter((item, idx) => unique(item.id, idx, array.map(item => item.id)));
};
export const uniqueByField = (array, field) => {
    const unique = (val, index, arr) => arr.indexOf(val) === index;
    return array.filter((item, idx) => unique(item[field], idx, array.map(item => item[field])));
};
export const addOrIncrement = (array, item, field) => {
    const exists = array.find(_item => item.id === _item.id);
    if (exists) {
        return array.map(_item => item.id === _item.id ? { ...exists, [field]: exists[field] + 1 } : { ..._item })
    }
    return [ ...array, item ];
};
export const removeOrDecrement = (array, item, field) => {
    const exists = array.find(_item => item.id === _item.id);
    if (exists && exists[field] === 1) {
        return array.filter(_item => item.id !== _item.id);
    }
    return array.map(_item => item.id === _item.id ? { ...exists, [field]: exists[field] - 1 } : { ..._item })
};
