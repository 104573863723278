import authServer from '../api/auth';
import { useMutation } from 'react-query';
import { getToken } from './token';
import store from '../store';
import { AlertType } from '../utils/enums';
import { closeAlert, openAlert } from '../actions/ui';
import { ALERT_TIME } from '../utils/constants';

export const useResendAllParentEmails = () => useMutation({
  mutationFn: resendAllParentEmails,
  refetchOnMount: false,
});

async function resendAllParentEmails(eventId) {
  try {
    const token = getToken();
    if (!token) {
      return [];
    }
    const response = await authServer.post(
      "/users/admin-resend-parent-email", { eventId }, { headers: { Authorization: token } },
    );
    let header = "Parent Emails Sent!";
    let message = `${response.data.totalSent} emails sent`;
    let status = AlertType.SUCCESS;
    if (response.data.totalFailed > 0) {
      status = AlertType.WARNING;
      header = "Some Emails Sent";
      message += `, and ${response.data.totalFailed} emails failed to send.`;
    }
    store.dispatch(
      openAlert({
        type: status,
        header,
        message,
      })
    );
    setTimeout(() => store.dispatch(closeAlert()), ALERT_TIME);
    return response.data;
  } catch (err) {
    throw err;
  }
}
