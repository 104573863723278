import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ErrorTag } from "../utils/enums";
import {
    Card,
    Message,
    Item,
    Loader,
    Button,
    Popup,
    Icon
} from "semantic-ui-react";
import TeamItem from "./TeamItem";
import { openCreateTeamModal, openConfirmation } from "../actions/ui";
import {
    handleNotificationClick
} from "../actions/notifications";
import { getTaggedErrors } from "../selectors/errors";
import { getTeamInvites } from "../selectors/teams";
import { declineInviteConfirmation } from "../utils/confirmations";
import { PARENT_CONSENT_AGE } from "../utils/constants"
import { UserType } from "../utils/enums"
import { getTeams } from "../actions/teams";
import { useTeams } from "../queries/get-teams";

const isMentor = (role) => {
    return (
        role === "INDUSTRY_MENTOR" ||
        role === "GUEST" ||
        role === "ROAMER"
    );
};

const TeamList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const loading = useSelector((state) => state.teams.loading);
    const competitions = useSelector((state) => state.competitions);
    const parentApprovalMethod = useSelector((state) => {
        if (!state.competitions.currentCompetition?.settings) {
            return 'None';
        }
        return state.competitions.currentCompetition.settings.parentApprovalMethod;
    });
    const currentComp = useSelector((state) => state.competitions.currentCompetition);
    const currentRole = useSelector((state) =>
        state.competitions.currentCompetition &&
            user.type === 'ADMIN' ? 'ADMIN' : state.competitions.currentCompetition?.role);
    const invites = useSelector((state) => getTeamInvites(state.notifications));
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.TEAMS));

    const teams = useTeams();
    const filterTeamsByComp = () => teams.data ? teams.data.filter(t => {
        return currentComp && currentComp.id === t.competitionId;
    }) : [];

    const handleDeclineInviteClick = invite => {
        dispatch(openConfirmation(declineInviteConfirmation(invite)));
    };

    const inviteMessage = invite => (
        <Message key={invite.teamName}>
            <Message.Header>You've got an invite!</Message.Header>
            <p>{`You've been invited to join the team '${invite.teamName}'.`}</p>
            <Button.Group>
                <Button
                    content="Accept" color="green"
                    onClick={() => dispatch(handleNotificationClick(invite))}
                />
                <Button
                    content="Decline" color="red"
                    onClick={() => dispatch(handleDeclineInviteClick(invite))}
                />
            </Button.Group>
        </Message>
    );

    const renderContent = (teams, loading) => {
        if (loading) {
            return (
                <Loader className="card-loader" active />
            );
        }

        if (errors) {
            return (
                <Message negative>
                    <Message.Header>Oops! We had trouble getting your teams.</Message.Header>
                    {errors.map(e => <p>{e.message}</p>)}
                </Message>
            );
        }

        if (teams.length === 0 && user.type !== UserType.ORG_MANAGER && user.type !== UserType.ADMIN) {
            if (parentApprovalMethod !== 'None' && user.age < PARENT_CONSENT_AGE && !user.parent) {
                return (
                    <Message>
                        <Message.Header>
                            Looks like you haven't finished your registration
                        </Message.Header>
                        <p>
                            Click "Enter Parent Info" below to update your parent
                            or legal guardian's contact info.
                        </p>
                    </Message>
                );
            }
            if (competitions.userComps.length === 0 || (user.type === UserType.ADMIN && competitions.activeComps.length === 0)) {
                if (isMentor(currentRole)) {
                    return (
                        <Message>
                            <Message.Header>
                                Looks like there aren't any teams yet
                            </Message.Header>
                            <p>
                                Have a student create a team and you will see it here
                            </p>
                        </Message>
                    );
                }
                return (
                    <Message>
                        <Message.Header>
                            Looks like you're not registered for any events yet.
                        </Message.Header>
                        <p>Click "Register" below to register for an event</p>
                    </Message>
                );
            }
            if (invites && invites.length !== 0) {
                return (
                    <React.Fragment>
                        {invites.map(inviteMessage)}
                    </React.Fragment>
                );
            }
            if (isMentor(currentRole)) {
                return (
                    <Message>
                        <Message.Header>
                            Looks like there aren't any teams yet
                        </Message.Header>
                        <p>
                            Have a student create a team and you will see it here
                        </p>
                    </Message>
                );
            }
            if (competitions.currentCompetition && !competitions.currentCompetition?.settings?.teamModificationsEnabled) {
                return (
                    <Message>
                        <Message.Header>Team Modifications Have Been Disabled</Message.Header>
                        <p>
                            Team modifications have been turned off for this event. If you need to join
                            a team, please click the blue "Help" button to the right and create a
                            ticket stating you would like to be added to a team.
                        </p>
                    </Message>
                );
            }
            return (
                <Message>
                    {/* <Message.Header> */}
                    {/*     You'll be able to create or join a team here closer */}
                    {/*     to the event start date. We will send you an email */}
                    {/*     when this is available. */}
                    {/* </Message.Header> */}
                    <Message.Header>Looks like you're not on a team yet</Message.Header>
                    <p>Click "Create Team" below to create your own team</p>
                </Message>
            );
        }

        return (
            <Item.Group divided>
                {teams.map(t =>
                (
                    <TeamItem
                        key={t.id}
                        team={t}
                        noButtons={user.type === UserType.ORG_MANAGER || user.type === UserType.ADMIN}
                    />
                )
                )}
            </Item.Group>
        );
    };

    const renderButton = (loading) => {
        if (user.type === UserType.ORG_MANAGER) {
            return <div />;
        }
        if (loading) {
            return <Button primary loading content="Loading" />;
        }
        if (parentApprovalMethod !== 'None' && user.age < PARENT_CONSENT_AGE && !user.parent) {
            return (
                <Button
                    primary
                    onClick={() => history.push("/parent-info")}
                >
                    Enter Parent Info
                </Button>
            );
        }
        if (competitions.userComps.length === 0 && user.type === UserType.USER) {
            return (
                <Button
                    primary
                    onClick={() => history.push("/events")}
                >
                    Register
                </Button>
            );
        }
        if (teams.length > 0) {
            return <></>
        }
        const createDisabled = (
            isMentor(currentRole)
            || user.type === UserType.ADMIN
        )
        if (competitions.currentCompetition && !competitions.currentCompetition?.settings?.teamModificationsEnabled) {
            return (
                <Popup
                    content="Team modification has been disabled for this event"
                    trigger={
                        <Button
                            primary
                            onClick={() => dispatch(openCreateTeamModal())}
                            disabled
                        >
                            Create Team
                        </Button>
                    }
                />
            );
        }
        return (
            <Button
                primary
                onClick={() => dispatch(openCreateTeamModal())}
                disabled={createDisabled}
            >
                Create Team
            </Button>
        );
    };

    return (
        <Card className="dashboard-card" centered>
            <Card.Content>
                <Card.Header>
                    Teams
                    {user.type === UserType.ORG_MANAGER && (
                        <div style={{
                            float: 'right',
                        }}>
                            <Button
                                onClick={() => teams.refetch()}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    paddingLeft: '1.9rem',
                                }}
                                disabled={teams.isFetching}
                            >
                                <Icon name={teams.isFetching ? "loading" : "refresh"} />
                            </Button>
                        </div>
                    )}
                </Card.Header>
            </Card.Content>
            {user.type === UserType.ORG_MANAGER && (<Card.Content>
                <p>Max Team Size: {currentComp?.settings.teamSize}</p>
                <p>Team Modifications: {currentComp?.settings.teamModificationsEnabled ? 'Enabled' : 'Disabled'}</p>
            </Card.Content>)}
            <Card.Content>
                {renderContent(filterTeamsByComp(), teams.isLoading)}
            </Card.Content>
            {teams.length !== 0 && invites && invites.length !== 0 && (
                <Card.Content>
                    {invites.map(inviteMessage)}
                </Card.Content>
            )}
            <Card.Content extra>
                {renderButton(teams.isLoading)}
            </Card.Content>
        </Card>
    );
};

export default TeamList;
