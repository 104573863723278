import store from '../store';
import authServer from '../api/auth';
import { useQuery } from 'react-query';
import { setTeams } from '../actions/teams';
import { getToken } from './token';

export const useTeams = () => useQuery({
  queryKey: ['teams'],
  queryFn: getTeams,
  refetchOnMount: false,
});

async function getTeams() {
  try {
    const token = getToken();
    if (!token) {
      return [];
    }
    const teamsResult = await authServer.get(
      "/teams", { headers: { Authorization: token } }
    );
    console.log('teams result', teamsResult.data);
    store.dispatch(setTeams(teamsResult.data));
    return teamsResult.data;
  } catch (err) {
    throw err;
  }
}
