/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getTaggedErrors } from "../selectors/errors";
import { getAllActiveCompetitions } from "../actions/competitions";
import { getInvoicePrice } from "../actions/billing";
import { openConfirmation } from "../actions/ui";
import { addAccessCodesConfirmation } from "../utils/confirmations";
import { ErrorTag } from "../utils/enums";
import {
    Card,
    Icon,
    Button,
    Message,
} from "semantic-ui-react";

import OrgAddAccessCodesForm from "../components/OrgAddAccessCodesForm";
import { useActiveComps } from "../queries/get-active-comps";

const AddAccessCodes = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const defaultData = { numberOfCodes: 0, competitionId: null, couponCode: '' };
    const [formState, setFormState] = React.useState({ ...defaultData });
    const organizationId = useSelector((state) => state.user.organizationId);
    const competitionsData = useActiveComps();
    const competitions = competitionsData.data;
    const compsLoading = competitionsData.isLoading;
    const invoicePriceData = useSelector((state) => state.billing.invoicePriceData);
    const loading = useSelector((state) => state.organization.addAccessCodesLoading);
    const priceLoading = useSelector((state) => state.billing.priceLoading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ADD_ACCESS_CODES));
    const priceLookupErrors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.PRICE_LOOKUP));

    const fetchPrice = () => {
        if (formState.competitionId) {
            dispatch(getInvoicePrice(formState.competitionId, organizationId, formState.numberOfCodes, formState.couponCode));
        }
    }
    React.useEffect(() => {
        if (formState.competitionId && formState.numberOfCodes > 0) {
            fetchPrice();
        }
    }, [formState.competitionId, formState.numberOfCodes]);

    const renderButton = () => {
        return (
            <Button
                primary fluid
                onClick={() => dispatch(openConfirmation(addAccessCodesConfirmation(
                    organizationId, formState, invoicePriceData?.totalAfterDiscounts, invoicePriceData?.currency, () => history.push("/")
                )))}
                disabled={formState.numberOfCodes === 0 || formState.competitionId === null || !invoicePriceData}
                loading={loading || priceLoading}
            >
                <Icon name="plus"/>
                Add
            </Button>
        );
    };

    const renderForm = () => {
        return (
            <OrgAddAccessCodesForm
                formState={formState}
                setFormState={setFormState}
                errors={[ ...(errors || []), ...(priceLookupErrors || []) ]}
                loading={loading}
                compOptions={competitions.map(c => ({
                    key: c.id,
                    text: c.name,
                    value: c.id,
                }))}
                compsLoading={compsLoading}
                fetchPrice={fetchPrice}
            />
        );
    };

    const renderPrice = () => {
        if (priceLoading) {
            return "Loading...";
        }
        if (!invoicePriceData) {
            return "Select an event and the number of codes";
        }
        return (
            <>
                <p>
                    Total: {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(invoicePriceData.total) + " " + invoicePriceData.currency}
                </p>
                {invoicePriceData.totalDiscount > 0.0 && (
                    <>
                        <p>Discount: {`${invoicePriceData.totalDiscount}%`}</p>
                        <p>
                            New Total: {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(invoicePriceData.totalAfterDiscounts) + " " + invoicePriceData.currency}
                        </p>
                    </>
                )}
            </>
        );
    };

    return (

        <Card className="dashboard-card">
            <Card.Content>
                <Card.Header>
                    Add Access Codes
                </Card.Header>
            </Card.Content>
            <Card.Content>
                {renderForm()}
            </Card.Content>
            <Card.Content>
                <h3>Price:</h3>
                {renderPrice()}
            </Card.Content>
            <Card.Content>
                {!!errors && (
                    <Message
                        error
                        header="Add Access Codes Error"
                        content={errors[0].message || "Unknown error"}
                    />
                )}
            </Card.Content>
            <Card.Content>
                {renderButton()}
            </Card.Content>
        </Card>
    );
};

export default AddAccessCodes;
