/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavTab } from "../utils/enums";
import { setActiveNavTab } from "../actions/ui";
import {
    Container,
    Card,
    Message,
    Button,
    Dropdown,
} from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { assignLearningPlatformCode, getLearningPlatformCode } from "../actions/user";
import Academy from "./Academy";

const Section = {
    SETUP: 'SETUP',
    KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
}

const LearningPlatform = () => {

    const dispatch = useDispatch();

    const code = useSelector((state) => state.user.learningPlatformCode);
    const currentComp = useSelector((state) => state.competitions.currentCompetition);
    const loading = useSelector((state) => state.user.learningPlatformCodeLoading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.LEARNING_PLATFORM_CODES));

    const [isFetched, setIsFetched] = useState(false);
    const [lastComp, setLastComp] = useState(currentComp);

    const [section, setSection] = useState(Section.SETUP);
    const sectionOptions = [
        { key: Section.SETUP, text: 'Setup Instructions', value: Section.SETUP },
        { key: Section.KNOWLEDGE_BASE, text: 'Knowledge Base', value: Section.KNOWLEDGE_BASE },
    ];

    return <Academy/>;

    React.useEffect(() => {
        dispatch(setActiveNavTab(NavTab.LEARNING_PLATFORM));
        if (!isFetched && currentComp) {
            setIsFetched(true);
            dispatch(getLearningPlatformCode(currentComp.id));
        }
        if (currentComp && (!lastComp || lastComp.id !== currentComp.id)) {
            setLastComp(currentComp);
            setIsFetched(true);
            dispatch(getLearningPlatformCode(currentComp.id));
        }
    }, [currentComp, isFetched, lastComp]);

    const handleAssignCode = () => {
        dispatch(assignLearningPlatformCode(currentComp.id));
    };

    const renderCode = () => {
        if (loading || !isFetched || !currentComp) {
            return <p>Loading...</p>
        }
        if (!currentComp.learningPlatformEnabled) {
            return (
                <p>The Learning Platform is not available yet. Check back closer to the event start date.</p>
            )
        }
        if (!code) {
            if (errors && errors.length > 0) {
                return <p>{errors[0].message}</p>;
            }
            return (
                <Button
                    onClick={handleAssignCode}
                    loading={loading}
                >
                    Get Code
                </Button>
            );
        }
        return (
            <p>{`Learning Platform Code: ${code}`}</p>
        );
    };

    const getSource = () => {
        return "https://space-teams-public-resources.s3.us-west-2.amazonaws.com/LearningPlatformGuide.pdf";
    }

    if (currentComp.isMembership) {
        return <Academy/>;
    }

    return (
        <Container
            style={{
                width: '100%',
                marginTop: '-1rem',
            }}
        >
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Card.Header>Learning Platform</Card.Header>
                </Card.Content>
                <Card.Content>
                    <p>
                        Learning Platform Link: <a href="https://space-teams.spacecraft-vr.com">https://space-teams.spacecraft-vr.com</a>
                    </p>
                    <div>
                        {renderCode()}
                    </div>
                </Card.Content>
                <Card.Content>
                    <h4>Setup Instructions</h4>
                    <embed
                        src={getSource()}
                        width="100%"
                        style={{
                            height: "80vh",
                        }}
                    />
                </Card.Content>
                <Card.Content>
                    {errors && errors.length && (
                        <Message
                            error
                            header="Error Getting Learning Platform Code"
                            content={errors[0].message}
                        />
                    )}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default LearningPlatform;
