import React from "react";
import { useSelector } from "react-redux";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import countries from "../utils/countries";
import {
    Message,
    Form,
    Dropdown,
} from "semantic-ui-react";

const SignupForm = ({
    formState,
    setFormState,
    includeManagerFields,
    organizationName,
}) => {

    const loading = useSelector((state) => state.user.loading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.SIGNUP));

    const countryOpts = countries.map(c => ({ key: c.name, text: c.name, value: c.name }));

    let err;

    return (

        <Form error={!!getFormError(errors)} loading={loading}>

            {organizationName && <h2>{`Join ${organizationName}`}</h2>}

            <Form.Input
                icon="user"
                iconPosition="left"
                label="Create Username"
                placeholder="Create Username"
                value={formState.username}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, username: value }))}
                error={(err = getFieldError("username", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                icon="mail"
                iconPosition="left"
                label="Email"
                placeholder="Email"
                value={formState.email}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, email: value }))}
                error={(err = getFieldError("email", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            {includeManagerFields && (
                <Form.Input
                    label="First Name"
                    placeholder="First Name"
                    value={formState.firstName}
                    onChange={({ target: { value } }) => setFormState(s => ({ ...s, firstName: value }))}
                    error={(err = getFieldError("firstName", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            )}
            {includeManagerFields && (
                <Form.Input
                    label="Last Name"
                    placeholder="Last Name"
                    value={formState.lastName}
                    onChange={({ target: { value } }) => setFormState(s => ({ ...s, lastName: value }))}
                    error={(err = getFieldError("lastName", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            )}
            {includeManagerFields && (
                <Form.Input
                    autoComplete="off"
                    label="Address*"
                    placeholder="Address"
                    value={formState.address}
                    onChange={({ target: { value } }) => setFormState(s => ({ ...s, address: value }))}
                    error={(err = getFieldError("address", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />)}
            {includeManagerFields && (
                <Form.Field>
                    <label>Country*</label>
                    <Dropdown
                        selection
                        search
                        placeholder="Select Country"
                        value={formState.country}
                        onChange={(e, { value }) => setFormState(s => ({ ...s, country: value }))}
                        options={countryOpts}
                        error={(err = getFieldError("country", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                </Form.Field>
            )}
            {includeManagerFields && (
                <Form.Input
                    autoComplete="off"
                    label="City*"
                    placeholder="City"
                    value={formState.city}
                    onChange={({ target: { value } }) => setFormState(s => ({ ...s, city: value }))}
                    error={(err = getFieldError("city", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            )}
            {includeManagerFields && (
            <Form.Input
                autoComplete="off"
                label="Zip Code"
                placeholder="Zip Code"
                value={formState.zipCode}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, zipCode: value }))}
                error={(err = getFieldError("zipCode", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            )}
            <Form.Input
                icon="lock"
                iconPosition="left"
                label="Create Password"
                placeholder="Create Password"
                type="Password"
                value={formState.password}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, password: value }))}
                error={(err = getFieldError("password", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                icon="lock"
                iconPosition="left"
                label="Confirm Password"
                placeholder="Confirm Password"
                type="Password"
                value={formState.confirmPassword}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, confirmPassword: value }))}
                error={(err = getFieldError("confirmPassword", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />

            <Message
                error
                header="Signup Error"
                content={getFormError(errors)}
            />

        </Form>
    );

};

export default SignupForm;
