/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Icon, Grid, Message } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getAllActiveCompetitions } from "../actions/competitions";

import InviteMentorForm from "./InviteMentorForm";
import { inviteMentor } from "../actions/user";

const InviteMentorModal = ({
    open,
    onOpen,
    onClose,
    trigger
}) => {

    const dispatch = useDispatch();

    const defaultData = { email: "", mentorType: "" };
    const [formState, setFormState] = React.useState({ ...defaultData });
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.INVITE_MENTOR));
    const loading = useSelector((state) => state.user.inviteMentorLoading);
    const selectedComp = useSelector((state) => state.competitions.currentCompetition);

    const handleClose = () => {
        setFormState({ ...defaultData });
        onClose();
    };

    const handleInviteMentor = () => {
        dispatch(inviteMentor(formState.email, selectedComp.id, formState.mentorType));
    };

    const renderButton = () => {
        return (
            <Button
                primary
                onClick={() => handleInviteMentor()}
                loading={loading}
            >
                <Icon name="plus"/>
                Add
            </Button>
        );
    };

    const renderForm = () => {
        return (
            <InviteMentorForm
                formState={formState}
                setFormState={setFormState}
                errors={errors}
                loading={loading}
            />
        );
    };

    return (

        <Modal
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
            trigger={trigger}
        >
            <Modal.Header>
                <Grid>
                    <Grid.Column width={12}>
                        Add Mentor
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button
                            icon="close"
                            onClick={handleClose}
                            floated="right"
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                {renderForm()}
                {!!errors && (
                    <Message
                        error
                        header="Add Mentor Error"
                        content={errors[0].message || "Unknown error"}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                {renderButton()}
            </Modal.Actions>
        </Modal>
    );
};

export default InviteMentorModal;
