export const NavTab = {
    HOME: "Home",
    SIMULATIONS: "Simulations",
    COMPETITIONS: "Activities",
    TEAMS: "Teams",
    LEADERBOARD: "Leaderboard",
    CHAT: "Chat",
    GALLERY: "Gallery",
    DOWNLOADS: "Downloads",
    USER_MANAGEMENT: "Users",
    TEAM_MANAGEMENT: "Teams",
    COMPETITION_MANAGEMENT: "Competition Management",
    AFFILIATES: "Affiliates",
    ORGANIZATIONS: "Organizations",
    HELP: "Help",
    EVENTS: "Events",
    LEARNING_PLATFORM: "Learning",
    STORE: "Store',"
};

export const AlertType = {
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
};

export const NotificationType = {
    TEAM_INVITE: "teamInvite"
};

export const ErrorTag = {
    USER: "user",
    TEAMS: "teams",
    SIMS: "sims",
    COMPETITIONS: "competitions",
    UPDATE_COMPETITION: "updateCompetition",
    UPDATE_ACTIVITY: "updateActivity",
    UPDATE_ACTIVITY_SCORE: "updateActivityScore",
    ACTIVITIES: "activities",
    LEADERBOARD: "leaderboard",
    LOGIN: "login",
    SIGNUP: "signup",
    ACCOUNT_INFO: "accountInfo",
    USER_UPDATE: "userUpdate",
    RESET_PASSWORD: "resetPassword",
    CREATE_TEAM: "createTeam",
    TEAM_INVITE: "teamInvite",
    MENTOR_INVITE: "mentorInvite",
    SIM_INVITE: "simInvite",
    JOIN_TEAM: "joinTeam",
    LEAVE_TEAM: "leaveTeam",
    DELETE_TEAM: "deleteTeam",
    JOIN_COMPETITION: "joinCompetition",
    USER_SEARCH: "userSearch",
    NOTIFICATIONS: "notifications",
    ANNOUNCEMENTS: "announcements",
    BUG_REPORT: "bugReport",
    ADD_ANNOUNCEMENT: "addAnnouncement",
    DELETE_ANNOUNCEMENT: "deleteAnnouncement",
    SCORECARD: "scorecard",
    PRICE_LOOKUP: "priceLookup",
    PAYMENT: "payment",
    SCHOOLS_AND_ORGS_LOOKUP: "schoolsAndOrgsLookup",
    CREATE_ORG: "createOrg",
    PARENT_INFO: "parentInfo",
    PARENT_CONSENT: "parentConsent",
    DOWNLOAD_LINK: "downloadLink",
    CHAT_MESSAGE: "chatMessage",
    COMPETITION_USERS: "competitionUsers",
    COMPETITION_STORE: "competitionStore",
    DISCOUNT_CODE: "discountCode",
    RECEIPT: "receipt",
    ADD_ACCESS_CODES: "addAccessCodes",
    GET_ACCESS_CODES: "getAccessCodes",
    REDEEM_ACCESS_CODE: "redeemAccessCode",
    ADD_ORG_MANAGER: "addOrgManager",
    TEAM_UPDATE: "teamUpdate",
    COMPETITION_MENTORS: "competitionMentors",
    INVITE_MENTOR: "inviteMentor",
    LEARNING_PLATFORM_CODES: "learningPlatformCodes",
    REMOVE_GUEST: "removeGuest",
    TRANSFER_OWNERSHIP: "transferOwnership",
    AFFILIATE_UPDATE: "affiliateUpdate",
    FETCH_AFFILIATES: "fetchAffiliates",
    FETCH_ORGANIZATIONS: "fetchOrganizations",
    UPDATE_ORGANIZATION: "updateOrganization",
    LICENSE: "license",
    FETCH_LICENSE_ITEMS: "licenseItems",
    ACADEMY_EVENT: "academyEvent",
    COMPLETE_ACTIVITY_TASK: "completeActivityTask",
    STUDENT_UPLOAD: "studentUpload",
    UPDATE_EVENT: 'updateEvent',
    ADD_LICENSE: 'addLicense',
};

export const OrgMode = {
    COMPETITION: "COMPETITION",
    ACADEMY: "ACADEMY",
}

export const Audience = {
    GLOBAL: "GLOBAL",
    COMPETITION: "COMPETITION",
    COMPETITION_ROLE: "COMPETITION_ROLE",
    TEAM: "TEAM",
    USER: "USER"
};

export const AnnouncementType = {
    REGULAR: "REGULAR",
    IMPORTANT: "IMPORTANT",
    WARNING: "WARNING"
};

export const AnnouncementSendType = {
    EMAIL: "EMAIL",
    BULLETIN: "BULLETIN",
    BOTH: "BOTH",
}

export const InviteStatus = {
    ACTIVE: "ACTIVE",
    ACCEPTED: "ACCEPTED",
    REJECTED: "REJECTED",
    REVOKED: "REVOKED"
};

export const UserType = {
    USER: "USER",
    INITIAL_USER: "INITIAL_USER",
    MENTOR: "MENTOR",
    ADMIN: "ADMIN",
    ORG_MANAGER: "ORG_MANAGER",
};

export const CompetitionRole = {
    COMPETITOR: "COMPETITOR",
    GUEST: "GUEST",
    ROAMER: "ROAMER",
    TEACHER: "TEACHER",
    INDUSTRY_MENTOR: "INDUSTRY_MENTOR",
}

export const AdminRole = {
    SC_ROOT: "SC_ROOT",
    SC_ADMIN: "SC_ADMIN"
};

export const TeamRole = {
    OWNER: "OWNER",
    ADMIN: "ADMIN",
    MEMBER: "MEMBER"
};

export const ParentConsentStatus = {
    PENDING: "PENDING",
    APPROVED: "APPROVED",
    DENIED: "DENIED"
};

// TODO(adam): This needs to be pulled from competition data in the future
export const ActivityName = {
    PLANET_BUILDER: "Planet Builder",
    SPACECRAFT_BUILDER: "Spacecraft Builder",
    TRAJECTORY_DESIGNER: "Trajectory Designer",
    ORBITAL_DESCENT: "Orbital Descent",
    HABITAT_BUILDER: "Habitat Builder",
    SURFACE_OPS: "Surface Operations"
};
