import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "semantic-ui-react";
import { closeSignupTypeModal } from "../actions/ui";

const SignupTypeModal = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const open = useSelector((state) => state.ui.signupTypeModalOpen);

    const handleIndividual = () => {
        history.push("/signup");
        dispatch(closeSignupTypeModal());
    };

    const handleOrg = () => {
        history.push("/organization-registration");
        dispatch(closeSignupTypeModal());
    };

    return (
        <Modal
            open={open}
            onClose={() => dispatch(closeSignupTypeModal())}
        >
            <Modal.Header>
                Signup Options
            </Modal.Header>
            <Modal.Content>
                <p style={{ fontSize: "1.5rem" }}>
                    Are you signing up as an individual (a student) or an organization (you are the manager for an organization or school)?
                </p>
            </Modal.Content>
            <Modal.Actions>
                <Button.Group
                    style={{ width: "100%" }}
                    size="huge"
                >
                    <Button
                        content="Individual"
                        onClick={handleIndividual}
                        primary
                    />
                    <Button.Or />
                    <Button
                        content="Organization"
                        onClick={handleOrg}
                        primary
                    />
                </Button.Group>
                <div>
                    <p style={{ fontSize: "1.2rem", width: "50%", display: "inline", float: "left", textAlign: "left" }}>You are a student competing in the event</p>
                    <p style={{ fontSize: "1.2rem", width: "50%", display: "inline" }}>You are the manager for an organization or school</p>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default SignupTypeModal;
