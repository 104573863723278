import {
    SET_AFFILIATES,
    SET_AFFILIATES_LOADING,
    SET_AFFILIATE_UPDATE_LOADING,
    SET_AFFILIATE_TO_ADD,
} from "./types";
import { ErrorTag, AlertType } from "../utils/enums";
import { ALERT_TIME } from "../utils/constants";
import { addError, addErrorAlert, clearErrors } from "./errors";
import { getToken, loadUserData } from "./user";
import authServer from "../api/auth";

const _getAffiliates = async (token) => {
    try {
        const affiliatesResult = await authServer.get(
            "/affiliates", { headers: { Authorization: token } }
        );
        return affiliatesResult.data;
    } catch (err) {
        throw err;
    }
};

const _createAffiliate = async (data, token) => {
    try {
        await authServer.post(
            "/affiliates", data, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
};

const _updateAffiliate = async (data, token) => {
    try {
        await authServer.patch(
            "/affiliates", data, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
};

const _removeAffiliate = async (id, token) => {
    try {
        await authServer.delete(
            `/affiliates?id=${id}`, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
};

const _createCoupon = async (data, token) => {
    try {
        await authServer.post(
            "/affiliates/coupon", data, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
};

const _updateCoupon = async (data, token) => {
    try {
        await authServer.patch(
            "/affiliates/coupon", data, { headers: { Authorization: token } });
    } catch (err) {
        throw err;
    }
};

const _removeCoupon = async (affiliateId, code, token) => {
    try {
        await authServer.delete(
            `/affiliates/coupon?affiliate_id=${affiliateId}&code=${code}`,
            { headers: { Authorization: token } }
        );
    } catch (err) {
        throw err;
    }
};

export const getAffiliates = () => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATES_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        const affiliates = await _getAffiliates(token);
        dispatch({ type: SET_AFFILIATES, payload: affiliates });
        dispatch(clearErrors(ErrorTag.FETCH_AFFILIATES));

    } catch (err) {
        dispatch(clearErrors(ErrorTag.FETCH_AFFILIATES));
        err.tag = ErrorTag.FETCH_AFFILIATES;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATES_LOADING, payload: false });
    }
};

export const createAffiliate = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _createAffiliate({ ...data }, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const updateAffiliate = (id, data, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _updateAffiliate({ ...data, id }, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const removeAffiliate = (id, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _removeAffiliate(id, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const createCoupon = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _createCoupon({ ...data }, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const updateCoupon = (data, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _updateCoupon({ ...data }, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const removeCoupon = (affiliateId, code, cb) => async (dispatch) => {
    try {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: true });
        let token;
        if (!(token = getToken()))
            return;

        await _removeCoupon(affiliateId, code, token);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        cb();

    } catch (err) {
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
        err.tag = ErrorTag.AFFILIATE_UPDATE;
        dispatch(addError(err));
    } finally {
        dispatch({ type: SET_AFFILIATE_UPDATE_LOADING, payload: false });
    }
};

export const setAffiliateToAdd = (affiliate) => ({
    type: SET_AFFILIATE_TO_ADD,
    payload: affiliate,
});
