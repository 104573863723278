import store from '../store';
import compServer from '../api/comp';
import { useQuery } from "react-query";
import { SET_ACTIVE_COMPETITIONS } from '../actions/types';
import { UserType } from '../utils/enums';

export const useActiveComps = () => useQuery({
  queryKey: ['active-comps'],
  queryFn: getActiveComps,
  refetchOnMount: false,
});

async function getActiveComps() {
  try {
    const user = store.getState().user;
    const url = user.type === UserType.ADMIN ? "/all" : "/active";
    const activeCompetitionsResults = await compServer.get(url);
    store.dispatch({
      type: SET_ACTIVE_COMPETITIONS,
      payload: activeCompetitionsResults.data,
    });
    return activeCompetitionsResults.data;
  } catch (err) {
    throw err;
  }
}
