import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Checkbox, Dropdown, Form, Menu, Message } from "semantic-ui-react";
import { setActiveNavTab } from "../actions/ui";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getFieldError } from "../utils/formErrors";
import DatePicker from "react-semantic-ui-datepickers";
import { createEvent } from "../actions/organization";
import { useHistory } from "react-router-dom";
import { activityNames, parentApprovalMethods, scoredActivityNames } from "../utils/constants";
import AddAcademyEvent from "../components/AddAcademyEvent";
import EditAcademyEvents from "../components/EditAcademyEvents";

const AcademyEvents = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const org = useSelector((state) => state.organization.org);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ACADEMY_EVENT));

    const [creating, setCreating] = useState(false);

    return (
        <Card className="dashboard-card">
            <Card.Content>
                <Card.Header>
                    Events
                </Card.Header>
                <Menu
                    attached='top'
                    tabular
                    onItemClick={(_, data) => data.name === 'Create' ? setCreating(true) : setCreating(false)}
                    items={[
                        { key: 'Edit', name: 'Edit', active: !creating },
                        { key: 'Create', name: 'Create', active: creating },
                    ]}
                />
                {creating ? (
                    <AddAcademyEvent />
                ) : (
                    <EditAcademyEvents />
                )}
            </Card.Content>
        </Card>
    );
};

export default AcademyEvents;