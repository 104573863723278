import React from "react";
import { useDispatch } from 'react-redux';
import {
    Button,
    Item
} from "semantic-ui-react";
import KeyValue from "./KeyValue";
import { openAdminResetPasswordModal } from "../actions/ui";

const MentorItem = ({ user, selectedCompetition }) => {

    const dispatch = useDispatch();

    const userComp = user.competitions.find(c => c.competitionId === selectedCompetition.id);
    const isTeacher = user.type === "ORG_MANAGER";

    // const handleResendInvite = () => {
    //     dispatch();
    // }

    return (
        <Item>
            <Item.Content>
                <Item.Header>{`${user.firstName || ""} ${user.lastName || ""}`}</Item.Header>
                <Item.Description>
                    <KeyValue keyName="ID" value={user.id}/>
                    <KeyValue keyName="User Type" value={user.type}/>
                    <KeyValue keyName="Email" value={user.email}/>
                    <KeyValue keyName="Username" value={user.username}/>
                    <KeyValue keyName="Competition Role" value={(userComp && userComp.competitionRole) || "TEACHER"} />
                    {/* {isTeacher && (!user.username || user.username === "") && (
                        <Button onClick={handleResendInvite}>Resend Invite</Button>
                    )} */}
                    <Button onClick={() => dispatch(openAdminResetPasswordModal(user.email))}>Reset Password</Button>
                </Item.Description>
            </Item.Content>
        </Item>
    );
};

export default MentorItem;
