/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Menu,
    Dropdown,
    Segment,
    Button,
    Icon,
    Label,
    Grid,
    Popup,
} from "semantic-ui-react";
import {
    setActiveNavTab,
    openLoginModal,
    openSignupTypeModal
} from "../actions/ui";
import { logout } from "../actions/user";
import { openBugReportModal } from "../actions/ui";
import { UserType, NavTab, OrgMode } from "../utils/enums";
import { uniqueById } from "../utils/unique";

import CompetitionSelection from "./CompetitionSelection";
import { LARGE_LOGO_URL } from "../utils/constants";
import { useOrganization } from "../queries/get-organization";
import { useHealthCheck } from "../queries/health-check";

const Navbar = ({
    height
}) => {

    const history = useHistory();
    const navigate = history.push;
    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const userComps = useSelector(
        (state) => uniqueById(state.competitions.userComps.filter(c => c.teamIsActive))
    );
    const organizationData = useOrganization(user.organizationId);
    const organization = organizationData.data ?? {};
    const orgMode = useSelector((state) => state.organization.mode);
    const activeComps = useSelector((state) => state.competitions.activeComps);
    const activeNavTab = useSelector((state) => state.ui.activeNavTab);
    const numItemsInCart = useSelector((state) => state.billing.cart.items.length);
    const currentComp = useSelector((state) => state.competitions.currentCompetition);

    const [mobileTabsVisible, setMobileTabsVisible] = React.useState(false);

    const healthCheck = useHealthCheck();

    React.useEffect(() => {
        if (history.location.pathname === "/" && !activeNavTab) {
            dispatch(setActiveNavTab("Home"));
        }
    }, [history, activeNavTab, setActiveNavTab]);

    const handleItemClick = name => {
        setMobileTabsVisible(false);
        if (name === "Login") {
            dispatch(openLoginModal());
        }
        else if (name === "Logout") {
            dispatch(logout());
            dispatch(setActiveNavTab("Home"));
            navigate("/");
        }
        else if (name === "Sign Up") {
            dispatch(setActiveNavTab(null));
            dispatch(openSignupTypeModal());
        }
        else if (name === "Account Settings") {
            dispatch(setActiveNavTab(null));
            navigate("/account-settings");
        }
        else if (name === "Cart") {
            dispatch(setActiveNavTab(null));
            navigate("/cart");
        }
        else if (name === NavTab.HOME) {
            dispatch(setActiveNavTab(name));
            navigate("/");
        }
        else if (name === NavTab.COMPETITIONS) {
            dispatch(setActiveNavTab(name));
            navigate("/competitions");
        }
        else if (name === NavTab.LEADERBOARD) {
            dispatch(setActiveNavTab(name));
            navigate("/leaderboard");
        }
        else if (name === NavTab.CHAT) {
            dispatch(setActiveNavTab(name));
            navigate("/chat");
        }
        else if (name === NavTab.GALLERY) {
            dispatch(setActiveNavTab(name));
            navigate("/gallery");
        }
        else if (name === NavTab.DOWNLOADS) {
            dispatch(setActiveNavTab(name));
            navigate("/downloads");
        }
        else if (name === NavTab.USER_MANAGEMENT) {
            dispatch(setActiveNavTab(name));
            navigate("/user-management");
        }
        else if (name === NavTab.TEAM_MANAGEMENT) {
            dispatch(setActiveNavTab(name));
            navigate("/team-management");
        }
        else if (name === NavTab.AFFILIATES) {
            dispatch(setActiveNavTab(name));
            navigate("/affiliates");
        }
        else if (name === NavTab.COMPETITION_MANAGEMENT) {
            dispatch(setActiveNavTab(name));
            navigate("/competition-management");
        }
        else if (name === NavTab.ORGANIZATIONS) {
            dispatch(setActiveNavTab(name));
            navigate("/organizations");
        }
        else if (name === NavTab.EVENTS) {
            dispatch(setActiveNavTab(name));
            navigate("/events");
        }
        else if (name === NavTab.LEARNING_PLATFORM) {
            dispatch(setActiveNavTab(name));
            navigate("/learning-platform");
        }
        else if (name === NavTab.STORE) {
            dispatch(setActiveNavTab(name));
            navigate('/organization-store');
        }
        else {
            dispatch(setActiveNavTab(name));
            navigate("/");
        }
    };

    const handleBugReportClick = () => {
        dispatch(openBugReportModal());
    };

    const getUserTypeText = (userType) => {
        switch (userType) {
            case UserType.ADMIN:
                return "Admin";
            case UserType.USER:
            case UserType.INITIAL_USER:
                return "User";
            case UserType.ORG_MANAGER:
                return "Manager";
            default:
                return "";
        }
    };

    let connectionStatus = 'Connected';
    if (healthCheck.error) {
        connectionStatus = 'NoConnection';
    } else if (
        healthCheck.data?.bridgeStatus
        && healthCheck.data.bridgeStatus !== 'HEALTHY'
    ) {
        connectionStatus = 'NoNetbridge';
    }
    const renderConnectionStatus = () => {
        let message = 'Your connection is good';
        let color = 'green';
        if (connectionStatus === 'NoNetbridge') {
            message = 'You can connect to the Space Teams servers, ' +
                'but multiplayer features may not work properly.';
            color = 'yellow';
        }
        if (connectionStatus === 'NoConnection') {
            message = 'You are unable to connect to the Space Teams servers.';
            color = 'red';
        }
        if (connectionStatus === 'NoInternet') {
            message = 'You have lost connection to the internet.';
            color = 'red';
        }
        return (
            <Popup
                content={message}
                position="bottom right"
                trigger={
                    <div>
                        <span>Connection</span>
                        <Icon
                            name="circle"
                            color={color}
                            style={{
                                paddingLeft: '1rem',
                            }}
                        />
                    </div>
                }
            />
        );
    }

    const renderAccountButtons = (user, isMobile) => {
        if (user.loggedIn) {
            return (
                <React.Fragment>
                    <Menu.Item>
                        {renderConnectionStatus()}
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => window.location.reload()}
                    >
                        <Icon name="refresh" />
                        Update
                    </Menu.Item>
                    <Menu.Item
                        onClick={() => handleItemClick("Cart")}
                    >
                        <Icon name="cart" />
                        {numItemsInCart > 0 && (
                            <Label color="red" floating>
                                {numItemsInCart}
                            </Label>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        <Dropdown text={`${getUserTypeText(user.type)}: ${user.username}`} pointing>
                            <Dropdown.Menu style={{ position: "absolute", left: "-60px", top: "20px" }}>
                                <Dropdown.Item
                                    text="Account Settings" icon="cog"
                                    onClick={() => handleItemClick("Account Settings")}
                                />
                                <Dropdown.Item
                                    text="Logout"
                                    onClick={() => handleItemClick("Logout")}
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Menu.Item
                        name="Login"
                        onClick={() => handleItemClick("Login")}
                    />
                    <Menu.Item
                        name="Sign Up"
                        onClick={() => handleItemClick("Sign Up")}
                    />
                </React.Fragment>
            );
        }
    };

    const renderNavTab = name => (
        <Menu.Item
            className="tab"
            name={name}
            active={activeNavTab === name}
            onClick={() => handleItemClick(name)}
        />
    );

    const renderTabs = user => (
        <>
            {renderNavTab(NavTab.HOME)}
            {user.loggedIn && currentComp
                && renderNavTab(NavTab.COMPETITIONS)}
            {user.loggedIn
                && renderNavTab(NavTab.LEADERBOARD)}
            {user.loggedIn
                && (user.type === UserType.USER || user.type === UserType.ORG_MANAGER)
                && renderNavTab(NavTab.CHAT)}
            {user.loggedIn
                && renderNavTab(NavTab.GALLERY)}
            {user.loggedIn
                && (user.type === UserType.USER
                    || user.type === UserType.MENTOR
                    || user.type === UserType.ORG_MANAGER)
                && renderNavTab(NavTab.DOWNLOADS)}
            {user.loggedIn
                && (user.type === UserType.USER || (user.type === UserType.ORG_MANAGER && orgMode === OrgMode.ACADEMY))
                && renderNavTab(NavTab.EVENTS)}
            {user.loggedIn
                && renderNavTab(NavTab.LEARNING_PLATFORM)}
            {user.loggedIn
                && (user.type === UserType.ORG_MANAGER)
                && renderNavTab(NavTab.STORE)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.USER_MANAGEMENT)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.TEAM_MANAGEMENT)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.COMPETITION_MANAGEMENT)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.AFFILIATES)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.ORGANIZATIONS)}
            {user.loggedIn
                && (user.type === UserType.ADMIN)
                && renderNavTab(NavTab.CHAT)}
        </>
    );

    const renderNavButtons = user => (
        <Menu.Menu position="right">
            {renderAccountButtons(user, false)}
        </Menu.Menu>
    );

    const renderMobileTabs = (visible, user) => (
        <div className={`mobile-tabs ${visible && "mobile-tabs--visible"}`} style={{ top: height }}>
            <div className="tab-row">
                {renderNavTab(NavTab.HOME)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.USER || user.type === "ORG_MANAGER")
                    && renderNavTab(NavTab.COMPETITIONS)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && renderNavTab(NavTab.LEADERBOARD)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.USER || user.type === UserType.ORG_MANAGER)
                    && renderNavTab(NavTab.CHAT)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && renderNavTab(NavTab.GALLERY)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.USER
                        || user.type === UserType.MENTOR
                        || user.type === UserType.ORG_MANAGER)
                    && userComps && userComps.length > 0
                    && renderNavTab(NavTab.DOWNLOADS)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.USER || user.type === UserType.ORG_MANAGER)
                    && renderNavTab(NavTab.EVENTS)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.USER || user.type === UserType.ORG_MANAGER)
                    && renderNavTab(NavTab.LEARNING_PLATFORM)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.USER_MANAGEMENT)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.TEAM_MANAGEMENT)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.COMPETITION_MANAGEMENT)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.AFFILIATES)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.ORGANIZATIONS)}
            </div>
            <div className="tab-row">
                {user.loggedIn
                    && (user.type === UserType.ADMIN)
                    && renderNavTab(NavTab.CHAT)}
            </div>
        </div>
    );

    const renderMobileNavButtons = user => (
        <Menu.Menu position="right" className="mobile-nav-buttons">
            {renderAccountButtons(user, true)}
        </Menu.Menu>
    );

    const renderMobile = user => (
        <Menu pointing secondary inverted className="navbar navbar--mobile">
            <Button className="nav-button" icon="bars"
                onClick={() => setMobileTabsVisible(!mobileTabsVisible)}
            />

            {renderMobileTabs(mobileTabsVisible, user)}
            {renderMobileNavButtons(user)}
        </Menu>
    );

    const logo = () => {
        if (user.type === UserType.ADMIN) {
            return <div />;
        }
        // if (process.env.NODE_ENV === "production") {
        return (
            <img src={LARGE_LOGO_URL} width="200px" style={{
                display: "inline-block",
                float: "left",
            }} />
        );
        // }
        // return (
        //     <h1 style={{
        //         display: "inline-block",
        //         float: "left",
        //         position: "relative",
        //         bottom: "1rem",
        //     }}>
        //         DEMO
        //     </h1>
        // );
    };

    const renderDesktop = user => {
        const showOrgName = user.type === "ORG_MANAGER" && organization && organization.name;

        return (
            <>
                {logo()}
                {showOrgName && (
                    <h3 style={{
                        position: "absolute",
                        left: "1.5rem",
                        top: "3rem",
                    }}>
                        {organization.nasaOrg ? `NASA:  ${organization.name}` : organization.name}
                    </h3>
                )}
                <Menu pointing secondary inverted className="navbar navbar--desktop">
                    {renderTabs(user)}
                    {renderNavButtons(user)}
                </Menu>
            </>
        );
    }

    return (
        <Segment inverted className="nav top-nav" style={{ height }}>

            {renderMobile(user)}
            {renderDesktop(user)}
            {user.loggedIn && (<CompetitionSelection />)}

        </Segment>
    );
};

export default Navbar;
