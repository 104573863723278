import React from "react";
import {
    Item,
    Button,
    Icon,
    Label,
    Grid
} from "semantic-ui-react";
import { UserType } from '../utils/enums';

const AddToCartButton = ({ onClick }) => (
    <Button onClick={onClick} primary floated="right">Add to Cart</Button>
);
const RemoveFromCartButton = ({ onClick }) => (
    <Button onClick={onClick} color="red" floated="right">Remove</Button>
);
const PlusButton = ({ onClick }) => (
    <Button onClick={onClick} floated="right"><Icon name='plus'/></Button>
);
const MinusButton = ({ onClick }) => (
    <Button onClick={onClick} floated="left"><Icon name='minus'/></Button>
);

const StoreButton = ({ userType, item, onRemoveFromCart, onAddToCart }) => {
    switch (userType) {
        case UserType.ORG_MANAGER:
            return (
                <Button.Group compact size='tiny'>
                    <MinusButton onClick={() => onRemoveFromCart(item.id)} />
                    <Label style={{
                        fontSize: '1.2rem',
                    }}>{item.qty}</Label>
                    <PlusButton onClick={() => onAddToCart(item.id)} />
                </Button.Group>
            )
        default:
return (
    item.selected
        ? (
            <RemoveFromCartButton onClick={() => onRemoveFromCart(item.id)} />
        ) : (
            <AddToCartButton onClick={() => onAddToCart(item.id)} />
        )
)
    }
}

const StoreList = ({ items, onAddToCart, onRemoveFromCart, userType }) => {
    return (
        <Item.Group divided>
            {items.map((item) => (
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={2}>
                            <StoreButton userType={userType} onAddToCart={onAddToCart} onRemoveFromCart={onRemoveFromCart} item={item} />
                        </Grid.Column>
                        <Grid.Column width={10}>
                            <Item key={item.id}>
                                <Item.Content>
                                    <Item.Header>{item.name}</Item.Header>
                                    <Item.Meta>{`$${item.price} ${item.currency}`}</Item.Meta>
                                    {item.selected && (
                                        <Item.Extra>
                                            <Icon color="green" name="check" />
                                            Added
                                        </Item.Extra>)}
                                    <Item.Description>{item.description}</Item.Description>
                                </Item.Content>
                            </Item>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            ))}
        </Item.Group>
    );
}

export default StoreList;
