import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Divider, Form, Message } from "semantic-ui-react";
import { addLicense, updateLicenseInfo } from "../actions/organization";
import { setActiveNavTab } from "../actions/ui";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getFieldError } from "../utils/formErrors";

const AcademyLicenses = () => {

    const dispatch = useDispatch();

    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.LICENSE));
    const licenseInfo = useSelector(state => state.organization.licenseInfo);
    const addLoading = useSelector(state => state.organization.addLicenseLoading);
    const students = useSelector(state => state.organization.academyStudents);
    const { loading, seats, accounts } = licenseInfo;

    const getPrice = () => {
        return (seats * 100 + accounts * 10).toFixed(2);
    }

    useEffect(() => {
        dispatch(setActiveNavTab(null));
    }, []);

    let err;

    const handleSubmit = () => {
        dispatch(addLicense());
    }

    return (
        <Card className="dashboard-card">
            <Card.Content>
                <Card.Header>
                    Add Licenses
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <Form>
                    <Form.Input
                        type="number"
                        label="Seats"
                        placeholder="Seats"
                        value={seats}
                        onChange={({ target: { value } }) => dispatch(updateLicenseInfo({ seats: value, accounts }))}
                        error={(err = getFieldError("seats", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <p>Description of how seats work (and maybe pricing info)</p>
                    <Divider/>
                    <Form.Input
                        type="number"
                        label="Accounts"
                        placeholder="Accounts"
                        value={accounts}
                        onChange={({ target: { value } }) => dispatch(updateLicenseInfo({ seats, accounts: value }))}
                        error={(err = getFieldError("accounts", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <p>Description of how accounts work (and maybe pricing info)</p>
                </Form>
            </Card.Content>
            <Card.Content>
                <h3>Price:</h3>
                {loading ? "Loading..." : "$" + getPrice()}
            </Card.Content>
            {!!errors && (
                <Card.Content>
                    <Message
                        error
                        header="Add Licenses Error"
                        content={errors[0].message || "Unknown error"}
                    />
                </Card.Content>
            )}
            <Card.Content>
                <Button
                    primary fluid
                    onClick={handleSubmit}
                    style={{ maxWidth: "20rem"}}
                    loading={addLoading}
                >
                    {addLoading ? "Submitting..." : "Checkout"}
                </Button>
            </Card.Content>
        </Card>
    );
};

export default AcademyLicenses;