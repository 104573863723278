import React from "react";
import { getFieldError, getFormError } from "../utils/formErrors";
import { Form, Dropdown } from "semantic-ui-react";

const InviteMentorForm = ({
    formState,
    setFormState,
    errors,
    loading,
}) => {

    let err;

    const mentorOptions = [
        { key: 'INDUSTRY_MENTOR', text: 'Industry', value: 'INDUSTRY_MENTOR' },
        { key: 'ROAMER', text: 'Roaming', value: 'ROAMER' },
        { key: 'GUEST', text: 'Guest', value: 'GUEST' },
    ];

    return (
        <Form
            loading={loading}
            error={!!getFormError(errors)}
        >
            <label htmlFor="mentorType" style={{ marginRight: "1rem"}}>Type</label>
            <Dropdown
                selection
                search
                fluid
                name="mentorType"
                placeholder="Mentor Type"
                value={formState.mentorType}
                onChange={(e, { value }) =>
                    setFormState({ ...formState, mentorType: value })}
                options={mentorOptions}
            />
            <Form.Input
                label="Email"
                placeholder="Email"
                value={formState.email}
                onChange={({ target: { value } }) => setFormState({ ...formState, email: value })}
                error={(err = getFieldError("email", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
        </Form>
    );
};

export default InviteMentorForm;
