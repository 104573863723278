import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button } from "semantic-ui-react";
import AddAffiliateForm from "./AddAffiliateForm";
import { openAddAffiliateModal, closeAddAffiliateModal } from "../actions/ui";
import { createAffiliate, getAffiliates, setAffiliateToAdd } from "../actions/affiliates";
import { clearErrors } from "../actions/errors";
import { ErrorTag } from "../utils/enums";

const AddAffiliateModal = () => {

    const dispatch = useDispatch();

    const open = useSelector((state) => state.ui.addAffiliateModalOpen);
    const affiliateToAdd = useSelector((state) => state.affiliates.newAffiliate);
    const loading = useSelector((state) => state.affiliates.updateLoading);

    const setFormState = fn => dispatch(setAffiliateToAdd(fn(affiliateToAdd)));

    const handleClose = () => {
        dispatch(closeAddAffiliateModal());
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
    }

    const handleAdd = () => {
        dispatch(createAffiliate(affiliateToAdd, () => {
            handleClose();
            dispatch(getAffiliates());
        }));
    };

    return (

        <Modal
            open={open}
            onOpen={() => dispatch(openAddAffiliateModal())}
            onClose={() => handleClose()}
        >
            <Modal.Header>Add Affiliate</Modal.Header>
            <Modal.Content>
                <AddAffiliateForm
                    formState={affiliateToAdd}
                    setFormState={setFormState}
                />
            </Modal.Content>
            <Modal.Actions>
                <Button
                    icon="close" content="Cancel"
                    onClick={() => handleClose()}
                />
                <Button
                    primary icon="add" content="Create"
                    onClick={() => handleAdd()}
                    loading={loading}
                />
            </Modal.Actions>
        </Modal>
    );
};

export default AddAffiliateModal;
