import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    Container,
    Card,
    Menu,
    Button,
    Icon,
    Header,
} from 'semantic-ui-react';
import { setActiveNavTab } from '../actions/ui';
import { completeActivityTask } from '../actions/user';
import { NavTab, UserType } from '../utils/enums';

export const Tab = {
    WELCOME: 'Welcome',
    SETUP: 'Getting Started',
    PLANETS: 'Planets',
    SPACECRAFT: 'Spacecraft',
    ORBITS: 'Orbits',
    LANDING: 'Landing',
    HABITAT: 'Habitat',
    SUSTAINABILITY: 'Sustainability',
    OVERVIEW: 'Overview',
    DOWNLOAD_SOFTWARE: 'Download Software',
    TEAM_CREATION: 'Team Creation',
    HARDWARE: 'Hardware',
    ACCOUNT: 'Account',
    KNOWLEDGE_BASE: 'Knowledge Base',
    TEACHERS: 'Teachers and Mentors',
    VR_COMPUTER_SETUP: 'VR Computer Setup',
    VR_SOFTWARE_SETUP: 'VR Software Setup',
    SPACEWALK: 'Space Station Spacewalk',
    SOLAR_SYSTEM: 'Solar System Explorer',
}
const tabToActivityName = (tab) => {
    switch (tab) {
        case Tab.WELCOME:
        case Tab.SETUP:
        case Tab.OVERVIEW:
        case Tab.DOWNLOAD_SOFTWARE:
        case Tab.TEAM_CREATION:
        case Tab.HARDWARE:
        case Tab.ACCOUNT:
        case Tab.KNOWLEDGE_BASE:
        case Tab.TEACHERS:
            return 'Welcome';
        case Tab.PLANETS:
            return 'Planet Builder';
        case Tab.SPACECRAFT:
            return 'Spacecraft Builder';
        case Tab.ORBITS:
            return 'Trajectory Designer';
        case Tab.LANDING:
            return 'Orbital Descent';
        case Tab.HABITAT:
            return 'Habitat Builder';
        case Tab.SUSTAINABILITY:
            return 'Surface Operations';
        default:
            return 'Welcome';
    }
}
const Content = {
    DOCS: 'Documentation',
    LESSON: 'Lesson',
    TUTORIAL: 'Tutorial',
    __INIT__: '__INIT__',
}
const WelcomeContent = {
    INFO_PACKET: "Information",
    VIDEO: 'Video',
}
const SetupContent = {
    GETTING_STARTED: "Getting Started",
    COUNTDOWN: "Launch Countdown",
    TEACHER_GUIDE: "Organization Manager Setup",
    TEACHER_DOCS: "Organization Account Creation Guide",
}
const VRContent = {
    SETUP: "Setup",
}
const contentToTaskName = (content) => {
    switch (content) {
        case Content.LESSON:
            return 'Lesson';
        case Content.TUTORIAL:
            return 'Tutorial';
        case WelcomeContent.VIDEO:
            return 'Welcome Video';
        case SetupContent.GETTING_STARTED:
            return 'Getting Started Video';
        case SetupContent.COUNTDOWN:
            return 'Countdown Checklist';
        default:
            return 'Other';
    }
}

const Academy = ({
}) => {

    const [tab, setTab] = useState(Tab.WELCOME);
    const [content, setContent] = useState(Content.__INIT__);
    const [menuOpen, setMenuOpen] = useState(true);
    const [page, setPage] = useState('');

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(setActiveNavTab(NavTab.LEARNING_PLATFORM));
    }, []);

    const hasValue = (obj, value) => Object.keys(obj).map(k => obj[k]).some(v => v === value);
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab');
        const content = params.get('content');
        console.log('tab', tab, 'content', content);
        if (hasValue(Tab, tab)) {
            console.log('has tab');
            setTab(params.get('tab'));
        }
        if (tab === Tab.WELCOME && hasValue(WelcomeContent, content)) {
            setContent(content);
            return;
        }
        if (tab === Tab.SETUP && hasValue(SetupContent, content)) {
            console.log('has setup content');
            setContent(content);
            return;
        }
        if (hasValue(Content, content)) {
            setContent(content);
        }
    }, [location]);

    const user = useSelector((state) => state.user);
    const selectedEvent = useSelector((state) => state.competitions.currentCompetition);

    const handleClick = (tab) => () => {
        setTab(tab);
        setContent(Content.__INIT__);
    }

    const getPage = (tab) => {
        switch (tab) {
            case Tab.PLANETS:
                return '#page=7';
            case Tab.SPACECRAFT:
                return '#page=8';
            case Tab.ORBITS:
                return '#page=9';
            case Tab.LANDING:
                return '#page=10';
            case Tab.HABITAT:
                return '#page=11';
            case Tab.SUSTAINABILITY:
                return '#page=12'
            default:
                return '';
        }
    }
    useEffect(() => {
        const nextPage = getPage(tab);
        setPage(nextPage);
    }, [page, tab]);

    const getDocument = () => {
        switch (tab) {
            case Tab.WELCOME:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Welcome/Space+Teams+Overview+Updated.pdf'
            case Tab.SETUP:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/InstallationandSetupGuide.pdf';
            case Tab.KNOWLEDGE_BASE:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Knowledge Base.pdf';
            case Tab.ACCOUNT:
                return (user.type === UserType.ORG_MANAGER)
                    ? 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Setup/Teacher+Guide+Video.mp4'
                    : 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/IndividualAccountCreation.pdf';
            case Tab.DOWNLOAD_SOFTWARE:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/SpaceCRAFTDownloadGuide.pdf';
            case Tab.OVERVIEW:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/Space+Teams+Overview+Updated.pdf';
            case Tab.TEAM_CREATION:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/TeamCreationGuide.pdf';
            case Tab.HARDWARE:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/HardwareTestGuide_STA.pdf';
            case Tab.TEACHERS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Help/TheTeachersMentorsGuide.pdf';
            case Tab.PLANETS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Planet+Designer+Activity.pdf';
            case Tab.SPACECRAFT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Spacecraft+Builder+Activity.pdf';
            case Tab.ORBITS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Trajectory+Designer+Activity.pdf';
            case Tab.LANDING:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Orbital+Descent+Activity.pdf';
            case Tab.HABITAT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Habitat+Builder+Activity.pdf';
            case Tab.SUSTAINABILITY:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Activities/Surface+Operations+Activity.pdf';
            case Tab.VR_COMPUTER_SETUP:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/VR/vr_computer_setup.pdf';
            case Tab.VR_SOFTWARE_SETUP:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/VR/vr_software_setup.pdf';
            default:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Knowledge Base.pdf';
        }
    }

    const getLesson = () => {
        switch (tab) {
            case Tab.PLANETS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Planetary Science Lesson.mp4';
            case Tab.SPACECRAFT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Ship Builder Lesson - 21522.mp4';
            case Tab.ORBITS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Orbital Mechanics Lesson.mp4';
            case Tab.LANDING:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Lander Lesson.mp4';
            case Tab.HABITAT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Habitat Lesson.mp4';
            case Tab.SUSTAINABILITY:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Lessons/Surface Ops Lesson.mp4';
            default:
                return null;
        }
    }

    const getTutorial = () => {
        switch (tab) {
            case Tab.PLANETS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Planet Builder Tutorial.mp4';
            case Tab.SPACECRAFT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Spacecraft Builder Tutorial.mp4';
            case Tab.ORBITS:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Orbit Designer Tutorial.mp4';
            case Tab.LANDING:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Orbital Descent Tutorial.mp4';
            case Tab.HABITAT:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Habitat Builder Tutorial.mp4';
            case Tab.SUSTAINABILITY:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Tutorials/Surface Operations Tutorial.mp4';
            case Tab.VR_COMPUTER_SETUP:
                return 'https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/VR/vr_computer_setup.mp4';
            default:
                return null;
        }
    }

    const [doc, lesson, tutorial] = [getDocument(), getLesson(), getTutorial()];

    const getSource = () => {
        switch (content) {
            case Content.DOCS:
                return doc;
            case Content.LESSON:
                return lesson;
            case Content.TUTORIAL:
                return tutorial;
        }
    }

    useEffect(() => {
        if (content === Content.__INIT__) {
            if (lesson) {
                setContent(Content.LESSON);
            } else {
                setContent(Content.DOCS);
            }
        }
        if (tab !== Tab.WELCOME && hasValue(WelcomeContent, content)) {
            if (lesson) {
                setContent(Content.LESSON);
            } else {
                setContent(Content.DOCS);
            }
        }
        if (tab !== Tab.SETUP && hasValue(SetupContent, content)) {
            if (lesson) {
                setContent(Content.LESSON);
            } else {
                setContent(Content.DOCS);
            }
        }
        if (content === Content.LESSON && !lesson) {
            setContent(Content.DOCS);
        }
    }, [tab, content]);

    useEffect(() => {
        const activityName = tabToActivityName(tab);
        const taskName = contentToTaskName(content);
        if (taskName === 'Other') {
            return;
        }
        const isSame = (a) => (
            a.eventId === selectedEvent?.id
            && a.name === activityName
            && a.task === taskName
        );
        const alreadyDone = user.completedActivities.find(a => isSame(a));
        let timeout;
        if (!alreadyDone && selectedEvent?.id) {
            timeout = setTimeout(() => {
                console.log('completing', selectedEvent?.id, activityName, taskName);
                dispatch(completeActivityTask(selectedEvent?.id, activityName, taskName));
            }, 5000);
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, [user.activities, tab, content]);

    const openMenu = () => {
        return (
            <Menu vertical style={{
                height: '100%',
            }}>
                <Button icon size='mini' floated='right' onClick={() => setMenuOpen(false)} style={{
                    position: 'relative',
                    zIndex: 10000,
                }}>
                    <Icon name='chevron right' />
                </Button>
                <Menu.Item>
                    <Menu.Header>Mission Prep</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name={Tab.WELCOME}
                            active={tab === Tab.WELCOME}
                            onClick={handleClick(Tab.WELCOME)}
                        />
                        <Menu.Item
                            name={Tab.SETUP}
                            active={tab === Tab.SETUP}
                            onClick={handleClick(Tab.SETUP)}
                        />
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Menu.Header>Activities</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name={Tab.PLANETS}
                            active={tab === Tab.PLANETS}
                            onClick={handleClick(Tab.PLANETS)}
                        />
                        <Menu.Item
                            name={Tab.SPACECRAFT}
                            active={tab === Tab.SPACECRAFT}
                            onClick={handleClick(Tab.SPACECRAFT)}
                        />
                        <Menu.Item
                            name={Tab.ORBITS}
                            active={tab === Tab.ORBITS}
                            onClick={handleClick(Tab.ORBITS)}
                        />
                        <Menu.Item
                            name={Tab.LANDING}
                            active={tab === Tab.LANDING}
                            onClick={handleClick(Tab.LANDING)}
                        />
                        <Menu.Item
                            name={Tab.HABITAT}
                            active={tab === Tab.HABITAT}
                            onClick={handleClick(Tab.HABITAT)}
                        />
                        <Menu.Item
                            name={Tab.SUSTAINABILITY}
                            active={tab === Tab.SUSTAINABILITY}
                            onClick={handleClick(Tab.SUSTAINABILITY)}
                        />
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Menu.Header>VR Experiences</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name={Tab.VR_COMPUTER_SETUP}
                            active={tab === Tab.VR_COMPUTER_SETUP}
                            onClick={handleClick(Tab.VR_COMPUTER_SETUP)}
                        />
                        <Menu.Item
                            name={Tab.VR_SOFTWARE_SETUP}
                            active={tab === Tab.VR_SOFTWARE_SETUP}
                            onClick={handleClick(Tab.VR_SOFTWARE_SETUP)}
                        />
                        <Menu.Item
                            name={Tab.SPACEWALK}
                            active={tab === Tab.SPACEWALK}
                            onClick={handleClick(Tab.SPACEWALK)}
                        />
                        <Menu.Item
                            name={Tab.SOLAR_SYSTEM}
                            active={tab === Tab.SOLAR_SYSTEM}
                            onClick={handleClick(Tab.SOLAR_SYSTEM)}
                        />
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Menu.Header>Help</Menu.Header>
                    <Menu.Menu>
                        <Menu.Item
                            name={Tab.OVERVIEW}
                            active={tab === Tab.OVERVIEW}
                            onClick={handleClick(Tab.OVERVIEW)}
                        />
                        <Menu.Item
                            name={Tab.DOWNLOAD_SOFTWARE}
                            active={tab === Tab.DOWNLOAD_SOFTWARE}
                            onClick={handleClick(Tab.DOWNLOAD_SOFTWARE)}
                        />
                        <Menu.Item
                            name={Tab.TEAM_CREATION}
                            active={tab === Tab.TEAM_CREATION}
                            onClick={handleClick(Tab.TEAM_CREATION)}
                        />
                        <Menu.Item
                            name={Tab.HARDWARE}
                            active={tab === Tab.HARDWARE}
                            onClick={handleClick(Tab.HARDWARE)}
                        />
                        <Menu.Item
                            name={Tab.ACCOUNT}
                            active={tab === Tab.ACCOUNT}
                            onClick={handleClick(Tab.ACCOUNT)}
                        />
                        {(user.type === UserType.ORG_MANAGER || user.type === UserType.ADMIN) && (
                            <Menu.Item
                                name={Tab.TEACHERS}
                                active={tab === Tab.TEACHERS}
                                onClick={handleClick(Tab.TEACHERS)}
                            />
                        )}
                        <Menu.Item
                            name={Tab.KNOWLEDGE_BASE}
                            active={tab === Tab.KNOWLEDGE_BASE}
                            onClick={handleClick(Tab.KNOWLEDGE_BASE)}
                        />
                    </Menu.Menu>
                </Menu.Item>
            </Menu>
        )
    }

    const closedMenu = () => {
        return (
            <Button icon primary size='mini' floated='right' onClick={() => setMenuOpen(true)} style={{
            }}>
                <Icon name='chevron left' />
            </Button>
        )
    }

    const renderWelcomeContent = () => {
        if (content === WelcomeContent.VIDEO) {
            return (
                <video
                    type="video/mp4"
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Welcome/Space+Teams+Welcome+Video+Final.mp4"
                    width="760"
                    height="415"
                    controls
                ></video>
            )
        }
        if (content === WelcomeContent.INFO_PACKET) {
            return (
                <iframe
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Welcome/Space+Teams+Overview+Updated.pdf"
                    key={WelcomeContent.INFO_PACKET}
                    width="100%"
                    style={{
                        height: "73vh",
                    }}
                />

            )
        }
    }

    const renderSetupContent = () => {
        console.log('setupContent', content);
        if (content === SetupContent.GETTING_STARTED) {
            return (
                <video
                    type="video/mp4"
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Student+Setup+Briefing+Guide.mp4"
                    width="760"
                    height="415"
                    controls
                ></video>
            )
        }
        if (content === SetupContent.COUNTDOWN) {
            console.log('COUNTDOWN');
            return (
                <iframe
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/Setup/LaunchCountdownList_STA.pdf"
                    key={SetupContent.COUNTDOWN}
                    width="100%"
                    style={{
                        height: "73vh",
                    }}
                />
            );
        }
        if (content === SetupContent.TEACHER_GUIDE) {
            return (
                <video
                    type="video/mp4"
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Org+Manager+Setup+Video-Global+Event.mp4"
                    width="760"
                    height="415"
                    controls
                ></video>
            )
        }
        if (content === SetupContent.TEACHER_DOCS) {
            return (
                <iframe
                    src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/OrganizationAccountCreationGuide_Competitions.pdf"
                    key={SetupContent.TEACHER_DOCS}
                    width="100%"
                    style={{
                        height: "73vh",
                    }}
                />
            )
        }
    }

    const renderVRContent = (demo) => {
        switch (demo) {
            case Tab.SPACEWALK: {
                return (
                    <video
                        type="video/mp4"
                        src="https://space-teams-public-resources.s3.us-west-2.amazonaws.com/Academy/VR/iss_demo.mp4"
                        width="760"
                        height="415"
                        controls
                    ></video>
                )
            }
            case Tab.SOLAR_SYSTEM: {
                return (
                    <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
                        <Header>Coming Soon!</Header>
                    </div>
                )
            }
        }
    }

    const renderActivityContent = () => {
        return (
            content !== Content.DOCS ? (
                <video
                    type="video/mp4"
                    src={getSource()}
                    width="760"
                    height="415"
                    controls
                ></video>
            ) : (
                <iframe
                    src={getSource()}
                    key={getSource()}
                    width="100%"
                    style={{
                        height: "73vh",
                    }}
                />
            ));
    }

    const renderContent = () => {
        if (tab === Tab.WELCOME) {
            if (!hasValue(WelcomeContent, content)) {
                setContent(WelcomeContent.INFO_PACKET);
            }
            return renderWelcomeContent();
        }
        if (tab === Tab.SETUP) {
            if (!hasValue(SetupContent, content)) {
                setContent(SetupContent.GETTING_STARTED);
            }
            return renderSetupContent();
        }
        if (tab === Tab.SPACEWALK) {
            return renderVRContent(tab);
        }
        if (tab === Tab.SOLAR_SYSTEM) {
            return renderVRContent(tab);
        }
        return renderActivityContent();
    }

    const renderTabs = () => {
        if (tab === Tab.WELCOME) {
            return (
                <Menu tabular attached='top'>
                    <Menu.Item
                        name={WelcomeContent.INFO_PACKET}
                        active={content === WelcomeContent.INFO_PACKET}
                        onClick={() => setContent(WelcomeContent.INFO_PACKET)}
                    />
                    <Menu.Item
                        name={WelcomeContent.VIDEO}
                        active={content === WelcomeContent.VIDEO}
                        onClick={() => setContent(WelcomeContent.VIDEO)}
                    />
                </Menu>
            );
        }
        if (tab === Tab.SETUP) {
            return (
                <Menu tabular attached='top'>
                    <Menu.Item
                        name={SetupContent.GETTING_STARTED}
                        active={content === SetupContent.GETTING_STARTED}
                        onClick={() => setContent(SetupContent.GETTING_STARTED)}
                    />
                    <Menu.Item
                        name={SetupContent.COUNTDOWN}
                        active={content === SetupContent.COUNTDOWN}
                        onClick={() => setContent(SetupContent.COUNTDOWN)}
                    />
                    {(user.type === UserType.ORG_MANAGER || user.type === UserType.ADMIN) && (
                        <Menu.Item
                            name={SetupContent.TEACHER_GUIDE}
                            active={content === SetupContent.TEACHER_GUIDE}
                            onClick={() => setContent(SetupContent.TEACHER_GUIDE)}
                        />
                    )}
                    {(user.type === UserType.ORG_MANAGER || user.type === UserType.ADMIN) && (
                        <Menu.Item
                            name={SetupContent.TEACHER_DOCS}
                            active={content === SetupContent.TEACHER_DOCS}
                            onClick={() => setContent(SetupContent.TEACHER_DOCS)}
                        />
                    )}
                </Menu>
            );
        }
        return (
            (lesson !== null || tutorial !== null) && (
                <Menu tabular attached='top'>
                    {lesson !== null && (
                        <Menu.Item
                            name={Content.LESSON}
                            active={content === Content.LESSON}
                            onClick={() => setContent(Content.LESSON)}
                        />
                    )}
                    {tutorial !== null && (
                        <Menu.Item
                            name={Content.TUTORIAL}
                            active={content === Content.TUTORIAL}
                            onClick={() => setContent(Content.TUTORIAL)}
                        />
                    )}
                    <Menu.Item
                        name={Content.DOCS}
                        active={content === Content.DOCS}
                        onClick={() => setContent(Content.DOCS)}
                    />
                </Menu>
            )
        )
    }

    return (
        <Container
            style={{
                width: '100%',
                marginTop: '-1rem',
            }}
        >
            <div style={{
                display: 'flex',
                alignItems: 'top',
                gap: 5,
            }}>
                {menuOpen ? openMenu() : closedMenu()}
                <div style={{ background: 'white', width: '100%' }}>
                    {renderTabs()}
                    <Card
                        className="dashboard-card"
                        centered
                        style={{ marginTop: 0 }}
                    >
                        <Card.Content>
                            {renderContent()}
                        </Card.Content>
                    </Card>
                </div>
            </div>
        </Container>
    )
}

export default Academy;
