import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { getCompetitionList, addToCart, removeFromCart, getLicenseItems } from "../actions/billing";
import { setActiveNavTab } from "../actions/ui";
import { NavTab } from "../utils/enums";
import {
    Button,
    Card,
    Divider,
    Grid
} from "semantic-ui-react";
import * as uuid from 'uuid';

import StoreList from "../components/StoreList";
import formatDate from "../utils/formatDate";

const OrganizationStore = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    let competitionId = useSelector((state) => state.user.desiredCompetitionId);
    if (!competitionId) {
        competitionId = new URLSearchParams(location.search).get("competition_id");
        if (competitionId) {
            localStorage.setItem("competition_id", competitionId);
        } else {
            competitionId = localStorage.getItem("competition_id");
        }
    }

    const user = useSelector((state) => state.user);
    const loading = useSelector((state) => state.billing.competitions.loading);
    const itemsList = useSelector((state) => {
        const list = state.billing.licenseItems.list;
        return list;
    });
    const userComps = useSelector((state) => state.competitions.userComps);
    const cart = useSelector((state) => state.billing.cart.items);
    const org = useSelector((state) => state.organization.org)

    const isSelected = (comp) => {
        return cart.some((item) => item.id === comp.id);
    };

    const handleAddToCart = (id) => {
        dispatch(addToCart(id));
    };

    const handleRemoveFromCart = (id) => {
        dispatch(removeFromCart(id));
    };

    useEffect(() => {
        dispatch(setActiveNavTab(NavTab.EVENTS));
        dispatch(getLicenseItems());
    }, []);

    const getPriceAndCurrency = (comp) => {
        let price = comp.price;
        let currency = "USD";
        // const alternatePrice = comp.alternatePrices.find(ac => ac.country === user.country);
        // if (alternatePrice) {
        //     price = alternatePrice.price;
        //     currency = alternatePrice.currency;
        // }
        return {
            price: Number.parseFloat(price).toFixed(2),
            currency
        };
    };

    const renderContent = () => {
        if (loading) {
            return (
                <p>
                    Loading...
                </p>
            );
        }
        return (
            <StoreList
                items={itemsList.map((item) => {
                    const { price, currency } = getPriceAndCurrency(item);
                    // const description = "1 year membership";
                    return {
                        id: item.id,
                        name: item.name,
                        price, currency,
                        description: item.description,
                        selected: isSelected(item),
                        qty: cart.find(i => i.id === item.id)?.qty || 0,
                    };
                })}
                onAddToCart={handleAddToCart}
                onRemoveFromCart={handleRemoveFromCart}
                userType={user.type}
            />
        );
    };

    const calculateLicense = () => {
        const seats = cart.map(i => ({ ...i, ...itemsList.find(_i => _i.id === i.id) })).reduce((acc, curr) => acc + (curr.seats * curr.qty), 0);
        const accounts = cart.map(i => ({ ...i, ...itemsList.find(_i => _i.id === i.id) })).reduce((acc, curr) => acc + (curr.accounts * curr.qty), 0);
        return { seats, accounts };
    }

    const renderHeader = () => {
        const { seats, accounts } = calculateLicense();

        return (
            <>
                <Card.Content>
                    <Card.Header>Licenses</Card.Header>
                </Card.Content>
                <Card.Content>
                    <Grid>
                        <Grid.Row style={{ padding: 0, paddingTop: 10 }}>
                            <Grid.Column width={2} style={{ fontWeight: 'bold' }}>
                                New
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Seats: {seats}
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Accounts: {accounts}
                            </Grid.Column>
                        </Grid.Row>
                        <Divider/>
                        <Grid.Row style={{ padding: 0, paddingBottom: 5 }}>
                            <Grid.Column width={2} style={{ fontWeight: 'bold' }}>
                                Existing
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Seats: {org?.license?.numSeats ?? 0}
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Accounts: {org?.license?.students?.length ?? 0}
                            </Grid.Column>
                        </Grid.Row>
                        <Divider/>
                        <Grid.Row style={{ padding: 0, paddingBottom: 5 }}>
                            <Grid.Column width={2} style={{ fontWeight: 'bold' }}>
                                Total After Purchase
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Seats: {(org?.license?.numSeats ?? 0) + seats}
                            </Grid.Column>
                            <Grid.Column width={4}>
                                Accounts: {(org?.license?.students?.length ?? 0) + accounts}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
            </>
        )
    }

    return (
        <Card className="dashboard-card" centered>
            {renderHeader()}
            <Card.Content>
                {renderContent()}
            </Card.Content>
            <Card.Content>
                <Button
                    onClick={() => history.push("/cart")}
                    primary
                    fluid
                >
                    Cart
                </Button>
            </Card.Content>
        </Card>
    );
}

export default OrganizationStore;
