import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    Container,
    Card,
    Loader,
    Input,
    Dropdown,
    Button,
    Label,
    Icon,
} from "semantic-ui-react";

import UserList from "../components/UserList";

import { getCompetitionUsers } from "../actions/user";

const searchByOptions = [
    { key: "username", text: "Username", value: "username" },
    { key: "email", text: "Email", value: "email" },
    { key: "firstName", text: "First Name", value: "firstName" },
    { key: "lastName", text: "Last Name", value: "lastName" },
    { key: "competitionRole", text: "Competition Role", value: "competitionRole" },
    { key: "parentApprovalStatus", text: "Parent Approval Status", value: "parentApprovalStatus" },
];

const FilterItem = ({ filter, removeFilterItem }) => {
    return (
        <Grid.Column width={4}>
            <Label>
                {`${filter.searchField === "" ? "" : filter.searchField + ":"} ${filter.search}`}
                <Icon name="delete" onClick={() => removeFilterItem(filter.id)}/>
            </Label>
        </Grid.Column>
    );
};

const Search = ({
    search,
    setSearch,
    searchField,
    setSearchField,
    clear,
    filters,
    addFilter,
    removeFilter,
    clearFilters,
}) => {
    return (
        <Grid style={{ marginTop: "1rem" }}>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Input
                        placeholder="Search"
                        fluid
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    <Dropdown
                        selection
                        search
                        fluid
                        placeholder="Search By"
                        value={searchField}
                        onChange={(e, { value }) => setSearchField(value)}
                        options={searchByOptions}
                    />
                </Grid.Column>
                <Grid.Column width={1}>
                    <Button
                        icon="plus" primary
                        onClick={() => {
                            addFilter({ search, searchField });
                            clear();
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={1}>
                    <Button
                        style={{ marginLeft: "-4px" }}
                        icon="close" color="red"
                        onClick={clear}
                    />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {filters.map((filter) => (
                    <FilterItem
                        key={filter.id}
                        filter={filter}
                        removeFilterItem={id => removeFilter(id)}
                    />
                ))}
                <Grid.Column width={4}>
                    <Button icon="close" color="red" content="Clear All" onClick={clearFilters} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const AdminUserManagement = () => {

    const dispatch = useDispatch();

    const currentComp = useSelector((state) => state.competitions.currentCompetition);
    const activeComps = useSelector((state) => state.competitions.activeComps);
    const loading = useSelector((state) => state.user.competitionUsers.loading);
    const users = useSelector((state) => state.user.competitionUsers.list);

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [lastLoadedComp, setLastLoadedComp] = React.useState(currentComp);
    const [search, setSearch] = React.useState("");
    const [searchField, setSearchField] = React.useState("");
    const [filters, setFilters] = React.useState([]);
    const [userType, setUserType] = React.useState("all");

    const clearSearch = () => {
        setSearch("");
        setSearchField("");
    };

    const clearFilters = () => {
        setFilters([]);
    };

    const addFilter = (filter) => {
        setFilters(s => [ ...s, { ...filter, id: filters.length }]);
    };

    const removeFilter = (id) => {
        setFilters(filters.filter(f => f.id !== id));
    };

    // Loading
    React.useEffect(() => {
        if (currentComp && !loading && !hasLoaded) {
            setLastLoadedComp(currentComp);
            dispatch(getCompetitionUsers(currentComp.id, userType));
            setHasLoaded(true);
        }
    }, [currentComp, loading, hasLoaded]);
    // Change competition
    React.useEffect(() => {
        if (hasLoaded && currentComp && currentComp.id !== lastLoadedComp.id && !loading) {
            setHasLoaded(false);
            setLastLoadedComp(currentComp);
            dispatch(getCompetitionUsers(currentComp.id, userType));
        }
    }, [currentComp, lastLoadedComp, hasLoaded, loading]);
    // Change user type
    React.useEffect(() => {
        if (currentComp) {
            dispatch(getCompetitionUsers(currentComp.id, userType));
        }
    }, [userType]);

    const userTypeOptions = [
        { key: "all", value: "all", text: "All" },
        { key: "allRegistered", value: "allRegistered", text: "All Registered" },
        { key: "competitor", value: "competitor", text: "Competitors" },
        { key: "mentor", value: "mentor", text: "Mentors" },
        { key: "manager", value: "manager", text: "Managers" },
    ];

    const renderUserList = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <UserList
                users={users}
                search={search}
                searchByOptions={searchByOptions}
                searchField={searchField}
                filters={filters}
                addFilter={addFilter}
                removeFilter={removeFilter}
                clearFilters={clearFilters}
                competitionList={activeComps}
                selectedCompetition={currentComp}
                userType={userType}
            />
        );
    };

    return (
        <Container>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Grid style={{ marginTop: "1rem" }}>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <h3>User Management</h3>
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Dropdown
                                    selection
                                    search
                                    fluid
                                    placeholder="User Type"
                                    value={userType}
                                    onChange={(e, { value }) => setUserType(value)}
                                    options={userTypeOptions}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button
                                    icon="refresh"
                                    onClick={() => dispatch(getCompetitionUsers(currentComp.id, userType))}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
                <Card.Content>
                    <h4>Search</h4>
                    <Search
                        search={search}
                        setSearch={setSearch}
                        searchField={searchField}
                        setSearchField={setSearchField}
                        clear={clearSearch}
                        filters={filters}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        clearFilters={clearFilters}
                    />
                </Card.Content>
                <Card.Content>
                    {renderUserList()}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default AdminUserManagement;
