import {
    SET_AFFILIATES,
    SET_AFFILIATES_LOADING,
    SET_AFFILIATE_UPDATE_LOADING,
    LOGOUT,
    SET_AFFILIATE_TO_ADD,
    SET_ADD_AFFILIATE_MODAL_OPEN,
} from "../actions/types";

const initialState = {
    list: [],
    loading: false,
    updateLoading: false,
    newAffiliate: {
        name: "",
        code: "",
    },
};

const affiliates = (state = initialState, action) => {
    switch (action.type) {
        case SET_AFFILIATES: {
            return {
                ...state,
                list: action.payload,
            };
        }
        case SET_AFFILIATES_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case SET_AFFILIATE_UPDATE_LOADING: {
            return {
                ...state,
                updateLoading: action.payload,
            };
        }
        case SET_AFFILIATE_TO_ADD: {
            return {
                ...state,
                newAffiliate: action.payload,
            };
        }
        case SET_ADD_AFFILIATE_MODAL_OPEN: {
            return {
                ...state,
                newAffiliate: initialState.newAffiliate,
            };
        }
        case LOGOUT: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default affiliates;
