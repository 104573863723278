/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import {
    Card,
    Item,
    Message,
    Dropdown,
    Form
} from "semantic-ui-react";
import {
    getCompetitionLeaderboard,
    setSelectedLeaderboard
} from "../actions/competitions";
import {
    setActiveNavTab
} from "../actions/ui";
import { NavTab, UserType } from "../utils/enums";
import { uniqueById } from "../utils/unique";
import { getAllTeams } from "../actions/teams";
import { getCompetitionUsers } from "../actions/user";

import LeaderboardTeam from "../components/LeaderboardTeam";
const createLeaderboard = (scoreList, league) => {
    return scoreList
        .filter(t => league === "ALL" || t.league === league)
        .map((s, i) => ({
            key: s.team.id,
            score: s.score,
            id: s.team.id,
            name: s.team.name,
            league: s.league,
            rank: i+1
        }));
};

const FullLeaderboard = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const userList = useSelector((state) =>
        state.user.competitionUsers.list.filter(u => u.type === "USER"));
    const usersLoading = useSelector((state) =>
        state.user.competitionUsers.loading);
    const teams = useSelector((state) => state.teams.list);
    const competitions = useSelector(
        (state) => {
            if (user.type === UserType.ADMIN || user.type === UserType.ORG_MANAGER) {
                return state.competitions.activeComps;
            } else {
                return uniqueById(state.competitions.userComps.filter(c => c.teamIsActive || c.role !== 'COMPETITOR'));
            }
        }
    );
    const leaderboard = useSelector((state) => state.competitions.leaderboard.list);
    const leaderboardLoading = useSelector((state) => state.competitions.leaderboard.loading);
    const selectedComp = useSelector((state) => state.competitions.currentCompetition);
    const leagues = selectedComp && selectedComp.leagues;
    const teamsLoading = useSelector((state) => state.teams.loading);
    const compLoading = useSelector((state) => !state.competitions.currentCompetition);

    const [activities, setActivities] = React.useState([]);
    const [activityOptions, setActivityOptions] = React.useState([
        { key: "Total Score", text: "Total Score", value: "Total" },
    ]);
    const [selectedActivity, setSelectedActivity] = React.useState("Total");
    const [selectedLeague, setSelectedLeague] = React.useState("ALL");

    React.useEffect(() => {
        dispatch(setActiveNavTab(NavTab.LEADERBOARD));
        if (user.type === UserType.ADMIN && !compLoading) {
            dispatch(getCompetitionUsers(selectedComp.id, "paid"));
            dispatch(getAllTeams(selectedComp.id));
        }
    }, []);

    React.useEffect(() => {
        const leaderboardPoll = () => {
            if (selectedComp) {
                dispatch(getCompetitionLeaderboard(selectedComp.id, selectedActivity));
            }
        };
        leaderboardPoll();
        const interval = setInterval(leaderboardPoll, 5000);
        return () => clearInterval(interval);
    }, [selectedComp, selectedActivity, getCompetitionLeaderboard]);

    React.useEffect(() => {
        if (selectedComp) {
            if (user.type === UserType.ADMIN && !compLoading) {
                dispatch(getCompetitionUsers(selectedComp.id, "paid"));
                dispatch(getAllTeams(selectedComp.id));
            }
            if (selectedComp.activities) {
                setActivities(selectedComp.activities);
                setActivityOptions([
                    { key: "Total Score", text: "Total Score", value: "Total" },
                    ...selectedComp.activities.map(a => ({
                        key: a.id,
                        text: a.name,
                        value: a.id
                    }))
                ]);
            } else {
                setActivityOptions([
                    { key: "Total Score", text: "Total Score", value: "Total" },
                ]);
            }
        }
    }, [selectedComp]);

    // const getCompetitionOptions = () => competitions
    //       .filter(c => c.teamId && c.teamIsActive)
    //       .map(c => ({
    //           key: c.id,
    //           text: c.name,
    //           value: c.id
    //       }));

    const myTeam = teams
          .filter(t => selectedComp && t.competitionId === selectedComp.id)
          .find(t => t.members.some(m => m.id === user.id));

    const getActivity = () => activities.find(a => a.id === selectedActivity);

    const renderLeaderboard = () => {
        if (!selectedComp || !selectedComp.id) {
            return (
                <Message>
                    <Message.Header>You Must Select an Event</Message.Header>
                    <p>
                        You must select an event to view the leaderboard. If you have
                        not registered for a event yet, visit the 'Events' tab above.
                    </p>
                </Message>
            );
        }
        // if (!competitions || !competitions.length) {
        //     return (
        //         <Message>
        //             <Message.Header>Create a Team</Message.Header>
        //             <p>
        //                 Create a team on the home page to view the leaderboard
        //             </p>
        //         </Message>
        //     );
        // }
        if (leaderboard.length === 0 && leaderboardLoading)
            return "LOADING...";
        if (leaderboard && leaderboard.length !== 0) {
            return (
                <Item.Group divided>
                    {createLeaderboard(leaderboard, selectedLeague)
                        .map(t => (
                            <LeaderboardTeam
                                key={t.id}
                                team={t}
                                myTeam={myTeam}
                                activity={getActivity()}
                                full
                            />
                        ))
                    }
                </Item.Group>
            );
        }
        return (
            <Item.Group divided>
            </Item.Group>
        );
    };

    let leagueOpts = [{ key: "ALL", text: "All", value: "ALL" }];
    if (leagues)
        leagueOpts = [...leagueOpts, ...leagues.map(l => ({
            key: l.id,
            text: l.name,
            value: l.name
        }))];

    const renderDownloadLink = () => {
        if (teams.length === 0 || teamsLoading) {
            return "Loading...";
        }
        if (userList.length === 0 || usersLoading) {
            return "Loading...";
        }
        const activityNames = [
             "Spacecraft Builder", "Orbit Designer", "Orbital Descent",
             "Habitat Builder", "Surface Operations",
        ]
        const userFields = [
            "User Name", "User Email", "User Address",
            "User City", "User State", "User Country", "User Zip Code",
            "User Date of Birth", "User School/Org",
            "Access Code Organization",
        ]
        const rows = leaderboard.flatMap((s, i) => {
            const rank = i + 1;
            const team = teams.find(t => t.id === s.team.id);
            const score = s.score;
            const scoreWithoutSO = Object.keys(s.activityScores || {})
                .reduce((total, key) => {
                    if (key === "Surface Operations") {
                        return total;
                    }
                    return total + parseFloat(s.activityScores[key]);
                }, 0);
            const league = s.league;
            return team.members.map(tm => {
                const user = userList.find(u => u.id === tm.userId);
                if (!user) {
                    return [];
                }
                const accessCodeOrg = user.competitions?.find(c => c.competitionId === selectedComp.id)?.organization;
                return [rank, team.name, league, score, scoreWithoutSO,
                    ...activityNames.map(an => s.activityScores[an] || 0),
                    tm.name, tm.email, user.address, user.city, user.stateOrProvince,
                    user.country, user.zipCode, user.dateOfBirth, user.schoolOrOrganization,
                    accessCodeOrg?.name,
                ];
            })
        });
        const data = [
            ["Rank", "Team Name", "League", "Total Score", "Total Without Surface Ops",
                ...activityNames,
                ...userFields,
            ],
            ...rows
        ];
        return (
            <CSVLink
                data={data}
                style={{ fontSize: "1.2rem" }}
                disabled={leaderboardLoading}
            >
                Download Leaderboard
            </CSVLink>
        );
    };

    return (
        <Card className="dashboard-card" centered>
            <Card.Content>
                <Card.Header>Leaderboard</Card.Header>
            </Card.Content>
            <Card.Content>
                <Form>
                    <label htmlFor="league" style={{ marginRight: "1rem" }}>League</label>
                    <Dropdown
                        selection
                        search
                        name="league"
                        label="League"
                        placeholder="Select League"
                        value={selectedLeague}
                        onChange={(e, { value }) => setSelectedLeague(value)}
                        options={leagueOpts}
                    />
                </Form>
            </Card.Content>
            {competitions && !!competitions.length && selectedComp && (
                <Card.Content>
                    <Form>
                        <Form.Select
                            inline
                            label="Activity"
                            placeholder="Select Activity"
                            value={selectedActivity}
                            options={activityOptions}
                            onChange={(e, { value }) => setSelectedActivity(value)}
                        />
                    </Form>
                </Card.Content>
            )}
            <Card.Content>
                {renderLeaderboard()}
            </Card.Content>
            <Card.Content>
                {user.type === UserType.ADMIN && renderDownloadLink()}
            </Card.Content>
        </Card>
    );
};

export default FullLeaderboard;
