import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List } from 'semantic-ui-react';
import { setActiveNavTab } from '../actions/ui';
import { NavTab } from '../utils/enums';

const Gallery = () => {
    const currentComp = useSelector(state => state.competitions.currentCompetition);
    const url = `https://gallery.spacecraft-vr.com?event=${encodeURIComponent(currentComp?.name ?? 'Public')}`;
    const dispatch = useDispatch();

    const [hasFetched, setHasFetched] = useState(false);
    const [loading, setLoading] = useState(false);
    const [links, setLinks] = useState([]);
    const [error, setError] = useState(null);

    // Set correct nav tab in case page reached with URL
    useEffect(() => {
        dispatch(setActiveNavTab(NavTab.GALLERY));
    }, []);

    useEffect(() => {
        if (!hasFetched && !loading && error === null) {
            setLoading(true);
            setHasFetched(true);
            fetch(url)
                .then(resp => {
                    resp.json()
                        .then(data => setLinks(data))
                        .catch(() => setError('Parsing Error'))
                })
                .catch(err => setError(err?.toString()))
                .finally(() => setLoading(false));
        }
    }, [hasFetched, loading]);

    useEffect(() => {
        setHasFetched(false);
    }, [currentComp?.name])

    console.log('links', links);

    const renderContent = () => {
        return (
            <List divided relaxed style={{ maxWidth: 500, margin: '0 auto' }}>
                {links.map(({ url, title, description }) => (
                    <List.Item key={title}>
                        <List.Content>
                            <List.Header>{title}</List.Header>
                            <List.Description>{description}</List.Description>
                        </List.Content>
                        <List.Content>
                            <img src={url} style={{
                                display: 'block',
                                width: '100%',
                            }} />
                        </List.Content>
                    </List.Item>
                ))}
            </List>
        );
    }

    return (
        <div style={{ background: 'white', padding: '2rem', borderRadius: 10 }}>
            <h1>Gallery</h1>
            {loading ? "Loading..." : renderContent()}
        </div>
    )
}

export default Gallery;
