/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { NavTab, OrgMode, UserType } from "./utils/enums";
import { PARENT_CONSENT_AGE } from "./utils/constants";
import "./App.scss";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import AlertModal from "./components/AlertModal";
import ConfirmationModal from "./components/ConfirmationModal";
import LoginModal from "./components/LoginModal";
import CreateTeamModal from "./components/CreateTeamModal";
import TeamInviteModal from "./components/TeamInviteModal";
import TeamInviteTypeModal from "./components/TeamInviteTypeModal";
import InviteToSimModal from "./components/InviteToSimModal";
import MentorInviteModal from "./components/MentorInviteModal";
import Landing from "./pages/Landing";
import MentorSignup from "./pages/MentorSignup";
import JoinTeamModal from "./components/JoinTeamModal";
import JoinCompetitionModal from "./components/JoinCompetitionModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import AdminHome from "./pages/AdminHome";
import Competitions from "./pages/Competitions";
import Signup from "./pages/Signup";
import LearningPlatform from "./pages/LearningPlatform";
import AccountInfo from "./pages/AccountInfo";
import ActivateAccount from "./pages/ActivateAccount";
import ParentInfo from "./pages/ParentInfo";
import ParentConsent from "./pages/ParentConsent";
import ResetPassword from "./pages/ResetPassword";
import AccountSettings from "./pages/AccountSettings";
import AddAccessCodes from "./pages/AddAccessCodes";
import BugReportModal from "./components/BugReportModal";
import CreateAnnouncementModal from "./components/CreateAnnouncementModal";
import AddAffiliateModal from "./components/AddAffiliateModal";
import ScorecardModal from "./components/ScorecardModal";
import ScorecardComparisonModal from "./components/ScorecardComparisonModal";
import CompetitionInfoModal from "./components/CompetitionInfoModal";
import CreateCompetition from "./pages/CreateCompetition";
import CompetitionRegistration from "./pages/CompetitionRegistration";
import PaymentConfirmationModal from "./components/PaymentConfirmationModal";
import CompetitionBulkPay from "./pages/CompetitionBulkPay";
import BulkPaymentConfirmationModal from "./components/BulkPaymentConfirmationModal";
import OrganizationRegistration from "./pages/OrganizationRegistration";
import SignupTypeModal from "./components/SignupTypeModal";
import FullLeaderboard from "./pages/FullLeaderboard";
import Downloads from "./pages/Downloads";
import Chat from "./pages/Chat";
import AdminUserManagement from "./pages/AdminUserManagement";
import AdminTeamManagement from "./pages/AdminTeamManagement";
import Affiliates from "./pages/Affiliates";
import AdminCompetitionManagement from "./pages/AdminCompetitionManagement";
import ChatModeration from "./pages/ChatModeration";
import Store from "./pages/Store";
import Cart from "./pages/Cart";
import Checkout from "./pages/Checkout";
import Receipt from "./pages/Receipt";
import AddAccessCodesModal from "./components/AddAccessCodesModal";
import AddManagerModal from "./components/AddManagerModal";
import InviteMentorModal from "./components/InviteMentorModal";
import TransferOwnershipModal from "./components/TransferOwnershipModal";

import {
  openLoginModal,
  closeLoginModal,
  openCreateTeamModal,
  closeCreateTeamModal,
  openTeamInviteModal,
  closeTeamInviteModal,
  openJoinCompetitionModal,
  closeJoinCompetitionModal,
  setAppLoading,
  openResetPasswordModal,
  closeResetPasswordModal,
  closeCompetitionInfoModal,
  setActiveNavTab,
  openAddAccessCodesModal,
  closeAddAccessCodesModal,
  openAddManagerModal,
  closeAddManagerModal,
  openInviteMentorModal,
  closeInviteMentorModal,
  openTransferOwnershipModal,
  closeTransferOwnershipModal,
  openAddOrgModal,
  closeAddOrgModal,
  openAdminAddLicenseModal,
  closeAdminAddLicenseModal,
} from "./actions/ui";
import { loadUserData } from "./actions/user";
import { setCurrentCompetition } from "./actions/competitions";
import { Loader } from "semantic-ui-react";
import moment from "moment";

import pusherClient from "./utils/pusher";
import AdminOrganizationManagement from "./pages/AdminOrganizationManagement";
import AddOrganizationModal from "./components/AddOrganizationModal";
import AcademyLicenses from "./pages/AcademyLicenses";
import AcademyEvents from "./pages/AcademyEvents";
import OrganizationStore from "./pages/OrganizationStore";
import OrganizationSelfPayStore from "./pages/OrganizationSelfPayStore";
import JoinOrganization from "./pages/JoinOrganization";
import UploadStudents from "./pages/UploadStudents";
import ResetTempPasswordModal from "./components/ResetTempPasswordModal";
import AdminResetPasswordModal from "./components/AdminResetPasswordModal";
import SelfPay from "./pages/SelfPay";
import Invoice from "./pages/Invoice";
import AdminAddLicenseModal from "./components/AdminAddLicenseModal";
import Gallery from "./pages/Gallery";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const ui = useSelector((state) => state.ui);
  const user = useSelector((state) => state.user);
  const userComps = useSelector((state) => state.competitions.userComps);
  const activeComps = useSelector((state) => state.competitions.activeComps);
  const orgMode = useSelector((state) => state.organization.mode);
  const selectedComp = useSelector(
    (state) => state.competitions.currentCompetition
  );

  // Set admin competition
  React.useEffect(() => {
    if (
      user.loggedIn &&
      user.type === UserType.ADMIN &&
      activeComps &&
      !selectedComp
    ) {
      const sortedActiveComps = activeComps.sort((a, b) => {
        const aStart = moment(a.startDate);
        const bStart = moment(a.startDate);
        return aStart.diff(bStart);
      });
      if (sortedActiveComps.length !== 0) {
        dispatch(setCurrentCompetition(sortedActiveComps[0].id));
      }
    }
  }, [user.loggedIn, user.type, activeComps, selectedComp]);

  // Set org competition
  React.useEffect(() => {
    if (
      user.loggedIn &&
      user.type === UserType.ORG_MANAGER &&
      activeComps &&
      !selectedComp
    ) {
      const sortedActiveComps = activeComps
        .filter((c) => c.organizationId === user.organizationId)
        .sort((a, b) => {
          const aStart = moment(a.startDate);
          const bStart = moment(b.startDate);
          return aStart.diff(bStart);
        });
      if (sortedActiveComps.length !== 0) {
        dispatch(setCurrentCompetition(sortedActiveComps[0].id));
      }
    }
  }, [user.loggedIn, user.type, activeComps, selectedComp]);

  React.useEffect(() => {
    if (localStorage.getItem("token")) dispatch(setAppLoading(true));
    dispatch(loadUserData());
  }, []);

  React.useEffect(() => {
    if (user.loggedIn) {
      if (!pusherClient.isConnected) {
        pusherClient
          .connect()
          .then(() => console.log("Pusher connected"))
          .catch((err) => console.warn("Pusher connection error:", err));
      }
    } else {
      // not logged in
      if (pusherClient.isConnected) {
        pusherClient.disconnect();
      }
    }
  }, [user.loggedIn]);

  let competitionId = new URLSearchParams(location.search).get(
    "competition_id"
  );
  if (competitionId) localStorage.setItem("competition_id", competitionId);
  else competitionId = localStorage.getItem("competition_id");

  const renderDashboardContent = (tab) => {
    switch (tab) {
      case NavTab.HOME: {
        return <Home />;
      }
      case NavTab.SIMULATIONS: {
        history.push("/simulations");
        return <div />;
      }
      case NavTab.COMPETITIONS: {
        history.push("/competitions");
        return <div />;
      }
      case NavTab.LEADERBOARD: {
        history.push("/leaderboard");
        return <div />;
      }
      case NavTab.CHAT: {
        history.push("/chat");
        return <div />;
      }
      case NavTab.DOWNLOADS: {
        history.push("/downloads");
        return <div />;
      }
      case NavTab.EVENTS: {
        history.push("/events");
        return <div />;
      }
      default: {
        setActiveNavTab(NavTab.HOME);
        return <Home />;
      }
    }
  };

  const renderAdminDashboardContent = (tab) => {
    switch (tab) {
      case NavTab.HOME: {
        return <AdminHome />;
      }
      case NavTab.USER_MANAGEMENT: {
        history.push("/user-management");
        return <div />;
      }
      case NavTab.TEAM_MANAGEMENT: {
        history.push("/team-management");
        return <div />;
      }
      case NavTab.CHAT: {
        history.push("/chat");
        return <div />;
      }
      case NavTab.LEADERBOARD: {
        history.push("/leaderboard");
        return <div />;
      }
      default: {
        setActiveNavTab(NavTab.HOME);
        return <AdminHome />;
      }
    }
  };

  const navHeight = (plus) => `${6 + plus}rem`;

  const renderApp = () => {
    const loginBlockedPaths = [
      "/signup",
      "/mentor-signup",
      "/organization-registration",
      "/competition-bulk-pay",
    ];

    if (
      user.loggedIn &&
      loginBlockedPaths.some((p) => location.pathname.startsWith(p))
    ) {
      history.push("/");
    }

    if (
      user.type !== UserType.INITIAL_USER &&
      location.pathname.startsWith("/finish-registration")
    ) {
      let nextUrl = "/join-organization";
      if (competitionId) nextUrl += "?competition_id=" + competitionId;
      if (userComps.length > 0) nextUrl = "/";
      history.push(nextUrl);
    }
    if (ui.loading) {
      return <Loader active />;
    }
    if (!user.loggedIn) {
      const blockedPaths = [
        "/competitions",
        "/leaderboard",
        "/downloads",
        "/chat",
        "/account-settings",
      ];
      if (blockedPaths.some((p) => location.pathname.startsWith(p))) {
        history.push("/");
      }
      return (
        <>
          <div style={{ paddingTop: navHeight(4) }}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/landing" component={Landing} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/mentor-signup" component={MentorSignup} />
              <Route
                exact
                path="/activate-user-account"
                component={ActivateAccount}
              />
              <Route
                exact
                path="/reset-password/:key"
                component={ResetPassword}
              />
              <Route
                exact
                path="/competition-bulk-pay"
                component={CompetitionBulkPay}
              />
              <Route
                exact
                path="/organization-registration"
                component={OrganizationRegistration}
              />
              <Route exact path="/parent-consent" component={ParentConsent} />
            </Switch>
            <ResetPasswordModal
              open={ui.resetPasswordModalOpen}
              onOpen={() => dispatch(openResetPasswordModal())}
              onClose={() => dispatch(closeResetPasswordModal())}
            />
            <AlertModal />
            <ConfirmationModal />
            <LoginModal
              open={ui.loginModalOpen}
              onOpen={() => dispatch(openLoginModal())}
              onClose={() => dispatch(closeLoginModal())}
            />
            <BulkPaymentConfirmationModal />
            <SignupTypeModal />
          </div>
          <Navbar height={navHeight} />
        </>
      );
    }
    if (user.type === UserType.INITIAL_USER) {
      if (!location.pathname.startsWith("/finish-registration")) {
        let url = "/finish-registration";
        if (competitionId) url += "?competition_id=" + competitionId;
        history.push(url);
      }
      return (
        <>
          <div style={{ paddingTop: navHeight(4) }}>
            <AlertModal />
            <ConfirmationModal />
            <Route exact path="/finish-registration" component={AccountInfo} />
          </div>
          <Navbar height={navHeight} />
        </>
      );
    }
    if (user.type === UserType.ADMIN) {
      return (
        <>
          <div style={{ paddingTop: navHeight(4) }}>
            <Switch>
              <Route
                exact
                path="/"
                component={() => renderAdminDashboardContent(ui.activeNavTab)}
              />
              <Route
                exact
                path="/user-management"
                component={AdminUserManagement}
              />
              <Route
                exact
                path="/team-management"
                component={AdminTeamManagement}
              />
              <Route
                exact
                path="/competition-management"
                component={AdminCompetitionManagement}
              />
              <Route exact path="/competitions" component={Competitions} />
              <Route exact path="/affiliates" component={Affiliates} />
              <Route
                exact
                path="/organizations"
                component={AdminOrganizationManagement}
              />
              <Route
                exact
                path="/account-settings"
                component={AccountSettings}
              />
              <Route
                exact
                path="/create-competition"
                component={CreateCompetition}
              />
              <Route exact path="/chat" component={ChatModeration} />
              <Route exact path="/gallery" component={Gallery} />
              <Route exact path="/leaderboard" component={FullLeaderboard} />
              <Route
                exact
                path="/learning-platform"
                component={LearningPlatform}
              />
            </Switch>
            <AlertModal />
            <ConfirmationModal />
            <BugReportModal />
            <CreateAnnouncementModal />
            <AddAffiliateModal />
            <AdminAddLicenseModal
              open={ui.adminAddLicenseModalOpen}
              onOpen={() => dispatch(openAdminAddLicenseModal())}
              onClose={() => dispatch(closeAdminAddLicenseModal())}
            />
            <AddOrganizationModal
              open={ui.addOrgModalOpen}
              onOpen={() => dispatch(openAddOrgModal())}
              onClose={() => dispatch(closeAddOrgModal())}
            />
            <AddAccessCodesModal
              open={ui.addAccessCodesModalOpen}
              onOpen={() => dispatch(openAddAccessCodesModal())}
              onClose={() => dispatch(closeAddAccessCodesModal())}
            />
            <AddManagerModal
              open={ui.addOrgManagerModalOpen}
              onOpen={() => dispatch(openAddManagerModal())}
              onClose={() => dispatch(closeAddManagerModal())}
            />
            <InviteMentorModal
              open={ui.inviteMentorModalOpen}
              onOpen={() => dispatch(openInviteMentorModal())}
              onClose={() => dispatch(closeInviteMentorModal())}
            />
            <LoginModal
              open={ui.loginModalOpen}
              onOpen={() => dispatch(openLoginModal())}
              onClose={() => dispatch(closeLoginModal())}
            />
            <AdminResetPasswordModal />
          </div>
          <Navbar height={navHeight} />
        </>
      );
    }

    if (
      !user.organizationId &&
      location.pathname !== "/join-organization" &&
      location.pathname !== "/self-pay" &&
      location.pathname !== "/cart" &&
      location.pathname !== "/checkout"
    ) {
      history.push("/join-organization");
    }

    return (
      // Regular user logged in
      <>
        <div style={{ paddingTop: navHeight(4) }}>
          <Switch>
            <Route
              exact
              path="/"
              component={() => renderDashboardContent(ui.activeNavTab)}
            />
            <Route exact path="/landing" component={Landing} />
            <Route exact path="/account-settings" component={AccountSettings} />
            <Route exact path="/competitions" component={Competitions} />
            <Route exact path="/leaderboard" component={FullLeaderboard} />
            <Route exact path="/downloads" component={Downloads} />
            <Route
              exact
              path="/competition-registration"
              component={CompetitionRegistration}
            />
            <Route
              exact
              path="/learning-platform"
              component={LearningPlatform}
            />
            <Route exact path="/parent-info" component={ParentInfo} />
            <Route exact path="/update-parent-info" component={ParentInfo} />
            <Route exact path="/parent-consent" component={ParentConsent} />
            <Route exact path="/chat" component={Chat} />
            <Route exact path="/gallery" component={Gallery} />
            <Route
              exact
              path="/activate-user-account"
              component={ActivateAccount}
            />
            <Route
              exact
              path="/events"
              component={
                user.type === UserType.ORG_MANAGER ? AcademyEvents : Store
              }
            />
            <Route
              exact
              path="/join-organization"
              component={JoinOrganization}
            />
            <Route exact path="/self-pay" component={SelfPay} />
            <Route
              exact
              path="/organization-store"
              component={orgMode === OrgMode.ACADEMY ? OrganizationStore : OrganizationSelfPayStore}
            />
            <Route exact path="/cart" component={Cart} />
            <Route exact path="/checkout" component={Checkout} />
            <Route exact path="/invoice" component={Invoice} />
            <Route exact path="/receipt" component={Receipt} />
            {user.type === UserType.ORG_MANAGER && (
              <Route
                exact
                path="/add-access-codes"
                component={AddAccessCodes}
              />
            )}
            {user.type === UserType.ORG_MANAGER && (
              <Route
                exact
                path="/academy-licenses"
                component={AcademyLicenses}
              />
            )}
            {user.type === UserType.ORG_MANAGER && (
              <Route exact path="/student-upload" component={UploadStudents} />
            )}
          </Switch>
          <LoginModal
            open={ui.loginModalOpen}
            onOpen={() => dispatch(openLoginModal())}
            onClose={() => dispatch(closeLoginModal())}
          />
          <CreateTeamModal
            open={ui.createTeamModalOpen}
            onOpen={() => dispatch(openCreateTeamModal())}
            onClose={() => dispatch(closeCreateTeamModal())}
          />
          <TeamInviteTypeModal />
          <TeamInviteModal
            open={ui.teamInviteModalOpen}
            onOpen={() => dispatch(openTeamInviteModal())}
            onClose={() => dispatch(closeTeamInviteModal())}
          />
          <TransferOwnershipModal
            open={ui.transferOwnershipModalOpen}
            onOpen={() => dispatch(openTransferOwnershipModal())}
            onClose={() => dispatch(closeTransferOwnershipModal())}
          />
          <MentorInviteModal />
          <InviteToSimModal />
          <JoinTeamModal />
          <JoinCompetitionModal
            open={ui.JoinCompetitionModalOpen}
            onOpen={() => dispatch(openJoinCompetitionModal())}
            onClose={() => dispatch(closeJoinCompetitionModal())}
          />
          <ScorecardModal />
          <ScorecardComparisonModal />
          <AlertModal />
          <ConfirmationModal />
          <BugReportModal />
          <CompetitionInfoModal
            onClose={() => {
              ui.closeCompetitionInfoCallback();
              dispatch(closeCompetitionInfoModal());
            }}
          />
          <PaymentConfirmationModal />
          <AddAccessCodesModal
            open={ui.addAccessCodesModalOpen}
            onOpen={() => dispatch(openAddAccessCodesModal())}
            onClose={() => dispatch(closeAddAccessCodesModal())}
          />
          <AddManagerModal
            open={ui.addOrgManagerModalOpen}
            onOpen={() => dispatch(openAddManagerModal())}
            onClose={() => dispatch(closeAddManagerModal())}
          />
          <ResetTempPasswordModal />
        </div>
        <Navbar height={navHeight} />
      </>
    );
  };

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        {renderApp()}
      </QueryClientProvider>
    </div>
  );
};

export default App;
