import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form, Message, Button } from "semantic-ui-react";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { adminResetTempPassword } from '../actions/user';
import { closeAdminResetPasswordModal, openAdminResetPasswordModal } from "../actions/ui";

const AdminResetPasswordModal = ({}) => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.user.loading);
    const open = useSelector((state) => state.ui.adminResetPasswordModalOpen);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.RESET_PASSWORD));
    const email = useSelector((state) => state.user.userEmailToResetPassword);

    const [formState, setFormState] = React.useState({
        email: '',
    });

    useEffect(() => {
        if (open && formState.email !== email) {
            setFormState({ email });
        }
    }, [open]);

    let err;

    return (
        <Modal
            open={open}
            onOpen={() => dispatch(openAdminResetPasswordModal())}
            onClose={() => dispatch(closeAdminResetPasswordModal())}
            className="login-modal"
        >
            <Modal.Header>Reset User's Password</Modal.Header>
            <Modal.Content>
                <Form error={getFormError(errors)} className="login-form">
                    <Form.Input
                        icon="mail"
                        iconPosition="left"
                        label="Email"
                        placeholder="Email"
                        value={formState.email}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, email: value }))}
                        error={(err = getFieldError("email", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />

                    <Message
                        error
                        header="Login Error"
                        content={getFormError(errors)}
                    />

                    <Button
                        primary
                        content="Submit"
                        size="big"
                        onClick={() => dispatch(adminResetTempPassword(formState.email))}
                        loading={loading}
                        style={{ width: "100%" }}
                    />

                </Form>
            </Modal.Content>
        </Modal>
    );
};

export default AdminResetPasswordModal;
