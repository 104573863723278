import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Checkbox, Dropdown, Form, Message } from "semantic-ui-react";
import { setActiveNavTab } from "../actions/ui";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getFieldError } from "../utils/formErrors";
import DatePicker from "react-semantic-ui-datepickers";
import { createEvent } from "../actions/organization";
import { useHistory } from "react-router-dom";
import { activityNames, parentApprovalMethods, scoredActivityNames, taskNames } from "../utils/constants";
import { useOrganization } from "../queries/get-organization";

const AddAcademyEvent = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state) => state.user);
    const organizationData = useOrganization(user.organizationId);
    const org = organizationData.data;
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ACADEMY_EVENT));

    const _parentApprovalMethods = org?.nasaOrg ? ['Study'] : parentApprovalMethods;

    const [formState, setFormState] = useState({
        name: "",
        startDate: null,
        endDate: null,
        teamSize: 10,
        parentApprovalMethod: 'None',
        scoredActivities: scoredActivityNames.reduce((acc, curr) => ({ ...acc, [curr]: true }), {}),
        requiredActivities: activityNames.reduce((acc, curr) => ({
            ...acc,
            [curr]: taskNames.reduce((acc, curr) => ({
                ...acc,
                [curr]: true,
            }), {}),
        }), {}),
    })

    const [requireAll, setRequireAll] = useState(activityNames.reduce((acc, curr) => ({
        ...acc,
        [curr]: true,
    })));

    useEffect(() => {
        dispatch(setActiveNavTab(null));
    }, []);

    const handleAddEvent = () => {
        dispatch(createEvent(org?.id, formState, history));
    }

    const Activity = ({ activityName }) => {
        return (
            <div key={activityName + '_activity'} style={{ paddingBottom: '1rem' }}>
                <h4>{activityName}</h4>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {scoredActivityNames.some(a => a === activityName) && (<Checkbox
                        key={activityName + '_scored'}
                        toggle
                        label={'Scored'}
                        checked={formState.scoredActivities[activityName]}
                        onChange={(_, data) => setFormState(s => ({ ...s, scoredActivities: { ...s.scoredActivities, [activityName]: data.checked } }))}
                    />)}
                    <Checkbox
                        key={activityName + '_required'}
                        toggle
                        label={'Required'}
                        checked={requireAll[activityName]}
                        onChange={(_, data) => {
                            setRequireAll(s => ({ ...s, [activityName]: data.checked }));
                            setFormState(s => ({
                                ...s,
                                requiredActivities: {
                                    ...s.requiredActivities,
                                    [activityName]: taskNames.reduce((acc, curr) => ({
                                        ...acc,
                                        [curr]: data.checked,
                                    }), {}),
                                },
                            }));
                        }}
                    />
                    {requireAll[activityName] && (
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', gap: 1 }}>
                            {['Lesson', 'Quiz', 'Tutorial', 'Activity'].map(task => (
                                <Checkbox
                                    key={`${activityName}-${task}`}
                                    toggle
                                    label={task}
                                    checked={formState.requiredActivities[activityName][task.toLowerCase()]}
                                    onChange={(_, data) => {
                                        setFormState(s => ({
                                            ...s,
                                            requiredActivities: {
                                                ...s.requiredActivities,
                                                [activityName]: {
                                                    ...s.requiredActivities[activityName],
                                                    [task.toLowerCase()]: data.checked,
                                                },
                                            },
                                        }));
                                    }}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    let err;

    return (
        <Card className="dashboard-card">
            <Card.Content>
                <Card.Header>
                    Add Event
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <Form>
                    <Form.Input
                        label="Name"
                        placeholder="Name"
                        value={formState.name}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, name: value }))}
                        error={(err = getFieldError("name", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <Form.Group>
                        <Form.Field>
                            <label>Start Date</label>
                            <DatePicker
                                onChange={
                                    (e, data) => setFormState(s => ({ ...s, startDate: data.value }))
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>End Date</label>
                            <DatePicker
                                onChange={
                                    (e, data) => setFormState(s => ({ ...s, endDate: data.value }))
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Input
                        label="Team Size"
                        placeholder="Team Size"
                        type="number"
                        value={formState.teamSize}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, teamSize: value }))}
                        error={(err = getFieldError("teamSize", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <Form.Field>
                        <label>Parent Approval Method</label>
                        <Dropdown
                            selection
                            search
                            placeholder="Parent Approval Method"
                            value={formState.parentApprovalMethod}
                            onChange={(e, { value }) => setFormState(s => ({ ...s, parentApprovalMethod: value }))}
                            options={_parentApprovalMethods.map(method => ({ key: method, text: method, value: method }))}
                            error={(err = getFieldError("parentApprovalMethod", errors)) && {
                                content: err,
                                pointing: "below"
                            }}
                        />
                    </Form.Field>
                    <h3>Activities</h3>
                    {activityNames.map(activityName => (
                        <Activity key={activityName} activityName={activityName} />
                    ))}
                </Form>
            </Card.Content>
            {
                !!errors && (
                    <Card.Content>
                        <Message
                            error
                            header="Add Event Error"
                            content={errors[0].message || "Unknown error"}
                        />
                    </Card.Content>
                )
            }
            <Card.Content>
                <Button onClick={handleAddEvent} primary fluid style={{ maxWidth: "20rem" }}>Create</Button>
            </Card.Content>
        </Card >
    );
};

export default AddAcademyEvent;
