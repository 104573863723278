/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, Button, Icon, Grid, Message, Card } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";

import AddOrganizationForm from "./AddOrganizationForm";
import { adminCreateOrganization, getAllOrganizations } from "../actions/organization";

const AddOrganizationModal = ({
    open,
    onOpen,
    onClose,
    trigger
}) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const defaultData = {
        name: '',
        nickname: '',
        country: '',
    };
    const [formState, setFormState] = React.useState({ ...defaultData });
    const loading = useSelector((state) => state.organization.loading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ADD_ACCESS_CODES));

    const handleClose = () => {
        setFormState({ ...defaultData });
        onClose();
    };

    const renderButton = () => {
        return (
            <Button
                primary fluid
                onClick={() => dispatch(adminCreateOrganization({ ...formState }, () => {
                    dispatch(getAllOrganizations());
                    handleClose();
                }))}
                loading={loading}
            >
                <Icon name="plus"/>
                Add
            </Button>
        );
    };

    const renderForm = () => {
        return (
            <AddOrganizationForm
                formState={formState}
                setFormState={setFormState}
            />
        );
    };

    return (

        <Modal
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
            trigger={trigger}
        >
            <Modal.Header>
                <Grid>
                    <Grid.Column width={12}>
                        Add Organization 
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button
                            icon="close"
                            onClick={handleClose}
                            floated="right"
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                {renderForm()}
                {!!errors && (
                    <Message
                        error
                        header="Add Organization Error"
                        content={errors[0].message || "Unknown error"}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                {renderButton()}
            </Modal.Actions>
        </Modal>
    );
};

export default AddOrganizationModal;
