/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Message } from "semantic-ui-react";
import { useAnnouncements } from "../queries/get-announcements";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";

const renderMessageContent = m => (
    <React.Fragment>
      <Message.Header>{m.title}</Message.Header>
      <p>{m.content}</p>
    </React.Fragment>
);

const renderMessage = m => {
    switch (m.type) {
        case "REGULAR":
            return <Message key={m.id}>{renderMessageContent(m)}</Message>;
        case "IMPORTANT":
            return <Message info key={m.id}>{renderMessageContent(m)}</Message>;
        case "SUCCESS":
            return <Message success key={m.id}>{renderMessageContent(m)}</Message>;
        case "WARNING":
            return <Message color="yellow" key={m.id}>{renderMessageContent(m)}</Message>;
        case "ERROR":
            return <Message error key={m.id}>{renderMessageContent(m)}</Message>;
        default:
            return <Message key={m.id}>{renderMessageContent(m)}</Message>;
    }
};

const Bulletin = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const { loggedIn } = user;
    const announcementsData = useAnnouncements();
    const announcements = announcementsData.data;
    const loading = announcementsData.isLoading;
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ANNOUNCEMENTS));

    if (announcements && announcements.sort) {
        announcements.sort((a, b) => {
            const aTime = new Date(a.createdAt);
            const bTime = new Date(b.createdAt);
            return (bTime - aTime);
        });
    }

    return (
        <Card className={`dashboard-card ${loading ? "dashboard-card--loading": ""}`} centered>
          <Card.Content>
            <Card.Header>Bulletin Board</Card.Header>
          </Card.Content>
          <Card.Content>
            {announcements && announcements.map(renderMessage)}
          </Card.Content>
        </Card>
    );
}

export default Bulletin;
