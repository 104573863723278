import React from "react";
import { useSelector } from "react-redux";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";
import { Form, Message } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";

const AddAffiliateForm = ({
    formState,
    setFormState,
}) => {

    const errors = useSelector((state) =>
        getTaggedErrors(state.errors, ErrorTag.AFFILIATE_UPDATE));

    let err;

    return (
        <Form
            error={!!getFormError(errors)}
        >
            <Form.Input
                label="Name"
                placeholder="Name"
                value={formState.name}
                onChange={(e, { value }) => setFormState(s => ({ ...s, name: value }))}
                error={(err = getFieldError("name", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                label="Code"
                placeholder="Code"
                value={formState.code}
                onChange={(e, { value }) => setFormState(s => ({ ...s, code: value }))}
                error={(err = getFieldError("code", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Message
                error
                header="Add Affiliate"
                content={getFormError(errors)}
            />

        </Form>
    );
};

export default AddAffiliateForm;
