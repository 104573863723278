import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Item, List, Button, Popup, Icon } from "semantic-ui-react";
import { setTeamInvite, setTeamToLeave } from "../actions/teams";
import {
    openConfirmation,
    openTeamInviteTypeModal,
} from "../actions/ui";
import { UserType, TeamRole } from "../utils/enums";
import {
    leaveTeamConfirmation,
    deleteTeamConfirmation,
} from "../utils/confirmations";
import { removeGuest } from "../actions/user";

const isMentor = (role) => {
    return (
        role === "INDUSTRY_MENTOR" ||
        role === "GUEST" ||
        role === "ROAMER"
    );
};

const TeamItem = ({
    team,
    noButtons
}) => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const competitions = useSelector((state) => {
        if (user.type === UserType.ADMIN) {
            return state.competitions.activeComps;
        } else {
            return state.competitions.userComps.filter(c => c.teamIsActive)
        }
    });
    const currentRole = useSelector((state) =>
        state.competitions.currentCompetition &&
        state.competitions.currentCompetition.role);
    const removeGuestLoading = useSelector((state) => state.user.removeGuestLoading);

    const handleInviteClick = team => {
        dispatch(setTeamInvite({ team }));
        dispatch(openTeamInviteTypeModal());
    };

    const handleRemoveGuest = teamId => {
        dispatch(removeGuest(teamId));
    };

    const handleLeaveTeam = team => {
        dispatch(setTeamToLeave(team));
        dispatch(openConfirmation(leaveTeamConfirmation(team)));
    };

    const handleDeleteTeam = team => {
        dispatch(openConfirmation(deleteTeamConfirmation(team)));
    };

    const { members, guests } = team;
    const me = members.find(m => m.userId === user.id);

    const comp = competitions.find(c => c.id === team.competitionId);

    const renderLeaveButton = (disabled) => {
        if (me && me.role === TeamRole.OWNER && members.length === 1) {
            return (
                <Button
                    icon="close" content="Delete" color="red"
                    onClick={() => handleDeleteTeam(team)}
                    disabled={disabled}
                />
            );
        }
        return (
            <Button
                icon="sign out" content="Leave &nbsp;" color="red"
                onClick={() => handleLeaveTeam(team)}
                disabled={disabled}
            />
        );
        {/* {me && me.role === TeamRole.OWNER */ }
        {/*  ? ( */ }
        {/*  ) : ( */ }
        {/*      <Button */ }
        {/*          icon="sign out" content="Leave &nbsp;" color="red" */ }
        {/*          onClick={() => openConfirmation(leaveTeamConfirmation(team))} */ }
        {/*          disabled */ }
        {/*      /> */ }
        {/*  )} */ }
    };

    const renderButtons = () => {
        if (isMentor(currentRole)) {
            return <div />;
        }
        if (comp && !comp.settings.teamModificationsEnabled) {
            return (
                <Popup
                    content="Team modification has been disabled for this event"
                    trigger={
                        <Button.Group vertical floated="right">
                            <Button
                                primary icon="add user" content="Invite"
                                onClick={() => handleInviteClick(team.id)}
                                disabled
                            />
                            {renderLeaveButton(true)}
                        </Button.Group>
                    }
                />
            );
        }
        if (members && members.length === 5) {
            return (
                <Popup
                    content="Teams cannot have more than 5 members"
                    trigger={
                        <Button.Group vertical floated="right">
                            <Button
                                primary icon="add user" content="Invite"
                                onClick={() => handleInviteClick(team.id)}
                                disabled
                            />
                            {renderLeaveButton(false)}
                        </Button.Group>
                    }
                />
            );
        }

        return (
            <Button.Group vertical floated="right">
                <Button
                    primary icon="add user" content="Invite"
                    onClick={() => handleInviteClick(team.id)}
                />
                {renderLeaveButton(false)}
            </Button.Group>
        );
    };

    const renderGuest = (guest) => {
        return (
            <React.Fragment
                key={guest.userId + "username"}
            >
                <List.Item >
                    <List.Icon name="user" />
                    <List.Content>{guest.username || "PENDING ACTIVATION"}</List.Content>
                    {renderActiveIcon(guest.active, guest.currentActivity)}
                </List.Item>
                <List.Item >
                    <List.Icon name="mail" />
                    <List.Content>{guest.email}</List.Content>
                </List.Item>
                {(guest.invitedBy === user.id) && (
                    <Button
                        icon="delete"
                        color="red"
                        content="Remove Guest"
                        onClick={() => handleRemoveGuest(team.id)}
                        loading={removeGuestLoading}
                    />
                )}
            </React.Fragment>
        );
    };

    const renderActiveIcon = (active, currentActivity) => {
        return active
            ? (
                <>
                    <List.Icon name="circle" color="green" />
                    <List.Content style={{ fontSize: "0.8rem" }}>{currentActivity}</List.Content>
                </>
            ) : (
                <List.Icon name="circle outline" style={{ color: "rgba(0, 0, 0, 0.3)" }}/>
            )
    }

    return (
        <>
            <Item>
                <Item.Content>
                    <Item.Header>{team.name}</Item.Header>
                    <Item.Meta>{comp && "Event: " + comp.name}</Item.Meta>
                    <Item.Meta>{"League: " + team.league}</Item.Meta>
                    <Item.Description>
                        <h4>Members</h4>
                        <List>
                            {
                                team.members.map(m => (
                                    <List.Item
                                        key={m.username}
                                    >
                                        <List.Icon name="user" />
                                        <List.Content>{m.username}</List.Content>
                                        {renderActiveIcon(m.active, m.currentActivity)}
                                    </List.Item>
                                ))
                            }
                        </List>
                        {guests && (
                            <>
                                <h4>Guests</h4>
                                <List>
                                    {guests.map(renderGuest)}
                                </List>
                            </>
                        )}
                    </Item.Description>
                </Item.Content>
                {!noButtons && (
                    <Item.Content>
                        {renderButtons()}
                    </Item.Content>)}
            </Item>
            {/* {!noButtons && ( */}
            {/*     <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}> */}
            {/*         <Button */}
            {/*             primary fluid */}
            {/*             icon="mail" */}
            {/*             content="Send Sim Invite" */}
            {/*             onClick={() => dispatch(openInviteToSimModal(team.id))} */}
            {/*         /> */}
            {/*     </div>)} */}
        </>
    );
};

export default TeamItem;
