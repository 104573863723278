import moment from "moment";
moment.tz = require("moment-timezone");

const DEFAULT_DATE_FORMAT = "MMM Do, YYYY h:mm a zz";

const formatDate = (date, format) =>
    moment(date)
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format(format || DEFAULT_DATE_FORMAT);

export default formatDate;
