import {
  SET_USER,
  SET_ALL_USERS,
  USER_LOADING,
  ALL_USERS_LOADING,
  USER_UPDATE_EDITING,
  USER_UPDATE_LOADING,
  RESET_PASSWORD_LOADING,
  SET_SCHOOLS_AND_ORGS,
  SET_SCHOOLS_AND_ORGS_LOADING,
  SET_PARENT_INFO_LOADING,
  SET_SUBMIT_PARENT_LOADING,
  SET_PARENT_CONSENT_LOADING,
  SET_PARENT_DATA,
  SET_USER_PARENT_DATA,
  SET_PARENT_CONSENT_STATUS,
  SET_PARTIAL_USER,
  SET_COMPETITION_USERS_LOADING,
  SET_COMPETITION_USERS,
  SET_COMPETITION_MENTORS_LOADING,
  SET_COMPETITION_MENTORS,
  SET_INVITE_MENTOR_LOADING,
  SET_LEARNING_PLATFORM_CODE,
  CLEAR_LEARNING_PLATFORM_CODE,
  SET_LEARNING_PLATFORM_CODE_LOADING,
  SET_REMOVE_GUEST_LOADING,
  LOGOUT,
  TEMP_PASSWORD_CHANGED,
  SET_LICENSE_INFO_LOADING,
  SET_LICENSE_INFO,
  SET_CREATE_EVENT_LOADING,
  ADD_EVENT,
  SET_ADD_LICENSE_LOADING,
  SET_ACADEMY_STUDENTS,
  REDEEM_ACCESS_CODE_LOADING,
  SET_USER_EMAIL_TO_RESET_PASSWORD,
  SET_ACADEMY_LICENSES,
} from "../actions/types";

const initialState = {
  loading: false,
  parentInfoLoading: false,
  submitParentLoading: false,
  parentConsentLoading: false,
  loggedIn: false,
  resetPasswordLoading: false,
  redeemAccessCodeLoading: false,
  updateLoading: {
    username: false,
    email: false,
    all: false,
  },
  updateEditing: {
    username: false,
    email: false,
  },
  allUsers: {
    list: [],
    loading: false,
  },
  schoolsAndOrgs: {
    list: [],
    loading: false,
  },
  competitionUsers: {
    list: [],
    loading: false,
  },
  mentors: {
    list: [],
    loading: false,
    competitionId: null,
  },
  licenses: {
    loading: false,
    list: [],
  },
  parent: null,
  userParentData: null,
  parentConsentStatus: null,
  partialUser: null,
  inviteMentorLoading: false,
  learningPlatformCode: null,
  learningPlatformCodeLoading: false,
  removeGuestLoading: false,
  userEmailToResetPassword: null,
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER: {
      return { ...state, ...action.payload, loading: false };
    }
    case SET_ALL_USERS: {
      return { ...state, allUsers: { list: action.payload, loading: false } };
    }
    case USER_LOADING: {
      return { ...state, loading: action.payload };
    }
    case ALL_USERS_LOADING: {
      return {
        ...state,
        allUsers: { ...state.allUsers, loading: action.payload },
      };
    }
    case USER_UPDATE_LOADING: {
      return {
        ...state,
        updateLoading: {
          ...state.updateLoading,
          ...action.payload,
        },
      };
    }
    case USER_UPDATE_EDITING: {
      return {
        ...state,
        updateEditing: {
          ...state.updateLoading,
          ...action.payload,
        },
      };
    }
    case RESET_PASSWORD_LOADING: {
      return {
        ...state,
        resetPasswordLoading: action.payload,
      };
    }
    case TEMP_PASSWORD_CHANGED: {
      return {
        ...state,
        isTempPassword: false,
      };
    }
    case SET_SCHOOLS_AND_ORGS: {
      return {
        ...state,
        schoolsAndOrgs: { list: action.payload, loading: false },
      };
    }
    case SET_SCHOOLS_AND_ORGS_LOADING: {
      return {
        ...state,
        schoolsAndOrgs: { ...state.schoolsAndOrgs, loading: action.payload },
      };
    }
    case SET_PARENT_INFO_LOADING: {
      return { ...state, parentInfoLoading: action.payload };
    }
    case SET_SUBMIT_PARENT_LOADING: {
      return { ...state, submitParentLoading: action.payload };
    }
    case SET_PARENT_CONSENT_LOADING: {
      return { ...state, parentConsentLoading: action.payload };
    }
    case SET_PARENT_DATA: {
      return { ...state, parent: action.payload };
    }
    case SET_USER_PARENT_DATA: {
      return { ...state, userParentData: action.payload };
    }
    case SET_PARENT_CONSENT_STATUS: {
      return { ...state, parentConsentStatus: action.payload };
    }
    case SET_PARTIAL_USER: {
      return { ...state, partialUser: action.payload };
    }
    case SET_COMPETITION_USERS_LOADING: {
      return {
        ...state,
        competitionUsers: {
          ...state.competitionUsers,
          loading: action.payload,
        },
      };
    }
    case SET_COMPETITION_USERS: {
      return {
        ...state,
        competitionUsers: {
          ...state.competitionUsers,
          list: action.payload,
        },
      };
    }
    case SET_COMPETITION_MENTORS_LOADING: {
      return {
        ...state,
        mentors: {
          ...state.mentors,
          loading: action.payload,
        },
      };
    }
    case SET_COMPETITION_MENTORS: {
      return {
        ...state,
        mentors: {
          ...state.mentors,
          list: action.payload.list,
          competitionId: action.payload.competitionId,
        },
      };
    }
    case SET_INVITE_MENTOR_LOADING: {
      return {
        ...state,
        inviteMentorLoading: action.payload,
      };
    }
    case SET_LEARNING_PLATFORM_CODE: {
      return {
        ...state,
        learningPlatformCode: action.payload,
      };
    }
    case CLEAR_LEARNING_PLATFORM_CODE: {
      return {
        ...state,
        learningPlatformCode: initialState.learningPlatformCode,
      };
    }
    case SET_LEARNING_PLATFORM_CODE_LOADING: {
      return {
        ...state,
        learningPlatformCodeLoading: action.payload,
      };
    }
    case SET_REMOVE_GUEST_LOADING: {
      return {
        ...state,
        removeGuestLoading: action.payload,
      };
    }
    case REDEEM_ACCESS_CODE_LOADING: {
      return {
        ...state,
        redeemAccessCodeLoading: action.payload,
      };
    }
    case SET_USER_EMAIL_TO_RESET_PASSWORD: {
      return {
        ...state,
        userEmailToResetPassword: action.payload,
      };
    }
    case SET_ACADEMY_LICENSES: {
      return {
        ...state,
        licenses: {
          loading: false,
          list: action.payload,
        },
      };
    }
    case LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export default user;
