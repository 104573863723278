import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Message } from "semantic-ui-react";
import { createInvoice, getCompetitionList } from "../actions/billing";
import { getCart, getSubtotal, getTotalDiscount } from "../selectors/billing";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";

import { clearErrors } from "../actions/errors";
import { useOrganization } from "../queries/get-organization";

const Invoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const orgData = useOrganization(user.organizationId);
  const cart = useSelector(getCart);
  const cartLoading = useSelector(
    (state) => state.billing.competitions.loading
  );
  const subtotal = useSelector(getSubtotal(orgData.data));
  const totalDiscount = useSelector(getTotalDiscount);
  const total = subtotal - totalDiscount;
  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.PAYMENT)
  );

  console.log("cart", cart);

  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false)

  useEffect(() => {
    dispatch(clearErrors(ErrorTag.PAYMENT));
    dispatch(getCompetitionList());
  }, []);

  useEffect(() => {
    if (!loading && !cartLoading && cart.items.length === 0) {
      history.push("/");
    }
  }, [loading, cartLoading, cart.items]);

  const renderContent = () => {
    return (
      <Card.Content>
        By clicking Accept below, you agree to pay an invoiced amount of {total.toFixed(2)}{" "}
        USD
      </Card.Content>
    );
  };

  const handleInvoice = async () => {
    setHasSubmitted(true)
    setLoading(true);
    dispatch(
      createInvoice({ ...cart, subtotal, totalDiscount, total }, history)
    );
  };

  useEffect(() => {
    if (hasSubmitted || loading) {
      return;
    }
    if (total === 0) {
      handleInvoice();
    }
  }, [total, loading, hasSubmitted]);

  return (
    <Card className="dashboard-card">
      <Card.Content>
        <Card.Header style={{ fontSize: "2rem" }}>
          Create Invoice for {total.toFixed(2) } USD?
        </Card.Header>
      </Card.Content>
      {renderContent()}
      <Card.Content>
        <div id="card-container"></div>
        <Button
          onClick={handleInvoice}
          primary
          fluid
          disabled={loading || cartLoading}
        >
          Accept
        </Button>
        {errors && (
          <Message error header="Payment Error" content={errors[0].message} />
        )}
      </Card.Content>
    </Card>
  );
};

export default Invoice;
