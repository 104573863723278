import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCompetitionList,
  addDiscount,
  removeFromCart,
  getLicenseItems,
  clearFromCart,
  getIndividualLicenseItems,
} from "../actions/billing";
import { getTaggedErrors } from "../selectors/errors";
import { getCart, getSubtotal, getTotalDiscount } from "../selectors/billing";
import { setActiveNavTab } from "../actions/ui";
import { addError } from "../actions/errors";
import { ErrorTag, UserType } from "../utils/enums";
import { Button, Card, Divider, Grid, Statistic } from "semantic-ui-react";

import CartList from "../components/CartList";
import ApplyDiscount from "../components/ApplyDiscount";
import CartTotal from "../components/CartTotal";
import { useLocation } from "react-router-dom";
import { useOrganization } from "../queries/get-organization";

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const isIndividual = user.type !== UserType.ORG_MANAGER;
  const loading = useSelector((state) => state.billing.competitions.loading);
  const discountLoading = useSelector((state) => state.billing.discountLoading);
  const discountErrors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.DISCOUNT_CODE)
  );
  const appliedDiscounts = useSelector((state) => state.billing.cart.discounts);
  const orgData = useOrganization(user.organizationId);

  const cart = useSelector(getCart);
  const subtotal = useSelector(getSubtotal(orgData.data));
  const totalDiscount = useSelector(getTotalDiscount);
  const total = subtotal - totalDiscount;

  useEffect(() => {
    dispatch(setActiveNavTab(null));
    dispatch(getCompetitionList());
    // TODO: Org switch
    dispatch(getLicenseItems());
  }, []);

  const handleRemoveItem = (id) => {
    dispatch(clearFromCart(id));
  };

  const handleApplyDiscount = (code, clear) => {
    console.log("handling discount", cart);
    if (appliedDiscounts.some((d) => d.code === code)) {
      dispatch(
        addError({
          tag: ErrorTag.DISCOUNT_CODE,
          message: "Discount " + code + " has already been applied",
        })
      );
      return;
    }
    const numStudents = cart.items.reduce(
      (acc, curr) => acc + parseInt(curr.numStudents ?? 1) * parseInt(curr.qty),
      0
    );
    console.log("num students", numStudents);
    dispatch(addDiscount(code, history, clear, numStudents));
  };

  const handleCheckout = () => {
    history.push("/checkout");
  };

  const handleInvoice = () => {
    history.push("/invoice");
  };

  const contentStyle = {
    padding: "1rem 2.5rem",
  };

  const goToStore =
    user.type === UserType.ORG_MANAGER
      ? () => history.push("/organization-store")
      : () => history.push("/store");
  const renderContent = () => {
    if (loading) {
      return <Card.Content>Loading...</Card.Content>;
    }
    if (cart.items.length === 0) {
      return (
        <React.Fragment>
          <Card.Content>
            <p>You don't have any items in your cart.</p>
            <Button primary onClick={() => goToStore()}>
              Go To Store
            </Button>
          </Card.Content>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Card.Content style={contentStyle}>
          <CartList cart={cart} onRemoveItem={handleRemoveItem} />
        </Card.Content>
        <Card.Content style={contentStyle}>
          <ApplyDiscount
            onApply={handleApplyDiscount}
            appliedDiscounts={appliedDiscounts}
            items={cart.items}
            loading={discountLoading}
            errors={discountErrors}
          />
        </Card.Content>
        <Card.Content style={contentStyle}>
          <CartTotal
            subtotal={subtotal}
            discount={totalDiscount}
            total={total}
          />
        </Card.Content>
        <Card.Content>
          {isIndividual ? (
            <Button
              onClick={handleCheckout}
              fluid
              primary
              disabled={cart.items.length === 0}
            >
              Checkout
            </Button>
          ) : (
            total === 0 ?
              (
                <Button
                  onClick={handleCheckout}
                  fluid
                  primary
                  disabled={cart.items.length === 0}
                >
                  Accept
                </Button>
              ) : (
                <Button.Group
                  style={{
                    position: "relative",
                    left: "50%",
                    transform: "translateX(-100%)",
                  }}
                >
                  <Button
                    onClick={handleCheckout}
                    fluid
                    primary
                    disabled={cart.items.length === 0}
                  >
                    Pay Now with Card
                  </Button>
                  <Button.Or />
                  <Button
                    onClick={handleInvoice}
                    fluid
                    primary
                    disabled={cart.items.length === 0}
                  >
                    Pay Later with Invoice
                  </Button>
                </Button.Group>
              )
          )}
        </Card.Content>
      </React.Fragment>
    );
  };

  return (
    <Card className="dashboard-card">
      <Card.Content>
        <Card.Header>Cart</Card.Header>
      </Card.Content>
      {renderContent()}
    </Card>
  );
};

export default Cart;
