/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Card, Button, Item } from "semantic-ui-react";
import { openAddManagerModal } from "../actions/ui";

const keyStyle = {
    fontWeight: "bold",
    fontSize: "1.1rem",
};
const valueStyle = {
    fontWeight: "light",
    fontSize: "1.1rem",
    float: "right",
};
const KeyValue = ({ keyName, value }) => (
    <p>
        <span style={keyStyle}>{`${keyName}: `}</span>
        <span style={valueStyle}>{value}</span>
    </p>
);

const OrgManagers = () => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const { loggedIn, organizationId } = user;
    const managers = useSelector((state) => state.organization.org?.managers);
    const loading = useSelector((state) => state.organization.loading);

    const renderGeneralManager = (m) => m && (
        <Item key={m.username}>
            <Item.Content>
                <Item.Header>General Manager</Item.Header>
                <Item.Description>
                    <KeyValue keyName="Email" value={m.email} />
                    <KeyValue keyName="Username" value={m.username} />
                </Item.Description>
            </Item.Content>
        </Item>
    );

    const renderManager = (m) => {
        if (m.managerType === "INVITED") {
            return (
                <Item key={m.username}>
                    <Item.Content>
                        <Item.Header>Invited</Item.Header>
                        <Item.Description>
                            <KeyValue keyName="Email" value={m.email} />
                        </Item.Description>
                    </Item.Content>
                </Item>
            );
        }
        return (
            <Item key={m.username}>
                <Item.Content>
                    <Item.Header>Manager</Item.Header>
                    <Item.Description>
                        <KeyValue keyName="Email" value={m.email} />
                        <KeyValue keyName="Username" value={m.username} />
                    </Item.Description>
                </Item.Content>
            </Item>
        );
    };

    const renderContent = () => {
        if (loading) {
            return "Loading...";
        }
        const generalManager = managers?.find(m => m.managerType === "GENERAL");
        return (
            <Item.Group divided>{
                managers ? [
                    renderGeneralManager(generalManager),
                    ...managers.filter(m => m.managerType !== "GENERAL").map(renderManager),
                ] : []
            }</Item.Group>
        );
    };

    return (
        <Card className={`dashboard-card ${loading ? "dashboard-card--loading": ""}`} centered>
            <Card.Content>
                <Card.Header>Organization Managers</Card.Header>
            </Card.Content>
            <Card.Content>
                {renderContent()}
            </Card.Content>
            <Card.Content extra>
                <Button primary onClick={() => dispatch(openAddManagerModal())}>Add Manager</Button>
            </Card.Content>
        </Card>
    );
}

export default OrgManagers;
