/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-semantic-ui-datepickers";
import { Card, Button, Form, Checkbox, Dropdown } from "semantic-ui-react";
import { fetchAccessCodes, updateEvent } from "../actions/organization";
import { useOrganization } from "../queries/get-organization";
import { getTaggedErrors } from "../selectors/errors";
import { activityNames, parentApprovalMethods, scoredActivityNames, taskNames } from "../utils/constants";
import { ErrorTag } from "../utils/enums";
import { getFieldError } from "../utils/formErrors";

const EditAcademyEvents = ({ hideAddButton }) => {

    const dispatch = useDispatch();

    const user = useSelector((state) => state.user);
    const { loggedIn, organizationId } = user;
    const organizationData = useOrganization(organizationId);
    const org = organizationData.data;
    const loading = organizationData.isLoading;
    const currentComp = useSelector((state) => state.competitions.currentCompetition);

    const _parentApprovalMethods = org?.nasaOrg ? ['Study'] : parentApprovalMethods;

    React.useEffect(() => {
        if (loggedIn && !hideAddButton) {
            dispatch(fetchAccessCodes(organizationId));
        }
    }, [loggedIn]);

    const Event = ({ event }) => {
        const startDate = new Date(event.startDate);
        const endDate = new Date(event.endDate);
        const initialState = {
            startDate,
            endDate,
            teamSize: event.settings.teamSize,
            parentApprovalMethod: event.settings.parentApprovalMethod,
            scoredActivities: event.settings.scoreActivities,
            requiredActivities: event.settings.requireActivities,
            downloadEnabled: event.settings.downloadEnabled,
            teamModificationsEnabled: event.settings.teamModificationsEnabled,
            parentApprovalMethod: event.settings.parentApprovalMethod,
        }
        const [formState, setFormState] = useState({ ...initialState });
        const [requireAll, setRequireAll] = useState(
            Object.entries(initialState.requiredActivities)
                .reduce((acc, [k, v]) => ({
                    ...acc,
                    [k]: Object.values(v).some(_v => _v)
                }), {})
        )

        const loading = useSelector((state) => state.organization.updateEventLoading);
        const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.UPDATE_EVENT));

        const [canSave, setCanSave] = useState(false);

        useEffect(() => {
            const isDiff = (
                event.settings.downloadEnabled !== formState.downloadEnabled
                || startDate.getTime() !== formState.startDate.getTime()
                || endDate.getTime() !== formState.endDate.getTime()
                || event.settings.teamModificationsEnabled !== formState.teamModificationsEnabled
                || event.settings.teamSize !== formState.teamSize
                || event.settings.parentApprovalMethod !== formState.parentApprovalMethod
                || Object.entries(event.settings.scoreActivities).some(([key, value]) => formState.scoredActivities[key] !== value)
                || Object.entries(event.settings.requireActivities).some(([key, value]) => JSON.stringify(formState.requiredActivities[key]) !== JSON.stringify(value))
            );
            if (!loading) {
                setCanSave(isDiff);
            } else {
                setCanSave(false);
            }
        }, [formState]);

        const handleSave = () => {
            dispatch(updateEvent(org.id, event.id, formState));
            organizationData.refetch();
        }
        const handleCancel = () => {
            setFormState({ ...initialState });
        }

        let err;

        const Activity = ({ activityName }) => {
            return (
                <div key={activityName + '_activity'} style={{ paddingBottom: '1rem' }}>
                    <h4>{activityName}</h4>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        {scoredActivityNames.some(a => a === activityName) && (<Checkbox
                            key={activityName + '_scored'}
                            toggle
                            label={'Scored'}
                            checked={formState.scoredActivities[activityName]}
                            onChange={(_, data) => setFormState(s => ({ ...s, scoredActivities: { ...s.scoredActivities, [activityName]: data.checked } }))}
                        />)}
                        <Checkbox
                            key={activityName + '_required'}
                            toggle
                            label={'Required'}
                            checked={requireAll[activityName]}
                            onChange={(_, data) => {
                                setRequireAll(s => ({ ...s, [activityName]: data.checked }));
                                setFormState(s => ({
                                    ...s,
                                    requiredActivities: {
                                        ...s.requiredActivities,
                                        [activityName]: taskNames.reduce((acc, curr) => ({
                                            ...acc,
                                            [curr]: data.checked,
                                        }), {}),
                                    },
                                }));
                            }}
                        />
                        {requireAll[activityName] && (
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', gap: 1 }}>
                                {['Lesson', 'Quiz', 'Tutorial', 'Activity'].map(task => (
                                    <Checkbox
                                        key={`${activityName}-${task}`}
                                        toggle
                                        label={task}
                                        checked={formState.requiredActivities[activityName][task.toLowerCase()]}
                                        onChange={(_, data) => {
                                            setFormState(s => ({
                                                ...s,
                                                requiredActivities: {
                                                    ...s.requiredActivities,
                                                    [activityName]: {
                                                        ...s.requiredActivities[activityName],
                                                        [task.toLowerCase()]: data.checked,
                                                    },
                                                },
                                            }));
                                        }}
                                    />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <Form>
                <h2>{event.name}</h2>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Form.Group>
                        <Form.Field>
                            <label>Start Date</label>
                            <DatePicker
                                value={formState.startDate}
                                onChange={
                                    (e, data) => setFormState(s => ({ ...s, startDate: data.value }))
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>End Date</label>
                            <DatePicker
                                value={formState.endDate}
                                onChange={
                                    (e, data) => setFormState(s => ({ ...s, endDate: data.value }))
                                }
                            />
                        </Form.Field>
                    </Form.Group>
                </div>
                <div style={{ display: 'flex', gap: 5 }}>
                    <Form.Input
                        label="Team Size"
                        placeholder="Team Size"
                        type="number"
                        value={formState.teamSize}
                        onChange={({ target: { value } }) => setFormState(s => ({ ...s, teamSize: value }))}
                        error={(err = getFieldError("teamSize", errors)) && {
                            content: err,
                            pointing: "below"
                        }}
                    />
                    <Form.Field>
                        <label>Parent Approval Method</label>
                        <Dropdown
                            selection
                            search
                            placeholder="Parent Approval Method"
                            value={formState.parentApprovalMethod}
                            onChange={(e, { value }) => setFormState(s => ({ ...s, parentApprovalMethod: value }))}
                            options={_parentApprovalMethods.map(method => ({ key: method, text: method, value: method }))}
                            error={(err = getFieldError("parentApprovalMethod", errors)) && {
                                content: err,
                                pointing: "below"
                            }}
                        />
                    </Form.Field>
                </div>
                <div style={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Checkbox
                        toggle
                        label="Download Enabled"
                        checked={formState.downloadEnabled}
                        onChange={(_, data) => setFormState(s => ({ ...s, downloadEnabled: data.checked }))}
                    />
                    <Checkbox
                        toggle
                        label="Team Modifications Enabled"
                        checked={formState.teamModificationsEnabled}
                        onChange={(_, data) => setFormState(s => ({ ...s, teamModificationsEnabled: data.checked }))}
                    />
                </div>
                <h3>Activities</h3>
                {activityNames.map(activityName => (
                    <Activity key={activityName} activityName={activityName} />
                ))}
                <div style={{ paddingTop: '1rem', display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Button primary fluid loading={loading} onClick={handleSave} disabled={!canSave}>Save</Button>
                    <Button fluid loading={loading} onClick={handleCancel} disabled={!canSave}>Cancel</Button>
                </div>
            </Form>
        )
    }

    const renderContent = () => {
        if (loading || org === undefined || !org?.license) {
            return "Loading...";
        }
        if (!currentComp) {
            return (
                <React.Fragment>
                    <p>Select an event to edit (or create one if you have not already)</p>
                </React.Fragment>
            );

        }
        const event = org.license?.events?.find(e => e.id === currentComp.id);

        return (
            <React.Fragment>
                {event ? (<Event event={event} />) : <p>This event cannot be edited</p>}
            </React.Fragment>
        );
    };

    return (
        <Card className={`dashboard-card ${loading ? "dashboard-card--loading" : ""}`} centered>
            <Card.Content>
                <Card.Header>Event Settings</Card.Header>
            </Card.Content>
            <Card.Content>
                {renderContent()}
            </Card.Content>
        </Card>
    );
}

export default EditAcademyEvents;
