import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Container,
  Card,
  Item,
  Loader,
  Input,
  Dropdown,
  Button,
  Form,
  Checkbox,
  Modal,
} from "semantic-ui-react";

import OrgAccessCodes from "../components/OrgAccessCodes";
import {
  getAllOrganizations,
  adminUpdateOrganization,
  setCurrentlyEditing,
  fetchAccessCodes,
} from "../actions/organization";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { getAffiliates } from "../actions/affiliates";
import countries from "../utils/countries";
import {
  openAddAccessCodesModal,
  openAddManagerModal,
  openAddOrgModal,
  openAdminAddLicenseModal,
} from "../actions/ui";
import { adminLoginAsUser } from "../actions/user";

const Search = ({
  search,
  setSearch,
  clear,
  filterByComp,
  setFilterByComp,
}) => {
  return (
    <Grid style={{ marginTop: "1rem" }}>
      <Grid.Row>
        <Grid.Column width={10}>
          <Input
            placeholder="Search"
            fluid
            value={search}
            onChange={(e, { value }) => setSearch(value)}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Button icon="close" onClick={clear} />
        </Grid.Column>
        <Grid.Column width={4}>
          <Checkbox
            toggle
            label={"Filter by Event"}
            checked={filterByComp}
            onChange={() => setFilterByComp(!filterByComp)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const KeyValue = ({ keyName, value }) => {
  return (
    <p>
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {`${keyName}: `}
      </span>
      {value}
    </p>
  );
};

const OrgModal = ({ organization, open, setOpen, setClosed }) => {
  const dispatch = useDispatch();

  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (open && organization && organization.id && !hasFetched) {
      setHasFetched(true);
      dispatch(fetchAccessCodes(organization.id));
    }
    if (!open && hasFetched) {
      setHasFetched(false);
    }
  }, [organization, hasFetched, open]);

  return (
    <Modal onClose={setClosed} onOpen={() => setOpen(organization)} open={open}>
      <Modal.Header>Organization Access Codes</Modal.Header>
      <Modal.Content>
        {organization ? <OrgAccessCodes hideAddButton /> : "Loading..."}
      </Modal.Content>
    </Modal>
  );
};

const OrgItem = ({
  organization,
  affiliates,
  openAccessCodes,
  numStudentsPurchased,
  numStudentsRegistered,
}) => {
  const updateLoading = useSelector(
    (state) =>
      state.organization.updateLoading || state.organization.addLicenseLoading
  );
  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.UPDATE_ORGANIZATION)
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [isEditing, setIsEditing] = React.useState(false);

  const initialAffiliate = affiliates.find(
    (a) => a.name === organization.affiliate
  );
  const initialFormState = {
    name: organization.name || "",
    nickname: organization.nickname || "",
    country: organization.contactInfo.country || "",
    affiliateId: initialAffiliate ? initialAffiliate.id : "",
    nasaOrg: organization.nasaOrg,
  };
  const countryOpts = countries.map((c) => ({
    key: c.name,
    text: c.name,
    value: c.name,
  }));

  const [formState, setFormState] = React.useState({ ...initialFormState });

  const noAffiliate = { key: "None", value: "", text: "None" };
  const affiliateOpts = affiliates
    ? [
      noAffiliate,
      ...affiliates.map((a) => ({ key: a.id, text: a.name, value: a.id })),
    ]
    : [noAffiliate];

  const handleEditButtonClick = () => {
    if (isEditing) {
      dispatch(
        adminUpdateOrganization(organization.id, formState, () => {
          setIsEditing(false);
          dispatch(getAllOrganizations());
          setFormState({ ...initialFormState });
        })
      );
    } else {
      setIsEditing(true);
    }
  };

  const renderEditButton = () => (
    <Button
      onClick={() => handleEditButtonClick()}
      primary={!!isEditing}
      content={isEditing ? "Done" : "Edit"}
      loading={updateLoading}
    />
  );

  const handleAddCodes = () => {
    dispatch(setCurrentlyEditing(organization.id));
    dispatch(openAddAccessCodesModal());
  };
  const renderAddCodesButton = () => (
    <Button
      onClick={() => handleAddCodes()}
      primary
      content="Add Codes"
      loading={updateLoading}
    />
  );

  const handleInviteManager = () => {
    dispatch(setCurrentlyEditing(organization.id));
    dispatch(openAddManagerModal());
  };
  const renderInviteManagerButton = () => (
    <Button
      onClick={() => handleInviteManager()}
      primary
      content="Invite Manager"
      loading={updateLoading}
    />
  );

  const handleAddLicense = () => {
    dispatch(setCurrentlyEditing(organization.id));
    dispatch(openAdminAddLicenseModal());
  };
  const renderAddLicenseButton = () => (
    <Button
      onClick={() => handleAddLicense()}
      primary
      content="Add License"
      loading={updateLoading}
    />
  );

  if (isEditing) {
    let err;
    return (
      <Item>
        <Item.Content>
          <Item.Header>{organization.name}</Item.Header>
          <Item.Description>
            <Form error={!!getFormError(errors)}>
              <Form.Input
                label="Name"
                placeholder="Organization Name"
                value={formState.name}
                onChange={({ target: { value } }) =>
                  setFormState((s) => ({ ...s, name: value }))
                }
                error={
                  (err = getFieldError("name", errors)) && {
                    content: err,
                    pointing: "below",
                  }
                }
              />
              <Form.Input
                label="Nickname"
                placeholder="Organization Nickname"
                value={formState.nickname}
                onChange={({ target: { value } }) =>
                  setFormState((s) => ({ ...s, nickname: value }))
                }
                error={
                  (err = getFieldError("nickname", errors)) && {
                    content: err,
                    pointing: "below",
                  }
                }
              />
              <Form.Field>
                <label>Country*</label>
                <Dropdown
                  selection
                  search
                  placeholder="Select Country"
                  value={formState.country}
                  onChange={(e, { value }) =>
                    setFormState((s) => ({ ...s, country: value }))
                  }
                  options={countryOpts}
                  error={
                    (err = getFieldError("country", errors)) && {
                      content: err,
                      pointing: "below",
                    }
                  }
                />
              </Form.Field>
              <Form.Field>
                <label>Affiliate</label>
                <Dropdown
                  selection
                  search
                  placeholder="Affiliate"
                  value={formState.affiliateId}
                  onChange={(e, { value }) =>
                    setFormState((s) => ({ ...s, affiliateId: value }))
                  }
                  options={affiliateOpts}
                  error={
                    (err = getFieldError("affiliateId", errors)) && {
                      content: err,
                      pointing: "below",
                    }
                  }
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  toggle
                  label={'NASA Organization'}
                  checked={formState.nasaOrg}
                  onChange={(_, data) => setFormState(s => ({ ...s, nasaOrg: data.checked }))}
                />
              </Form.Field>
            </Form>
          </Item.Description>
          <Item.Extra>
            {renderEditButton()}
            {isEditing && (
              <Button
                content="Cancel"
                onClick={() => {
                  setIsEditing(false);
                  setFormState(initialFormState);
                }}
              />
            )}
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }

  return (
    <Item>
      <Item.Content>
        <Item.Header>{organization.name}</Item.Header>
        <Item.Description>
          <KeyValue keyName="ID" value={organization.id} />
          <KeyValue keyName="Name" value={organization.name} />
          <KeyValue keyName="Nickname" value={organization.nickname} />
          <KeyValue
            keyName="Country"
            value={organization.contactInfo.country}
          />
          <KeyValue keyName="Affiliate" value={organization.affiliate} />
          <KeyValue
            keyName="Contact Email"
            value={organization.contactInfo?.email}
          />
          {organization.numStudentsPurchased ? (
            <KeyValue
              keyName="Student Accounts Purchased"
              value={organization.numStudentsPurchased}
            />
          ) : (
            <></>
          )}
          {organization.numStudentsRegistered ? (
            <KeyValue
              keyName="Student Accounts Registered"
              value={organization.numStudentsRegistered}
            />
          ) : (
            <></>
          )}
          <KeyValue keyName="NASA Organization" value={organization.nasaOrg ? "Yes" : "No"} />
          <Item.Group divided>
            <Item.Header>Managers</Item.Header>
            {organization.managers.map((m) => (
              <Item key={m.username}>
                <Item.Description>
                  <KeyValue keyName="Username" value={m.username} />
                  <KeyValue keyName="Email" value={m.email} />
                  <Button
                    onClick={() => {
                      dispatch(adminLoginAsUser(m.id, history));
                    }}
                  >
                    Login
                  </Button>
                </Item.Description>
              </Item>
            ))}
          </Item.Group>
        </Item.Description>
        <Item.Group>
          {renderEditButton()}
          {renderAddLicenseButton()}
          <Button
            disabled
            primary
            onClick={() => openAccessCodes(organization)}
          >
            View Codes
          </Button>
          {renderInviteManagerButton()}
        </Item.Group>
      </Item.Content>
    </Item>
  );
};

const OrgsList = ({ organizations, affiliates, openAccessCodes, loading }) => {
  if (loading) {
    return "Loading...";
  }
  return (
    <Item.Group divided>
      {organizations.map((o) => (
        <OrgItem
          key={o.id}
          organization={o}
          affiliates={affiliates}
          openAccessCodes={openAccessCodes}
          numStudentsPurchased={o.numStudentsPurchased}
          numStudentsRegistered={o.numStudentsPurchased}
        />
      ))}
    </Item.Group>
  );
};

const AdminOrganizationManagement = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.organization.loading);
  const organizations = useSelector((state) => state.organization.list);
  const affiliates = useSelector((state) => state.affiliates.list);
  const currentComp = useSelector(
    (state) => state.competitions.currentCompetition
  );

  const [search, setSearch] = React.useState("");
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [accessCodesOpen, setAccessCodesOpen] = useState(false);
  const [filterByComp, setFilterByComp] = useState(false);
  const [filteredOrgs, setFilteredOrgs] = useState(organizations);
  const [lastFilteredCompId, setLastFilteredCompId] = useState(undefined);
  const [isFilteredBySearch, setIsFilteredBySearch] = useState(false);
  const [lastSearch, setLastSearch] = useState("");
  const [hasLoaded, setHasLoaded] = useState(false);

  const handleOpenAccessCodes = (organization) => {
    setSelectedOrg(organization);
    setAccessCodesOpen(true);
  };
  const handleCloseAccessCodes = () => {
    setSelectedOrg(null);
    setAccessCodesOpen(false);
  };

  React.useEffect(() => {
    dispatch(getAllOrganizations());
    dispatch(getAffiliates());
  }, []);

  const filterOrgs = (orgs) => {
    return orgs
      .filter((o) => {
        const hasLicense = o.competitionLicenses.some((l) =>
          l.events.some((e) => e.id === currentComp.id)
        );
        console.log("hasLicense", hasLicense);
        return hasLicense;
      })
      .map((o) => {
        const license = o.competitionLicenses.find((l) =>
          l.events.some((e) => e.id === currentComp.id)
        );
        o.numStudentsPurchased = license.students.length;
        o.numStudentsRegistered = license.students.filter(
          (s) => s.redeemed
        ).length;
        return o;
      });
  };

  React.useEffect(() => {
    if (!loading && !hasLoaded && organizations.length > 0) {
      const orgs = filterByComp ? filterOrgs(organizations) : organizations;
      setFilteredOrgs(orgs);
      setHasLoaded(true);
    } else if (organizations.length === 0) {
      setFilteredOrgs([]);
    }
  }, [organizations, loading, hasLoaded, filterByComp]);

  React.useEffect(() => {
    const needsFiltering = lastFilteredCompId !== currentComp?.id;
    console.log("needsFiltering", needsFiltering);
    if (needsFiltering) {
      const orgs = filterByComp ? filterOrgs(organizations) : organizations;
      console.log("original list length", organizations.length);
      console.log("filtered list", orgs);
      if (filterByComp) {
        setLastFilteredCompId(currentComp?.id);
      }
      setFilteredOrgs(orgs);
    }
  }, [filterByComp, organizations, currentComp, lastFilteredCompId]);

  React.useEffect(() => {
    if (!filterByComp) {
      setLastFilteredCompId(undefined);
    }
  }, [filterByComp]);

  React.useEffect(() => {
    console.log("list inside search handler", filteredOrgs);
    if (search !== "" && search !== lastSearch) {
      const regex = new RegExp(search, "i");
      const filtered = organizations.filter(
        (o) =>
          regex.test(o.name) ||
          o.managers.some((m) => regex.test(m.email) || regex.test(m.username))
      );
      setFilteredOrgs(filterByComp ? filterOrgs(filtered) : filtered);
      setIsFilteredBySearch(true);
      setLastSearch(search);
    } else if (isFilteredBySearch && search === "") {
      setFilteredOrgs(filterByComp ? filterOrgs(organizations) : organizations);
      setIsFilteredBySearch(false);
      setLastSearch("");
    }
  }, [search, lastSearch, isFilteredBySearch, organizations, filteredOrgs]);

  const clearSearch = () => {
    setSearch("");
  };

  const renderOrganizationsList = () => {
    if (loading) {
      return "Loading...";
    }
    return (
      <OrgsList
        organizations={filteredOrgs}
        affiliates={affiliates}
        loading={loading}
        openAccessCodes={handleOpenAccessCodes}
      />
    );
  };

  return (
    <Container>
      <Card className="dashboard-card" centered>
        <Card.Content>
          <Grid style={{ marginTop: "1rem" }}>
            <Grid.Row>
              <Grid.Column width={8}>
                <h3>Organization Management</h3>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button
                  icon="plus"
                  content="Create"
                  primary
                  onClick={() => dispatch(openAddOrgModal())}
                />
              </Grid.Column>
              <Grid.Column width={4}>
                <Button
                  icon="refresh"
                  onClick={() => dispatch(getAllOrganizations())}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
        <Card.Content>
          <h4>Search</h4>
          <Search
            search={search}
            setSearch={setSearch}
            clear={clearSearch}
            filterByComp={filterByComp}
            setFilterByComp={setFilterByComp}
          />
        </Card.Content>
        <Card.Content>
          <span>Org Count:&nbsp;</span>
          {`${filteredOrgs.length}/${organizations.length}`}
        </Card.Content>
        {filterByComp && (
          <>
            <Card.Content>
              <span>Accounts Purchased:&nbsp;</span>
              {`${filteredOrgs.reduce((acc, org) => {
                const license = org.competitionLicenses.find((l) =>
                  l.events.some((e) => e.id === currentComp.id)
                );
                const studentCount = license?.students?.length ?? 0;
                return acc + studentCount;
              }, 0)}`}
            </Card.Content>
            <Card.Content>
              <span>Accounts Registered:&nbsp;</span>
              {`${filteredOrgs.reduce((acc, org) => {
                const license = org.competitionLicenses.find((l) =>
                  l.events.some((e) => e.id === currentComp.id)
                );
                const studentCount = license?.students?.filter(
                  (s) => s.redeemed
                ).length;
                return acc + studentCount;
              }, 0)}`}
            </Card.Content>
          </>
        )}
        <Card.Content>{renderOrganizationsList()}</Card.Content>
      </Card>
      <OrgModal
        organization={selectedOrg}
        open={accessCodesOpen}
        setOpen={handleOpenAccessCodes}
        setClosed={handleCloseAccessCodes}
      />
    </Container>
  );
};

export default AdminOrganizationManagement;
