import authServer from '../api/auth';
import { useQuery } from 'react-query';

export const useHealthCheck = () => useQuery({
  queryFn: healthCheck,
  refetchOnMount: false,
});

async function healthCheck() {
  try {
    const response = await authServer.get("/health-check");
    return response.data;
  } catch (err) {
    throw err;
  }
}
