import { handleRemoveNotificationClick } from "../actions/notifications";
import { leaveTeam, deleteTeam } from "../actions/teams";
import { openConfirmation } from "../actions/ui";
import { addAccessCodes, adminAddLicense } from "../actions/organization";
import store from "../store";

export const declineInviteConfirmation = (invite) => ({
  header: "Decline Invite?",
  message:
    `Are you sure you want to decline your invitation ` +
    `to join team '${invite.teamName}'?`,
  onConfirm: () => store.dispatch(handleRemoveNotificationClick(invite)),
});

export const leaveTeamConfirmation = (team) => ({
  header: "Leave Team?",
  message: `Are you sure you want to leave the team '${team.name}'`,
  onConfirm: () => store.dispatch(leaveTeam(team.id)),
});

export const deleteTeamConfirmation = (team) => ({
  header: "Delete Team?",
  message:
    `Are you sure you want to delete the team '${team.name}'` +
    ` and remove all your teammates? This action cannnot be undone.`,
  onConfirm: () => store.dispatch(deleteTeam(team.id)),
});

export const organizationRegistrationThankYou = () => ({
  header: "Thank You",
  message:
    "Thank you for registering your organization. " +
    "Please check your email for next steps to sign up individual students.",
  onConfirm: () => {},
  okayOnly: true,
});

export const organizationRegistrationConfirmation = (
  orgId,
  compId,
  history
) => {
  let nextURL = "/competition-bulk-pay?organization_id=" + orgId;
  if (compId) {
    nextURL += "&competition_id=" + compId;
  }
  return {
    header: "Success",
    message:
      "You've successfully created your organization. " +
      "You will receive a welcome email with a student registration " +
      "form along with a link that you can use to pay for " +
      "student registrations. If you know approximately how many " +
      "students you will be registering, you can pay now and make " +
      "changes later. Proceed to payment page?",
    onConfirm: () => history.push(nextURL),
    onCancel: () =>
      store.dispatch(openConfirmation(organizationRegistrationThankYou())),
    newModalOnCancel: true,
  };
};

export const inviteToSimConfirmation = () => {
  return {
    header: "Invite Successful",
    message:
      "You've successfully invited your team member(s) to your sim. " +
      "They will receive an email instructing them to wait for you in the lobby. " +
      "Go ahead and log into the SpaceCRAFT application and wait for them to join you " +
      "in the lobby (the main menu with all the activities). " +
      "Do NOT start the sim until you've met your teammate(s) in the " +
      "lobby. If you start the sim without them, they won't be able to join you.",
    okayOnly: true,
    onConfirm: () => {},
    onCancel: () => {},
  };
};

export const accessCodeSuccess = () => {
  return {
    header: "Access Code Accepted",
    message:
      "Your access code has been accepted and you have successfully joined " +
      "the competition. You can now create a team, or ask to be invited to an existing " +
      "team using the global chat.",
    okayOnly: true,
    onConfirm: () => {},
    onCancel: () => {},
  };
};

export const addAccessCodesConfirmation = (
  orgId,
  formData,
  price,
  currency,
  cb
) => {
  return {
    header: "Add Access Codes?",
    message:
      `Add ${formData.numberOfCodes} access codes to your account?` +
      ` You will be invoiced for ${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(price)} ${currency}.`,
    okayOnly: false,
    onConfirm: () => store.dispatch(addAccessCodes(orgId, formData, cb)),
    onCancel: () => {},
  };
};

export const addAccessCodesSuccess = (numberOfCodes) => {
  return {
    header: "Success",
    message:
      `You have successfully added ${numberOfCodes} to your account. ` +
      `You will receive an email with your invoice and instructions on how ` +
      `to pay shortly.`,
    okayOnly: true,
    onConfirm: () => {},
    onCancel: () => {},
  };
};

export const addOrgLicenseConfirmation = (orgId, orgName, formData, cb) => {
  return {
    header: "Add License?",
    message: `Add License with ${formData.accounts} accounts and ${formData.seats} seats to ${orgName}?`,
    okayOnly: false,
    onConfirm: () => store.dispatch(adminAddLicense(orgId, formData, cb)),
    onCancel: () => {},
  };
};

export const addOrgLicenseSuccess = (accounts, seats) => {
  return {
    header: "Success",
    message: `You have successfully added ${accounts} accounts and ${seats} seats. `,
    okayOnly: true,
    onConfirm: () => {},
    onCancel: () => {},
  };
};

export const inviteMentorSuccess = (email) => {
  return {
    header: "Success",
    message:
      `You have successfully invited ${email} to be a mentor. ` +
      `They will receive an email with instructions on how ` +
      `to activate their account shortly.`,
    okayOnly: true,
    onConfirm: () => {},
    onCancel: () => {},
  };
};
