import {
  SET_ORGANIZATION_LOADING,
  SET_CREATE_ORGANIZATION_LOADING,
  SET_ORGANIZATION_DATA,
  SET_ORGANIZATION_LIST,
  SET_ADD_ACCESS_CODES_LOADING,
  SET_ACCESS_CODES_LOADING,
  SET_ACCESS_CODES,
  SET_ADD_ORG_MANAGER_LOADING,
  SET_ORGANIZATION_UPDATE_LOADING,
  SET_CURRENTLY_EDITING_ORGANIZATION,
  SET_ACADEMY_STUDENTS,
  SET_ADD_LICENSE_LOADING,
  SET_LICENSE_INFO_LOADING,
  SET_LICENSE_INFO,
  SET_CREATE_EVENT_LOADING,
  ADD_EVENT,
  STUDENT_UPLOAD_LOADING,
  UPDATE_EVENT_LOADING,
  UPDATE_EVENT,
  SET_ORGANIZATION_MODE,
  SET_CURRENT_COMPETITION,
} from "./types";
import { ALERT_TIME } from "../utils/constants";
import { addError, clearErrors } from "./errors";
import { ErrorTag, AlertType } from "../utils/enums";
import authServer from "../api/auth";
import { getToken, login } from "./user";
import {
  closeConfirmation,
  openConfirmation,
  openAlert,
  closeAlert,
} from "./ui";
import {
  addAccessCodesSuccess,
  addOrgLicenseSuccess,
} from "../utils/confirmations";
import { setCurrentCompetition, _getCompetitionById } from "./competitions";

const _registerOrg = async (formData) => {
  try {
    if (formData.password !== formData.confirmPassword) {
      throw {
        message: "Passwords do not match",
      };
    }
    const org = await authServer.post("/organizations", {
      name: formData.name,
      nickname: formData.nickname,
      generalManager: {
        username: formData.managerUsername,
        firstName: formData.managerFirstName,
        lastName: formData.managerLastName,
        schoolOrOrganization: formData.schoolOrOrganization,
        email: formData.managerEmail,
        password: formData.password,
      },
      contactInfo: {
        address: formData.address,
        city: formData.city,
        country: formData.country,
        zipCode: formData.zipCode,
        phoneNumber: formData.phoneNumber,
      },
    });
    return org.data;
  } catch (err) {
    throw err;
  }
};

const _addAccessCodes = async (organizationId, formData, isAdmin, token) => {
  try {
    const data = { organizationId, ...formData };
    const url = isAdmin
      ? "/organizations/admin-access-codes"
      : "/organizations/add-access-codes";
    const codes = await authServer.post(url, data, {
      headers: {
        Authorization: token,
      },
    });
    return codes.data;
  } catch (err) {
    throw err;
  }
};

const _getAccessCodes = async (organizationId, token) => {
  try {
    const codes = await authServer.get(
      `/organizations/access-codes?organization_id=${encodeURIComponent(
        organizationId
      )}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return codes.data;
  } catch (err) {
    throw err;
  }
};

const _inviteOrgManager = async (organizationId, formData, isAdmin, token) => {
  try {
    const url = isAdmin
      ? "/organizations/admin-invite-manager"
      : "/organizations/invite-manager";
    await authServer.post(
      url,
      { organizationId, ...formData },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  } catch (err) {
    throw err;
  }
};

const _adminCreateOrganization = async (formData, token) => {
  try {
    const data = {
      ...formData,
      contactInfo: {
        country: formData.country,
      },
    };
    await authServer.post("/organizations/admin-create", data, {
      headers: {
        Authorization: token,
      },
    });
  } catch (err) {
    throw err;
  }
};

const _adminAddLicense = async (organizationId, formData, token) => {
  try {
    const data = {
      ...formData,
      organizationId,
    };
    await authServer.post("/organizations/admin-organization-license", data, {
      headers: {
        Authorization: token,
      },
    });
  } catch (err) {
    throw err;
  }
};

const _getOrgData = async (orgId, token) => {
  try {
    const orgData = await authServer.get("/organizations?id=" + orgId, {
      headers: { Authorization: token },
    });
    return orgData.data;
  } catch (err) {
    throw err;
  }
};

const _getAllOrganizations = async (token) => {
  try {
    const orgData = await authServer.get("/organizations/all", {
      headers: { Authorization: token },
    });
    return orgData.data;
  } catch (err) {
    throw err;
  }
};

const _adminUpdateOrganization = async (organization, token) => {
  try {
    console.log("ADMIN UPDATE", organization);
    console.log("TOKEN", token);
    const promise = authServer.patch(
      "organizations/admin-update",
      {
        id: organization.id,
        name: organization.name,
        nickname: organization.nickname,
        contactInfo: {
          country: organization.country,
        },
        nasaOrg: organization.nasaOrg,
      },
      { headers: { Authorization: token } },
    );
    console.log("PROMISE", promise);
    await promise;
    console.log("DONE");
    if (organization.affiliateId && organization.affiliateId !== "") {
      await authServer.post(
        "organizations/affiliate",
        {
          organizationId: organization.id,
          affiliateId: organization.affiliateId,
        },
        { headers: { Authorization: token } }
      );
    } else {
      await authServer.delete(
        `organizations/affiliate?organization_id=${encodeURIComponent(
          organization.id
        )}`,
        { headers: { Authorization: token } }
      );
    }
  } catch (err) {
    throw err;
  }
};

// TODO(adam): This should go through payment service
const _addLicense = async (organizationId, numSeats, numStudents, token) => {
  try {
    const result = await authServer.post(
      `/organizations/academy-license`,
      { organizationId, numSeats, numStudents },
      { headers: { Authorization: token } }
    );
    return result.data;
  } catch (err) {
    throw err;
  }
};

const _createAcademyEvent = async (organizationId, eventData, token) => {
  try {
    const resp = await authServer.post(
      `/organizations/event`,
      {
        id: organizationId,
        eventType: "INVITE_ONLY",
        name: eventData.name,
        startDate: eventData.startDate,
        endDate: eventData.endDate,
        teamSize: eventData.teamSize,
        parentApprovalMethod: eventData.parentApprovalMethod,
        scoredActivities: eventData.scoredActivities,
        requiredActivities: eventData.requiredActivities,
      },
      { headers: { Authorization: token } }
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const _updateAcademyEvent = async (
  organizationId,
  eventId,
  eventData,
  token
) => {
  try {
    let startDate = eventData.startDate;
    if (startDate.toISOString) {
      startDate = startDate.toISOString();
    }
    let endDate = eventData.endDate;
    if (endDate.toISOString) {
      endDate = endDate.toISOString();
    }
    const resp = await authServer.patch(
      `/organizations/event`,
      {
        id: organizationId,
        eventId: eventId,
        downloadEnabled: eventData.downloadEnabled,
        teamModificationsEnabled: eventData.teamModificationsEnabled,
        teamSize: eventData.teamSize,
        parentApprovalMethod: eventData.parentApprovalMethod,
        scoredActivities: eventData.scoredActivities,
        requiredActivities: eventData.requiredActivities,
        startDate: startDate,
        endDate: endDate,
      },
      { headers: { Authorization: token } }
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const _uploadStudents = async (orgId, eventId, file, token) => {
  try {
    const baseUrl = `/upload/students?organization_id=${encodeURIComponent(
      orgId
    )}`;
    const url = eventId
      ? `${baseUrl}&event_id=${encodeURIComponent(eventId)}`
      : baseUrl;
    const formData = new FormData();
    formData.append("file", file);
    const resp = await authServer.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: token,
      },
    });
    return { success: true, data: resp.data };
  } catch (err) {
    if (err.response && err.response.data.reason === "UPLOAD_ERROR") {
      return { success: false, data: err.response.data.data };
    }
    throw err;
  }
};

export const getOrgData = (orgId) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.GET_ORG));
    dispatch({ type: SET_ORGANIZATION_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    const orgData = await _getOrgData(orgId, token);

    dispatch({
      type: SET_ORGANIZATION_DATA,
      payload: orgData,
    });
  } catch (err) {
    err.tag = ErrorTag.GET_ORG;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ORGANIZATION_LOADING, payload: false });
  }
};

export const registerOrganization =
  (orgData, competitionId, history) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.CREATE_ORG));
      dispatch({ type: SET_CREATE_ORGANIZATION_LOADING, payload: true });

      await _registerOrg(orgData);
      dispatch(login(orgData.managerUsername, orgData.password));

      history.push("/");
    } catch (err) {
      err.tag = ErrorTag.CREATE_ORG;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_CREATE_ORGANIZATION_LOADING, payload: false });
    }
  };

export const setOrganizationMode = (mode) => ({
  type: SET_ORGANIZATION_MODE,
  payload: mode,
});

export const setAccessCodes = (accessCodes) => ({
  type: SET_ACCESS_CODES,
  payload: accessCodes,
});

export const addAccessCodes = (orgId, formData, cb) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.ADD_ACCESS_CODES));
    dispatch({ type: SET_ADD_ACCESS_CODES_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    const codes = await _addAccessCodes(orgId, formData, false, token);
    dispatch(closeConfirmation());
    dispatch(openConfirmation(addAccessCodesSuccess(formData.numberOfCodes)));
    dispatch(setAccessCodes(codes));
    cb();
  } catch (err) {
    err.tag = ErrorTag.ADD_ACCESS_CODES;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ADD_ACCESS_CODES_LOADING, payload: false });
  }
};

export const adminAddLicense = (orgId, formData, cb) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.ADD_LICENSE));
    dispatch({ type: SET_ADD_LICENSE_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    await _adminAddLicense(orgId, formData, token);
    dispatch(closeConfirmation());
    dispatch(
      openConfirmation(addOrgLicenseSuccess(formData.accounts, formData.seats))
    );
    cb();
  } catch (err) {
    err.tag = ErrorTag.ADD_LICENSE;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ADD_LICENSE_LOADING, payload: false });
  }
};

export const adminAddOrgCodes = (orgId, formData, cb) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.ADD_ACCESS_CODES));
    dispatch({ type: SET_ADD_ACCESS_CODES_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    await _addAccessCodes(orgId, formData, true, token);
    dispatch(openConfirmation(addAccessCodesSuccess(formData.numberOfCodes)));
    cb();
  } catch (err) {
    err.tag = ErrorTag.ADD_ACCESS_CODES;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ADD_ACCESS_CODES_LOADING, payload: false });
  }
};

export const inviteOrgManager =
  (orgId, formData, isAdmin, cb) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.ADD_ORG_MANAGER));
      dispatch({ type: SET_ADD_ORG_MANAGER_LOADING, payload: true });
      const token = getToken();
      if (!token) return;

      await _inviteOrgManager(orgId, formData, isAdmin, token);
      dispatch(getOrgData(orgId));
      cb();
    } catch (err) {
      err.tag = ErrorTag.ADD_ORG_MANAGER;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_ADD_ORG_MANAGER_LOADING, payload: false });
    }
  };

export const fetchAccessCodes = (orgId) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.GET_ACCESS_CODES));
    dispatch({ type: SET_ACCESS_CODES_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    const codes = await _getAccessCodes(orgId, token);
    dispatch(setAccessCodes(codes));
  } catch (err) {
    err.tag = ErrorTag.GET_ACCESS_CODES;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ACCESS_CODES_LOADING, payload: false });
  }
};

export const getAllOrganizations = () => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.GET_ORG));
    dispatch({ type: SET_ORGANIZATION_LOADING, payload: true });
    const token = getToken();
    if (!token) return;

    const orgs = await _getAllOrganizations(token);
    dispatch(setOrganizationList(orgs));
  } catch (err) {
    err.tag = ErrorTag.GET_ORG;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ORGANIZATION_LOADING, payload: false });
  }
};

export const setOrganizationList = (orgs) => ({
  type: SET_ORGANIZATION_LIST,
  payload: orgs,
});

export const adminUpdateOrganization =
  (orgId, data, cb) => async (dispatch, getState) => {
    try {
      dispatch({ type: SET_ORGANIZATION_UPDATE_LOADING, payload: true });
      let token;
      if (!(token = getToken())) return;

      const org = {
        ...data,
        id: orgId,
      };
      await _adminUpdateOrganization({ ...org }, token);
      dispatch(clearErrors(ErrorTag.UPDATE_ORGANIZATION));
      cb();
    } catch (err) {
      dispatch(clearErrors(ErrorTag.UPDATE_ORGANIZATION));
      err.tag = ErrorTag.UPDATE_ORGANIZATION;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_ORGANIZATION_UPDATE_LOADING, payload: false });
    }
  };

export const adminCreateOrganization = (orgData, cb) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.CREATE_ORG));
    dispatch({ type: SET_CREATE_ORGANIZATION_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;

    await _adminCreateOrganization(orgData, token);
    cb();
  } catch (err) {
    err.tag = ErrorTag.CREATE_ORG;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_CREATE_ORGANIZATION_LOADING, payload: false });
  }
};

export const setCurrentlyEditing = (id) => ({
  type: SET_CURRENTLY_EDITING_ORGANIZATION,
  payload: id,
});
export const clearCurrentlyEditing = () => ({
  type: SET_CURRENTLY_EDITING_ORGANIZATION,
  payload: null,
});

export const updateLicenseInfo = (licenseInfo) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.LICENSE));
    dispatch({ type: SET_LICENSE_INFO_LOADING, payload: true });

    const token = getToken();
    if (!token) return;

    // TODO(adam): API
    dispatch({ type: SET_LICENSE_INFO, payload: licenseInfo });
  } catch (err) {
    err.tag = ErrorTag.LICENSE;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_LICENSE_INFO_LOADING, payload: false });
  }
};

export const addLicense = () => async (dispatch, getState) => {
  try {
    dispatch(clearErrors(ErrorTag.LICENSE));
    dispatch({ type: SET_ADD_LICENSE_LOADING, payload: true });

    const token = getToken();
    if (!token) return;
    const licenseInfo = getState().organization.licenseInfo;
    const orgId = getState().user.organizationId;

    // TODO(adam): Payment processing
    const result = await _addLicense(
      orgId,
      licenseInfo.seats,
      licenseInfo.accounts,
      token
    );
    dispatch({ type: SET_ACADEMY_STUDENTS, payload: result.students });
  } catch (err) {
    err.tag = ErrorTag.LICENSE;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_ADD_LICENSE_LOADING, payload: false });
  }
};

export const createEvent = (orgId, eventInfo, history) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.ACADEMY_EVENT));
    dispatch({ type: SET_CREATE_EVENT_LOADING, payload: true });

    const token = getToken();
    if (!token) return;

    const event = await _createAcademyEvent(orgId, eventInfo, token);
    const competition = await _getCompetitionById(token, event.id);
    dispatch({ type: ADD_EVENT, payload: event });
    dispatch({ type: SET_CURRENT_COMPETITION, payload: competition });

    dispatch(
      openAlert({
        type: AlertType.SUCCESS,
        header: "Event Created!",
        message: "You successfully created a new event.",
      })
    );
    setTimeout(() => dispatch(closeAlert()), ALERT_TIME);

    history.push("/");
  } catch (err) {
    err.tag = ErrorTag.ACADEMY_EVENT;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_CREATE_EVENT_LOADING, payload: false });
  }
};

export const updateEvent =
  (orgId, eventId, eventInfo) => async (dispatch, getState) => {
    try {
      dispatch(clearErrors(ErrorTag.UPDATE_EVENT));
      dispatch({ type: UPDATE_EVENT_LOADING, payload: true });

      const token = getToken();
      if (!token) return;

      const event = await _updateAcademyEvent(orgId, eventId, eventInfo, token);
      dispatch({ type: UPDATE_EVENT, payload: event });
      dispatch(
        setCurrentCompetition(getState().competitions.currentCompetition.id)
      );

      dispatch(
        openAlert({
          type: AlertType.SUCCESS,
          header: "Event Updated!",
          message: `You successfully updated ${event.name}.`,
        })
      );
      setTimeout(() => dispatch(closeAlert()), ALERT_TIME);
    } catch (err) {
      console.trace(err);
      err.tag = ErrorTag.UPDATE_EVENT;
      dispatch(addError(err));
      dispatch(
        openAlert({
          type: AlertType.ERROR,
          header: "Error Updating Event",
          message: `Error updating event: ${err.toString()}`,
        })
      );
      setTimeout(() => dispatch(closeAlert()), ALERT_TIME);
    } finally {
      dispatch({ type: UPDATE_EVENT_LOADING, payload: false });
    }
  };

export const uploadStudents =
  (orgId, eventId, file, onError, history) => async (dispatch) => {
    dispatch(clearErrors(ErrorTag.STUDENT_UPLOAD));
    dispatch({ type: STUDENT_UPLOAD_LOADING, payload: true });

    const token = getToken();
    if (!token) return;

    try {
      const result = await _uploadStudents(orgId, eventId, file, token);
      if (!result.success) {
        onError(result.data);
        return;
      }
      dispatch(fetchAccessCodes(orgId));
      dispatch(
        openAlert({
          type: AlertType.SUCCESS,
          header: "Success!",
          message: `You've uploaded ${result.data.numStudents} students`,
        })
      );
      setTimeout(() => dispatch(closeAlert()), ALERT_TIME);

      history.push("/");
    } catch (err) {
      err.tag = ErrorTag.STUDENT_UPLOAD;
      dispatch(addError(err));
    } finally {
      dispatch({ type: STUDENT_UPLOAD_LOADING, payload: false });
    }
  };
