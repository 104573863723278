import store from '../store';
import authServer from '../api/auth';
import { useQuery } from "react-query";
import { getToken } from "./token";
import { SET_ORGANIZATION_DATA } from '../actions/types';

export const useOrganization = (id) => useQuery({
  queryKey: ['organization', id],
  queryFn: () => getOrganization(id),
  refetchOnMount: false,
});

async function getOrganization(orgId) {
  try {
    if (!orgId) {
      return {};
    }
    const token = getToken();
    if (!token) {
      return {};
    }
    const orgData = await authServer.get("/organizations?id=" + orgId, {
      headers: { Authorization: token },
    });
    store.dispatch({
      type: SET_ORGANIZATION_DATA,
      paylaod: orgData.data,
    });
    console.log('org', orgData.data);
    return orgData.data;
  } catch (err) {
    throw err;
  }
}
