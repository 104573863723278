const KeyValue = ({ keyName, value }) => {
    return (
        <p>
            <span
                style={{
                    fontWeight: "bold"
                }}
            >
                {`${keyName}: `}
            </span>
            {value}
        </p>
    );
};

export default KeyValue;
