import React, { useEffect } from "react";
import { Item } from "semantic-ui-react";
import UserItem from "./UserItem";
import MentorItem from "./MentorItem";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { getAllTeams } from "../actions/teams";
import { getAllOrganizations } from "../actions/organization";
import { ParentConsentStatus } from "../utils/enums";

const filterUserByType = (u, userType, selectedCompetition) => {
    const userComp = u.competitions
        .find(uc => uc.competitionId === selectedCompetition.id);
    if (userType === "competitor") {
        return u.type === "USER" && userComp && userComp.competitionRole === "COMPETITOR";
    } else if (userType === "mentor") {
        return (
            userComp && userComp.competitionRole === "TEACHER" ||
            userComp && userComp.competitionRole === "INDUSTRY_MENTOR" ||
            userComp && userComp.competitionRole === "ROAMER" ||
            userComp && userComp.competitionRole === "GUEST"
        );
    } else if (userType === "manager") {
        return u.type === "ORG_MANAGER";
    } else if (userType === "allRegistered") {
        return ["COMPETITOR", "TEACHER", "INDUSTRY_MENTOR", "ROAMER", "GUEST"]
            .some(role => userComp && userComp.competitionRole === role);
    } else {
        return true;
    }
};

const UserList = ({
    users,
    search,
    searchByOptions,
    searchField,
    filters,
    competitionList,
    selectedCompetition,
    userType
}) => {
    const dispatch = useDispatch();

    const initialState = users.filter(u => filterUserByType(u, userType, selectedCompetition));

    const [userList, setUserList] = React.useState(initialState);
    const searchFields = searchByOptions.map(opt => opt.value);

    const teams = useSelector((state) => state.teams);
    const orgs = useSelector((state) => state.organization.list);
    const [hasFetched, setHasFetched] = React.useState(false);

    useEffect(() => {
        if (!hasFetched && selectedCompetition?.id) {
            setHasFetched(true);
            dispatch(getAllTeams(selectedCompetition.id));
            dispatch(getAllOrganizations());
        }
    }, [hasFetched, selectedCompetition])

    const testSearch = (user, field, regex) => {
        const userComp = user.competitions
            .find(uc => uc.competitionId === selectedCompetition.id);
        let compRole = userComp && userComp.competitionRole;
        if (user.type === "ORG_MANAGER") {
            compRole = "TEACHER";
        }
        if (field === "competitionRole") {
            return compRole && regex.test(compRole);
        }
        if (field === "parentApprovalStatus") {
            return user.parent && regex.test(user.parent.approvalStatus);
        }
        return regex.test(user[field]);
    };
    const filterUserList = (list, search, searchField) => {
        if (searchField === "" && search !== "") {
            const regex = new RegExp(search, "i");
            return list.filter(u =>
                searchFields
                    .map(field => testSearch(u, field, regex))
                    .some(match => match)
            );
        } else if (search !== "") {
            const regex = new RegExp(search, "i");
            return list.filter(u => testSearch(u, searchField, regex));
        } else {
            return list;
        }
    };
    React.useEffect(() => {
        const filteredList = [{ search, searchField }, ...filters].reduce((list, filter) => {
            return filterUserList(list, filter.search, filter.searchField)
        }, initialState);
        setUserList(filteredList);
    }, [search, searchField, filters]);

    const renderPinpointLink = () => {
        const rows = userList.map(user => {
            const userComp = user.competitions
                .find(uc => uc.competitionId === selectedCompetition.id);
            let compRole = userComp && userComp.competitionRole;
            return createPinpointRow(user, compRole);
        });
        const data = [pinpointColumns, ...rows]
        return (
            <CSVLink
                data={data}
                style={{ fontSize: "1.2rem" }}
            >
                Download Pinpoint
            </CSVLink>
        );
    };

    const renderDownloadLink = () => {
        const rows = userList.map(user => {
            const userComp = user.competitions.find(c => c.competitionId === selectedCompetition.id);
            const userTeam = userComp ? teams.list.find(t => t.id === userComp.teamId) : null;
            const userOrg = orgs.find(o => o.id === user.organizationId);
            const row = {
                ...user,
                competition: selectedCompetition.name,
                competitionRole: user.type === "ORG_MANAGER" ? "TEACHER" : userComp?.competitionRole,
                team: userTeam?.name,
                league: userTeam?.league,
                organization: userOrg?.name,
            }
            delete row.competitions;
            delete row.organizationId;
            return row;
        })
        return (
            <CSVLink
                data={rows}
                style={{ fontSize: "1.2rem" }}
            >
                Download Full
            </CSVLink>
        );
    };

    const renderItem = (user, userType) => {
        if (user.type === "ORG_MANAGER") {
            return (
                <MentorItem
                    key={user.id}
                    user={user}
                    selectedCompetition={selectedCompetition}
                />
            );
        }
        return (
            <UserItem
                key={user.id}
                user={user}
                competitionList={competitionList}
                selectedCompetition={selectedCompetition}
                userType={userType}
            />
        );
    };

    let parentsApproved = 0;
    let parentsDenied = 0;
    let parentsPending = 0;
    const usersWithParents = userList.filter(u => !!u.parent);
    const totalParents = usersWithParents.length;
    for (const user of usersWithParents) {
        switch (user.parent.approvalStatus) {
            case ParentConsentStatus.APPROVED:
                parentsApproved += 1;
                break;
            case ParentConsentStatus.DENIED:
                parentsDenied += 1;
                break;
            case ParentConsentStatus.PENDING:
                parentsPending += 1;
                break;
        }
    }

    return (
        <Item.Group divided>
            <Item>
                {`Users Found: ${userList.length}/${initialState.length}`}
                <br/>
                {`Parent Consent Pending:  ${parentsPending}/${totalParents}`}
                <br/>
                {`Parent Consent Approved: ${parentsApproved}/${totalParents}`}
                <br/>
                {`Parent Consent Denied:   ${parentsDenied}/${totalParents}`}
                <br/>
            </Item>
            <Item>
                {renderPinpointLink()}
            </Item>
            <Item>
                {renderDownloadLink()}
            </Item>
            {userList.map(renderItem)}
        </Item.Group>
    );
};

const pinpointColumns = [
    "Id", 
    "ChannelType", 
    "User.UserAttributes.FirstName", 
    "User.UserAttributes.LastName", 
    "Address", 
    "User.UserAttributes.UserType",
    "User.UserAttributes.CompetitionRole",
];
const createPinpointRow = (user, compRole) => [
    user.id,
    "EMAIL",
    user.firstName,
    user.lastName,
    user.email,
    user.type,
    user.type === "ORG_MANAGER" ? "TEACHER" : (compRole || "UNREGISTERED"),
];

export default UserList;
