import { DateTime } from "luxon";

export function generateCSVData(students, studentData) {
    const headers = [
        "Access Code (case-sensitive)",
        "Username",
        "First Name",
        "Last Name",
        "Email",
        "School or Organization",
        "Address",
        "City",
        "State/Province",
        "Mail Code",
        "Country",
        "Date of Birth (M/D/YYYY)",
        "Redeemed (DO NOT EDIT)"
    ];
    const available = students.filter(s => !s.redeemed).map(student => createStudentRow(student, studentData));
    const redeemed = students.filter(s => s.redeemed).map(student => createStudentRow(student, studentData));
    const data = [
        headers,
        ...available,
        ...redeemed,
    ];
    return data;
}

function createStudentRow(student, studentData) {
    const data = studentData.find(s => s._id === student.userId);
    if (student.redeemed && data !== undefined) {
        const dob = DateTime.fromISO(data._accountInfo.dateOfBirth, { zone: 'utc' });
        return [
            student.code,
            data._username,
            data._accountInfo.firstName,
            data._accountInfo.lastName,
            data._email,
            data._accountInfo.schoolOrOrganization,
            data._accountInfo.address.lineOne,
            data._accountInfo.address.city,
            data._accountInfo.address.stateOrProvince || undefined,
            data._accountInfo.address.zipCode,
            data._accountInfo.address.country,
            dob.toFormat('L/d/yyyy'),
            true,
        ];
    } else {
        return [
            student.code,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            false,
        ];
    }
}
