import {
  COMPETITION_LIST_LOADING,
  SET_COMPETITION_LIST,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  SET_COMPETITION_PRICE,
  SET_INVOICE_PRICE,
  SET_ORGANIZATION_PAYMENT_INFO,
  SET_PRICE_LOADING,
  SET_PAYMENT_LOADING,
  SET_RECEIPT,
  CLEAR_RECEIPT,
  ADD_DISCOUNT,
  DISCOUNT_LOADING,
  RECEIPT_LOADING,
  CLEAR_CART,
  SET_LICENSE_ITEMS,
  LICENSE_ITEMS_LOADING,
  CLEAR_ITEM_FROM_CART,
  ACADEMY_EVENTS_LOADING,
  SET_ACADEMY_EVENTS,
} from "./types";
import { ErrorTag } from "../utils/enums";
import { addError, clearErrors } from "./errors";
import { getToken, loadUserData } from "./user";
import { openConfirmation } from "./ui";
import { accessCodeSuccess } from "../utils/confirmations";

import authServer from "../api/auth";
import billingServer from "../api/billing";

const _checkAccessCode = async (code, token) => {
  try {
    const url = `/users/access-code?code=${encodeURIComponent(code)}`;
    const resp = await authServer.get(url, {
      headers: { Authorization: token },
    });
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export const _joinOrganization = async (accessCode, token) => {
  try {
    await authServer.patch(
      `/users/access-code?code=${encodeURIComponent(accessCode)}`,
      {},
      { headers: { Authorization: token } }
    );
  } catch (err) {
    throw err;
  }
};

const _joinCompetition = async (competitionId, accessCode, token) => {
  try {
    const status = accessCode ? "ACTIVE_UNPAID" : "ACTIVE";
    await authServer.post(
      "/users/join-competition",
      { competitionId, accessCode, status },
      { headers: { Authorization: token } }
    );
  } catch (err) {
    throw err;
  }
};

const _getCompetitionList = async () => {
  try {
    const url = "/competitions";
    const list = await billingServer.get(url);
    return list.data;
  } catch (err) {
    throw err;
  }
};

const _getAcademyEvents = async (token) => {
  try {
    const url = "/organizations/event";
    const list = await authServer.get(url, {
      headers: { Authorization: token },
    });
    return list.data;
  } catch (err) {
    throw err;
  }
};

const _getSelfPayEvents = async (token) => {
  try {
    const url = "/users/self-pay-events";
    const list = await authServer.get(url, {
      headers: { Authorization: token },
    });
    return list.data;
  } catch (err) {
    throw err;
  }
};

const _getDiscount = async (code, numStudents) => {
  try {
    const url = `/discount?code=${encodeURIComponent(code)}&num_students=${numStudents || 1
      }`;
    const discount = await billingServer.get(url);
    return discount.data;
  } catch (err) {
    throw err;
  }
};

const _getInvoicePrice = async (
  compId,
  orgId,
  numStudents,
  couponCode,
  discount,
  token
) => {
  try {
    let url =
      `/invoice-price?competition_id=${encodeURIComponent(compId)}` +
      `&organization_id=${encodeURIComponent(orgId)}` +
      `&num_students=${encodeURIComponent(numStudents)}`;
    if (couponCode) {
      url += `&coupon_code=${encodeURIComponent(couponCode)}`;
    }
    if (discount) {
      url += `&discount=${encodeURIComponent(discount)}`;
    }
    const result = await billingServer.get(url, {
      headers: { Authorization: token },
    });
    return result.data;
  } catch (err) {
    throw err;
  }
};

const _getCompetitionPrice = async (id, discountCode) => {
  try {
    let url = `/competition-price?competition_id=${id}`;
    if (discountCode) {
      url += `&discount_code=${discountCode}`;
    }
    const priceResp = await billingServer.get(url);
    return priceResp.data;
  } catch (err) {
    throw err;
  }
};

const _submitPayment = async (authToken, paymentInfo, cart, discountCode) => {
  try {
    const paymentResp = await authServer.post(
      "/payment",
      { paymentInfo, cart, discountCode },
      { headers: { Authorization: authToken } }
    );
    return paymentResp.data;
  } catch (err) {
    throw err;
  }
};

const _createInvoice = async (authToken, cart, discountCode) => {
  try {
    const paymentResp = await authServer.post(
      "/payment/invoice",
      { cart, discountCode },
      { headers: { Authorization: authToken } }
    );
    return paymentResp.data;
  } catch (err) {
    throw err;
  }
};

const _submitSelfPay = async (authToken, paymentInfo, price, discountCode) => {
  try {
    const paymentResp = await authServer.post(
      "/users/academy-license",
      { price, paymentToken: paymentInfo?.token, discountCode },
      { headers: { Authorization: authToken } }
    );
    return paymentResp.data;
  } catch (err) {
    throw err;
  }
};

const _getReceipt = async (token, paymentId) => {
  try {
    const receiptResp = await billingServer.get(
      `/receipt?payment_id=${encodeURIComponent(paymentId)}`,
      { headers: { Authorization: token } }
    );
    return receiptResp.data;
  } catch (err) {
    throw err;
  }
};

const _submitCompetitionPayment = async (
  token,
  competitionId,
  paymentInfo,
  discountCode
) => {
  try {
    const cardInfo = {
      number: paymentInfo.cardNumber,
      expiration: paymentInfo.expiration,
      code: paymentInfo.code,
    };
    const billingInfo = {
      firstName: paymentInfo.firstName,
      lastName: paymentInfo.lastName,
      address: paymentInfo.address,
      country: paymentInfo.country,
      city: paymentInfo.city,
      state: paymentInfo.stateOrProvince,
      zip: paymentInfo.zipCode,
    };
    const paymentResp = await billingServer.post(
      "/competition",
      { competitionId, cardInfo, billingInfo, discountCode },
      { headers: { Authorization: token } }
    );
    return paymentResp.data;
  } catch (err) {
    throw err;
  }
};

const _submitBulkCompetitionPayment = async (
  competitionId,
  orgId,
  numStudents,
  paymentInfo
) => {
  try {
    const cardInfo = {
      number: paymentInfo.cardNumber,
      expiration: paymentInfo.expiration,
      code: paymentInfo.code,
    };
    const billingInfo = {
      firstName: paymentInfo.firstName,
      lastName: paymentInfo.lastName,
      address: paymentInfo.address,
      country: paymentInfo.country,
      city: paymentInfo.city,
      state: paymentInfo.stateOrProvince,
      zip: paymentInfo.zipCode,
    };
    const paymentResp = await billingServer.post("/competition-bulk-pay", {
      competitionId,
      orgId,
      numStudents,
      cardInfo,
      billingInfo,
    });
    return paymentResp.data;
  } catch (err) {
    throw err;
  }
};

const _getLicenseItems = async (mode, token) => {
  try {
    const resp = await authServer.get(`/payment/license-items?mode=${mode}`, {
      headers: { Authorization: token },
    });
    return resp.data.map((item) => ({
      ...item,
      price: item.price / 100,
      seats: item.numSeats,
      accounts: item.numStudents,
    }));
  } catch (err) {
    throw err;
  }
};

const _getIndividualLicenseItems = async (token) => {
  try {
    const resp = await authServer.get(`/payment/license-items/individual`, {
      headers: { Authorization: token },
    });
    return resp.data.map((item) => ({
      ...item,
      price: item.price / 100,
      seats: item.numSeats,
      accounts: item.numStudents,
    }));
  } catch (err) {
    throw err;
  }
};

export const getCompetitionList = () => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.COMPETITION_STORE));
    dispatch({ type: COMPETITION_LIST_LOADING, payload: true });
    const list = await _getCompetitionList();
    dispatch({ type: SET_COMPETITION_LIST, payload: list });
  } catch (err) {
    err.tag = ErrorTag.COMPETITION_STORE;
    dispatch(addError(err));
  } finally {
    dispatch({ type: COMPETITION_LIST_LOADING, payload: false });
  }
};

export const getAcademyEvents = (licenses) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.ACADEMY_EVENT));
    dispatch({ type: ACADEMY_EVENTS_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;
    const [academyEvents, selfPayEvents] = await Promise.all([
      _getAcademyEvents(token),
      _getSelfPayEvents(token),
    ]);
    console.log("licenses", licenses);
    console.log("academy events", academyEvents);
    console.log("self pay events", selfPayEvents);
    let allEventIds = academyEvents.map(e => e.id);
    allEventIds = allEventIds.concat(selfPayEvents.filter(e => licenses.some(l => l.id === e.id)).map(e => e.id));
    console.log("all event ids", allEventIds);
    const list = [academyEvents, selfPayEvents]
      .flat()
      .filter((e) => allEventIds.some((id) => id === e.id));
    console.log("list", list);
    dispatch({ type: SET_ACADEMY_EVENTS, payload: list });
  } catch (err) {
    err.tag = ErrorTag.ACADEMY_EVENT;
    dispatch(addError(err));
  } finally {
    dispatch({ type: ACADEMY_EVENTS_LOADING, payload: false });
  }
};

export const getLicenseItems = () => async (dispatch, getState) => {
  try {
    dispatch(clearErrors(ErrorTag.FETCH_LICENSE_ITEMS));
    dispatch({ type: LICENSE_ITEMS_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;
    const mode = getState().organization.mode;
    const list = await _getLicenseItems(mode, token);
    dispatch({ type: SET_LICENSE_ITEMS, payload: list });
  } catch (err) {
    err.tag = ErrorTag.FETCH_LICENSE_ITEMS;
    dispatch(addError(err));
  } finally {
    dispatch({ type: LICENSE_ITEMS_LOADING, payload: false });
  }
};

export const getIndividualLicenseItems = () => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.FETCH_LICENSE_ITEMS));
    dispatch({ type: LICENSE_ITEMS_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;
    const list = await _getIndividualLicenseItems(token);
    dispatch({ type: SET_LICENSE_ITEMS, payload: list });
  } catch (err) {
    err.tag = ErrorTag.FETCH_LICENSE_ITEMS;
    dispatch(addError(err));
  } finally {
    dispatch({ type: LICENSE_ITEMS_LOADING, payload: false });
  }
};

export const addToCart = (id) => ({
  type: ADD_TO_CART,
  payload: id,
});

export const removeFromCart = (id) => ({
  type: REMOVE_FROM_CART,
  payload: id,
});
export const clearFromCart = (id) => ({
  type: CLEAR_ITEM_FROM_CART,
  payload: id,
});

export const addDiscount =
  (code, history, cb, numStudents) => async (dispatch) => {
    try {
      let token;
      if (!(token = getToken())) return;
      dispatch(clearErrors(ErrorTag.DISCOUNT_CODE));
      dispatch({ type: DISCOUNT_LOADING, payload: true });
      const accessCode = await _checkAccessCode(code, token);
      if (accessCode._tag === "Legacy") {
        const { competitionId } = accessCode;
        if (competitionId) {
          await _joinCompetition(competitionId, accessCode.code, token);
          dispatch(loadUserData());
          dispatch({ type: CLEAR_CART });
          history.push("/");
          dispatch(openConfirmation(accessCodeSuccess()));
          return cb();
        }
      }
      if (accessCode._tag === "Academy") {
        await _joinOrganization(accessCode.code, token);
        dispatch(loadUserData());
        dispatch({ type: CLEAR_CART });
        history.push("/");
        dispatch(openConfirmation(accessCodeSuccess()));
        return cb();
      }
      const discount = await _getDiscount(code, numStudents);
      dispatch({ type: ADD_DISCOUNT, payload: discount });
      cb();
    } catch (err) {
      err.tag = ErrorTag.DISCOUNT_CODE;
      dispatch(addError(err));
    } finally {
      dispatch({ type: DISCOUNT_LOADING, payload: false });
    }
  };

export const getCompetitionPrice = (id, discountCode) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.PRICE_LOOKUP));
    dispatch({ type: SET_PRICE_LOADING, payload: true });
    const priceInfo = await _getCompetitionPrice(id, discountCode);
    dispatch({ type: SET_COMPETITION_PRICE, payload: priceInfo });
  } catch (err) {
    err.tag = ErrorTag.PRICE_LOOKUP;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_PRICE_LOADING, payload: false });
  }
};

export const getInvoicePrice =
  (compId, orgId, numStudents, couponCode, discount) =>
    async (dispatch, getState) => {
      try {
        let token;
        if (!(token = getToken())) return;
        if (couponCode === "") {
          couponCode = null;
        }
        const user = getState().user;
        if (user.type === "ADMIN") {
          orgId = getState().organization.currentlyEditing;
        }
        dispatch(clearErrors(ErrorTag.PRICE_LOOKUP));
        dispatch({ type: SET_INVOICE_PRICE, payload: null });
        dispatch({ type: SET_PRICE_LOADING, payload: true });
        const priceInfo = await _getInvoicePrice(
          compId,
          orgId,
          numStudents,
          couponCode,
          discount,
          token
        );
        dispatch({ type: SET_INVOICE_PRICE, payload: priceInfo });
      } catch (err) {
        err.tag = ErrorTag.PRICE_LOOKUP;
        dispatch(addError(err));
      } finally {
        dispatch({ type: SET_PRICE_LOADING, payload: false });
      }
    };

export const setReceipt = (data) => ({
  type: SET_RECEIPT,
  payload: data,
});

export const clearReceipt = () => ({
  type: CLEAR_RECEIPT,
});

export const submitPayment =
  (paymentToken, cart, history) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.PAYMENT));
      dispatch({ type: SET_PAYMENT_LOADING, payload: true });
      let token;
      if (!(token = getToken())) return;

      const discountCode =
        cart.discounts.length > 0 ? cart.discounts[0].code : undefined;
      const paymentResult = await _submitPayment(
        token,
        paymentToken,
        cart,
        discountCode
      );
      dispatch({ type: CLEAR_CART });
      dispatch(setReceipt(paymentResult));
      dispatch(loadUserData());
      history.push(
        `/receipt?payment_id=${encodeURIComponent(paymentResult.id)}`
      );
    } catch (err) {
      err.tag = ErrorTag.PAYMENT;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_PAYMENT_LOADING, payload: false });
    }
  };

export const submitSelfPay =
  (paymentInfo, price, discountCode, history) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.PAYMENT));
      dispatch({ type: SET_PAYMENT_LOADING, payload: true });
      let token;
      if (!(token = getToken())) return;

      const paymentResult = await _submitSelfPay(
        token,
        paymentInfo,
        price,
        discountCode
      );
      console.log("payment result", paymentResult);
      dispatch({ type: CLEAR_CART });
      dispatch(setReceipt(paymentResult));
      dispatch(loadUserData());
      history.push(
        `/receipt?payment_id=${encodeURIComponent(paymentResult.id)}`
      );
    } catch (err) {
      err.tag = ErrorTag.PAYMENT;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_PAYMENT_LOADING, payload: false });
    }
  };

export const createInvoice = (cart, history) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.PAYMENT));
    dispatch({ type: SET_PAYMENT_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;

    const discountCode =
      cart.discounts.length > 0 ? cart.discounts[0].code : undefined;
    const invoiceResult = await _createInvoice(token, cart, discountCode);
    dispatch({ type: CLEAR_CART });
    dispatch(setReceipt(invoiceResult));
    dispatch(loadUserData());
    history.push(`/receipt?payment_id=${encodeURIComponent(invoiceResult.id)}`);
  } catch (err) {
    err.tag = ErrorTag.PAYMENT;
    dispatch(addError(err));
  } finally {
    dispatch({ type: SET_PAYMENT_LOADING, payload: false });
  }
};

export const getReceipt = (paymentId) => async (dispatch) => {
  try {
    dispatch(clearErrors(ErrorTag.RECEIPT));
    dispatch({ type: RECEIPT_LOADING, payload: true });
    let token;
    if (!(token = getToken())) return;

    const receipt = await _getReceipt(token, paymentId);
    dispatch(setReceipt(receipt));
  } catch (err) {
    err.tag = ErrorTag.RECEIPT;
    dispatch(addError(err));
  } finally {
    dispatch({ type: RECEIPT_LOADING, payload: false });
  }
};

export const submitCompetitionPayment =
  (compId, paymentInfo, discountCode) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.PAYMENT));
      dispatch({ type: SET_PAYMENT_LOADING, payload: true });
      let token;
      if (!(token = getToken())) return;

      const paymentResult = await _submitCompetitionPayment(
        token,
        compId,
        paymentInfo,
        discountCode
      );
      dispatch(setReceipt(paymentResult));
      dispatch(loadUserData());
    } catch (err) {
      err.tag = ErrorTag.PAYMENT;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_PAYMENT_LOADING, payload: false });
    }
  };

export const submitBulkCompetitionPayment =
  (compId, orgId, numStudents, paymentInfo) => async (dispatch) => {
    try {
      dispatch(clearErrors(ErrorTag.PAYMENT));
      dispatch({ type: SET_PAYMENT_LOADING, payload: true });

      const paymentResult = await _submitBulkCompetitionPayment(
        compId,
        orgId,
        numStudents,
        paymentInfo
      );
      dispatch(setReceipt(paymentResult));
      dispatch(loadUserData());
    } catch (err) {
      err.tag = ErrorTag.PAYMENT;
      dispatch(addError(err));
    } finally {
      dispatch({ type: SET_PAYMENT_LOADING, payload: false });
    }
  };

export const setOrganizationPaymentInfo = (orgInfo) => ({
  type: SET_ORGANIZATION_PAYMENT_INFO,
  payload: orgInfo,
});
