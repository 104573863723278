import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Button } from "semantic-ui-react";
import { LARGE_LOGO_URL } from "../utils/constants";

const Landing = () => {
    const history = useHistory();
    const location = useLocation();
    const loggedIn = useSelector((state) => state.user.loggedIn);

    useEffect(() => {
        if (loggedIn) {
            history.push("/");
        }
    }, [loggedIn]);

    const params = location.search;

    const handleIndividual = () => {
        history.push("/signup" + params);
    };

    const handleOrg = () => {
        history.push("/organization-registration" + params);
    };

    return (
        <Card className="dashboard-card" style={{
            padding: "2rem",
        }}>
            <Card.Content>
                <Card.Header>
                    <h1>Welcome to</h1>
                    <img src={LARGE_LOGO_URL} width="100%" />
                </Card.Header>
            </Card.Content>
            <Card.Content>
                <Button.Group
                    style={{ width: "100%" }}
                    size="huge"
                >
                    <Button
                        content="Individual"
                        onClick={handleIndividual}
                        primary
                    />
                    <Button.Or />
                    <Button
                        content="Organization"
                        onClick={handleOrg}
                        primary
                    />
                </Button.Group>
                <div>
                    <p style={{ fontSize: "1.5rem", width: "50%", display: "inline", float: "left", textAlign: "left" }}>Select this option if you are a student competiting in the event</p>
                    <p style={{ fontSize: "1.5rem", width: "50%", display: "inline" }}>Select this option if you are the manager of an organization</p>
                </div>
            </Card.Content>
        </Card>
    );
};

export default Landing;
