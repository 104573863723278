import React from "react";
import { useSelector } from 'react-redux';
import { getFieldError, getFormError } from "../utils/formErrors";
import { Form, Dropdown, Button } from "semantic-ui-react";

const OrgAddAccessCodesForm = ({
    formState,
    setFormState,
    errors,
    loading,
    compsLoading,
    compOptions,
    fetchPrice,
    isAdmin,
}) => {

    const currentOrg = useSelector((state) => {
        const id = state.organization.currentlyEditing;
        if (state.organization.list) {
            return state.organization.list.find(o => o.id === id);
        }
        return undefined;
    });

    const hasContactEmail = currentOrg && currentOrg.contactInfo && currentOrg.contactInfo.email;

    React.useEffect(() => {
        if (!hasContactEmail && formState.discount !== 1.0) {
            setFormState(s => ({ ...s, discount: 1.0 }));
        }
    }, [hasContactEmail, formState.discount]);

    const renderDiscountField = () => {
        if (hasContactEmail) {
            return(
                <Form.Input
                    label="Discount"
                    placeholder="Discount (0.0 - 1.0)"
                    type="number"
                    value={formState.discount}
                    onChange={({ target: { value } }) => setFormState({ ...formState, discount: value })}
                    error={(err = getFieldError("discount", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            );
        }
        return (
            <Form.Input
                label="Free Access Codes Only (org does not have email)"
                placeholder="Discount (0.0 - 1.0)"
                type="number"
                value={formState.discount}
                onChange={() => {}}
                error={(err = getFieldError("discount", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
        );
    }

    let err;

    return (
        <Form
            loading={loading}
            error={!!getFormError(errors)}
        >
            <Form.Field>
                <label>Event</label>
                <Dropdown
                    placeholder={
                        compsLoading
                            ? "Loading..."
                            : "Select Event"
                    }
                    search
                    selection
                    fluid
                    options={compOptions}
                    value={formState.competitionId}
                    onChange={(e, { value }) => setFormState({ ...formState, competitionId: value })}
                    error={(err = getFieldError("competitionId", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Field>
            <Form.Input
                label="Number of Codes"
                placeholder="Number of Codes"
                type="number"
                value={formState.numberOfCodes}
                onChange={({ target: { value } }) => setFormState({ ...formState, numberOfCodes: value })}
                error={(err = getFieldError("numberOfCodes", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            {(!isAdmin || hasContactEmail) && (
                <>
                    <Form.Group>
                        <Form.Field inline error={(err = getFieldError('couponCode', errors)) && { content: err, pointing: 'below' }}>
                            <label>Coupon Code</label>
                            <input
                                name="couponCode"
                                placeholder="Coupon Code"
                                value={formState.couponCode}
                                onChange={({ target: { value } }) => setFormState({ ...formState, couponCode: value })}
                            />
                        </Form.Field>
                        <Button
                            onClick={fetchPrice}
                            floated="right"
                            primary
                        >
                            Apply
                        </Button>
                    </Form.Group>
                    <div class="ui error message" style={{ display: err ? 'block' : 'none' }}>
                        <div class="header">Invalid Coupon Code</div>
                        <p>We could not find that coupon code. Make sure the capitalization is correct.</p>
                    </div>
                </>
            )}
            {isAdmin && renderDiscountField()}
        </Form>
    );
};

export default OrgAddAccessCodesForm;
