import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Grid } from "semantic-ui-react";
import TeamList from "../components/TeamList";
import Bulletin from "../components/Bulletin";
import OrgEvents from "../components/OrgEvents";
import Leaderboard from "../components/Leaderboard";
import OrgManagers from "../components/OrgManagers";
import LoginForm from "../components/LoginForm";
import { NavTab, UserType } from "../utils/enums";
import { LARGE_LOGO_URL } from "../utils/constants";
import OrgAcademyLicense from "../components/OrgAcademyLicense";
import { setActiveNavTab } from "../actions/ui";

const render = (userType) => {
    if (userType === UserType.ORG_MANAGER) {
        return (
            <>
                <Grid.Row>
                    <Grid.Column>
                        <TeamList />
                    </Grid.Column>
                    <Grid.Column>
                        <OrgAcademyLicense />
                    </Grid.Column>
                    <Grid.Column>
                        <Bulletin/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <OrgManagers />
                    </Grid.Column>
                </Grid.Row>
            </>
        );
    }
    return (
        <Grid.Row>
            <Grid.Column>
                <TeamList />
            </Grid.Column>
            <Grid.Column>
                <Bulletin />
            </Grid.Column>
            <Grid.Column>
                <Leaderboard />
            </Grid.Column>
        </Grid.Row>
    );
};

const renderTempAcademy = () => (
    <div>
        <img src={LARGE_LOGO_URL} width='100%' style={{
            marginTop: '5rem',
        }}/>
        <Card className="dashboard-card" centered style={{
            maxWidth: '700px',
        }}>
            <Card.Content>
                <Card.Header>Thanks for registering!</Card.Header>
            </Card.Content>
            <Card.Content>
                <Card.Description>
                    Licenses for Space Teams Academy will be available March 5th. Until then,
                    keep an eye out for email updates. You can email us
                    at spacecraft.vr@gmail.com if you have any questions.
                </Card.Description>
            </Card.Content>
        </Card>
    </div>
);

const Home = () => {
    const user = useSelector((state) => state.user);

    return (
        <Grid columns={3} stackable>
            {!user.loggedIn && (
                <div
                    style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "90vw",
                        background: "rgba(5, 5, 5, 0.5)"
                    }}
                >
                    <LoginForm />
                </div>
            )}
            {user.loggedIn && render(user.type)}
        </Grid>
    );
}

export default Home;
