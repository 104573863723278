import React from "react";
import { Segment, Container, Header, Divider } from "semantic-ui-react";
import PasswordResetForm from "../components/PasswordResetForm";

const PasswordReset = () => (
    <Segment className="form-background">
        <Container style={{ padding: "2rem 1rem" }}>
            <Header size="huge">Space Teams Password Reset</Header>
            <Divider style={{ marginBottom: "2rem" }} />
            <PasswordResetForm />
        </Container>
    </Segment>
);

export default PasswordReset;
