import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  getCompetitionList,
  addToCart,
  removeFromCart,
  getAcademyEvents,
} from "../actions/billing";
import { setActiveNavTab } from "../actions/ui";
import { ErrorTag, NavTab } from "../utils/enums";
import moment from "moment";
import { Button, Card, Message } from "semantic-ui-react";

import StoreList from "../components/StoreList";
import formatDate from "../utils/formatDate";
import { DateTime } from "luxon";
import { joinCompetition } from "../actions/competitions";
import { getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { getStudentLicenses } from "../actions/user";

const membershipIsValid = (membership) => {
  const now = moment.utc();
  const start = moment.utc(membership.start);
  const end = moment.utc(membership.end);
  return now > start && now < end;
};

const Store = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [hasLoaded, setHasLoaded] = useState(false);

  const user = useSelector((state) => state.user);
  const loading = useSelector(
    (state) => state.organization.academyEvents.loading
  );
  const competitionList = useSelector((state) => {
    const academyEvents = state.organization.academyEvents.list
      .filter((e) => !state.competitions.userComps.some((c) => c.id === e.id))
      .filter((e) => DateTime.fromISO(e.endDate).diffNow() > 0);
    // console.log('academy events', academyEvents)
    const selfPayEvents = state.user.licenses.list
      .flatMap((l) => {
        if (!l.isCompetitionLicense) {
          return [];
        }
        const comp = state.competitions.activeComps.find((c) => l.events.some(e => e.id === c.id));
        // console.log("comp", comp);
        return comp === undefined ? [] : [comp];
      })
      .filter((e) => !state.competitions.userComps.some((c) => c.id === e.id))
      .filter((e) => DateTime.fromISO(e.endDate).diffNow() > 0);
    // console.log('self pay events', selfPayEvents)
    const combined = academyEvents.concat(selfPayEvents.filter(e => !academyEvents.some(ae => ae.id === e.id)));
    // console.log('combined', combined)
    const hasSelfPayWithNoEvents = state.user.licenses.list.some(l => l.isSelfPay && l.events.length === 0);
    if (hasSelfPayWithNoEvents) {
      return combined.concat(state.competitions.activeComps
        .filter(comp => comp.isMembership)
        .filter(comp => !state.competitions.userComps.some(c => c.id === comp.id))
        .filter((e) => DateTime.fromISO(e.endDate).diffNow() > 0));
    }
    return combined;
  });
  const userComps = useSelector((state) => state.competitions.userComps);
  const joinLoading = useSelector((state) => state.competitions.loading);
  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.COMPETITIONS)
  );

  useEffect(() => {
    dispatch(setActiveNavTab(NavTab.EVENTS));
    dispatch(getStudentLicenses());
  }, []);

  useEffect(() => {
    if (user.licenses?.list?.length && !hasLoaded) {
      dispatch(getAcademyEvents(user.licenses.list));
      setHasLoaded(true);
    }
  }, [user.licenses, hasLoaded]);

  const handleJoin = (id) => {
    dispatch(joinCompetition(id, history));
  };

  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (competitionList.length === 0) {
      return (
        <Message
          header="No Events to Join"
          content="There are no new events to join"
        />
      );
    }
    return (
      <>
        {competitionList.map((event) => {
          return (
            <div key={event.id} style={{ paddingBottom: "2rem" }}>
              <h4>{event.name}</h4>
              <p>
                Start:{" "}
                {DateTime.fromISO(event.startDate).toFormat("LLL dd yyyy")}
              </p>
              <p>
                End: {DateTime.fromISO(event.endDate).toFormat("LLL dd yyyy")}
              </p>
              <Button
                primary
                loading={joinLoading}
                onClick={() => handleJoin(event.id)}
              >
                Join
              </Button>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <Card className="dashboard-card" centered>
      <Card.Content>
        <Card.Header>Events</Card.Header>
      </Card.Content>
      <Card.Content>
        {renderContent()}
        {errors && errors.length > 0 && (
          <Message error header="Join Error" content={getFormError(errors)} />
        )}
      </Card.Content>
    </Card>
  );
};

export default Store;
