import React from "react";
import { getFieldError, getFormError } from "../utils/formErrors";
import { Form, Dropdown } from "semantic-ui-react";

const OrgAddManagerForm = ({
    formState,
    setFormState,
    errors,
    loading,
}) => {

    let err;

    return (
        <Form
            loading={loading}
            error={!!getFormError(errors)}
        >
            <Form.Input
                label="Email"
                placeholder="Email"
                value={formState.email}
                onChange={({ target: { value } }) => setFormState({ ...formState, email: value })}
                error={(err = getFieldError("email", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
        </Form>
    );
};

export default OrgAddManagerForm;
