import React from "react";
import { getFieldError, getFormError } from "../utils/formErrors";
import { Form, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";

const TransferOwnershipForm = ({
    formState,
    setFormState,
    loading,
    errors,
    team,
}) => {

    const user = useSelector((state) => state.user);
    const members = team ? team.members : [];
    const memberOpts = members
        .filter(m => m.userId !== user.id)
        .map(m => ({
            key: m.userId,
            text: m.username,
            value: m.userId,
        }));

    return (
        <Form
            loading={loading}
            error={!!getFormError(errors)}
        >
            <Dropdown
                selection
                search
                fluid
                placeholder="New Owner"
                value={formState.newOwner}
                onChange={(e, { value }) => setFormState({ ...formState, newOwner: value })}
                options={memberOpts}
            />
        </Form>
    );
};

export default TransferOwnershipForm;
