/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Icon, Grid, Message } from "semantic-ui-react";
import { getTaggedErrors } from "../selectors/errors";
import { inviteOrgManager } from "../actions/organization";
import { getAllActiveCompetitions } from "../actions/competitions";
import { ErrorTag } from "../utils/enums";

import OrgAddManagerForm from "./OrgAddManagerForm";

const AddManagerModal = ({
    open,
    onOpen,
    onClose,
    trigger
}) => {

    const dispatch = useDispatch();

    const defaultData = { email: "" };
    const [formState, setFormState] = React.useState({ ...defaultData });
    const organizationId = useSelector((state) => state.user.organizationId);
    const loading = useSelector((state) => state.organization.addManagerLoading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.ADD_ORG_MANAGER));
    const isAdmin = useSelector((state) => state.user.type === "ADMIN");
    const adminOrgId = useSelector((state) => state.organization.currentlyEditing);

    let orgId = organizationId;
    if(isAdmin) {
        orgId = adminOrgId;
    }

    const handleClose = () => {
        setFormState({ ...defaultData });
        onClose();
    };

    const renderButton = () => {
        return (
            <Button
                primary
                onClick={() => dispatch(inviteOrgManager(orgId, formState, isAdmin, handleClose))}
                disabled={formState.numberOfCodes === 0}
                loading={loading}
            >
                <Icon name="plus"/>
                Add
            </Button>
        );
    };

    const renderForm = () => {
        return (
            <OrgAddManagerForm
                formState={formState}
                setFormState={setFormState}
                errors={errors}
                loading={loading}
            />
        );
    };

    return (

        <Modal
            open={open}
            onOpen={onOpen}
            onClose={handleClose}
            trigger={trigger}
        >
            <Modal.Header>
                <Grid>
                    <Grid.Column width={12}>
                        Add Manager
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button
                            icon="close"
                            onClick={handleClose}
                            floated="right"
                        />
                    </Grid.Column>
                </Grid>
            </Modal.Header>
            <Modal.Content>
                {renderForm()}
                {!!errors && (
                    <Message
                        error
                        header="Add Manager Error"
                        content={errors[0].message || "Unknown error"}
                    />
                )}
            </Modal.Content>
            <Modal.Actions>
                {renderButton()}
            </Modal.Actions>
        </Modal>
    );
};

export default AddManagerModal;
