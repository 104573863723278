import store from '../store';
import messageServer from '../api/messages';
import { useQuery } from "react-query";
import { getToken } from "./token";
import { SET_ANNOUNCEMENTS } from '../actions/types';

export const useAnnouncements = () => useQuery({
  queryKey: ['announcements'],
  queryFn: getAnnouncements,
  refetchOnMount: false,
});

async function getAnnouncements() {
  try {
    const token = getToken();
    if (!token) {
      return {};
    }
    const announcements = await messageServer.get("/announcements", {
      headers: {
        Authorization: token
      }
    });
    store.dispatch({
      type: SET_ANNOUNCEMENTS,
      paylaod: announcements.data,
    });
    return announcements.data;
  } catch (err) {
    throw err;
  }
}
