/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Checkbox, Divider, Grid, GridColumn, GridRow, Form, Dropdown, Input } from "semantic-ui-react";
import {
    setActiveNavTab
} from "../actions/ui";
import { loadUserData, completeActivityTask } from "../actions/user";
import { activityNames } from "../utils/constants";
import { NavTab, UserType } from "../utils/enums";
import { Tab as LearningPlatformTab } from './Academy';
import { getCompetitionLeaderboard } from '../actions/competitions';
import { useAcceptTerms } from "../queries/accept-study-terms";
import { useTeams } from "../queries/get-teams";

const serverActivityName = (name) => {
    switch (name) {
        case 'Planet Builder':
            return 'Planet Builder';
        case 'Spacecraft Builder':
            return 'Spacecraft Builder';
        case 'Trajectory Designer':
            return 'Orbit Designer';
        case 'Orbital Descent':
            return 'Orbital Descent';
        case 'Habitat Builder':
            return 'Habitat Builder';
        case 'Surface Operations':
            return 'Surface Operations';
        case 'Finale':
            return 'Finale';
    }
}

const Competitions = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const selectedCompetition = useSelector((state) => state.competitions.currentCompetition);
    const isAcademy = !selectedCompetition.isMembership;
    const user = useSelector((state) => state.user);
    const userComp = useSelector((state) => state.competitions.userComps.find(c => selectedCompetition && c.id === selectedCompetition.id));
    const teams = useTeams();
    const team = teams.data?.find(t => t.id === userComp?.teamId);

    const leaderboard = useSelector((state) => state.competitions.leaderboard.list);
    const leaderboardLoading = useSelector((state) => state.competitions.leaderboard.loading);

    const [pageSize, setPageSize] = useState('letter');

    const studentName = encodeURIComponent(`${user.firstName} ${user.lastName}`);
    const competition = encodeURIComponent(selectedCompetition?.name);
    const teamName = encodeURIComponent(team?.name);
    const league = encodeURIComponent(team?.league);
    const completionCertificateUrl = `https://certificates.spacecraft-vr.com?cert_type=completion&student_name=${studentName}`
        + `&competition=${competition}`
        + `&page_size=${pageSize}`;
    console.log('REQUIRE', selectedCompetition?.settings?.requireActivities);
    let shouldShowCertificate = false;
    console.log('============ START CERT ===============');
    let hasCompletedRequiredActivities = true;
    const requiredActivities = selectedCompetition?.settings?.requireActivities;
    requiredActivities["Finale"] = {
        Survey: true,
    };
    console.log('COMPLETED', user.completedActivities);
    for (const [activityName, tasks] of Object.entries(selectedCompetition?.settings?.requireActivities ?? {})) {
        if (!hasCompletedRequiredActivities) {
            break;
        }
        for (const [taskName, required] of Object.entries(tasks)) {
            if (!required) {
                continue;
            }
            const completed = user.completedActivities.some(a => serverActivityName(a.name) === activityName && a.task.toLowerCase() === taskName.toLowerCase());
            console.log(activityName, tasks, user.completedActivities, taskName, completed);
            if (!completed) {
                console.log('NOT COMPLETE');
                hasCompletedRequiredActivities = false;
                break;
            }
        }
    }
    shouldShowCertificate = hasCompletedRequiredActivities;
    console.log('============= END CERT ================');
    console.log('should show certificate', shouldShowCertificate);


    React.useEffect(() => {
        dispatch(setActiveNavTab(NavTab.COMPETITIONS));
        dispatch(loadUserData());
        if (selectedCompetition) {
            dispatch(getCompetitionLeaderboard(selectedCompetition?.id));
        }
    }, [selectedCompetition?.id]);

    const [place, setPlace] = useState(0);
    const placeCertificateUrl = place ? `https://certificates.spacecraft-vr.com?cert_type=place&student_name=${studentName}`
        + `&competition=${competition}`
        + `&team=${teamName}`
        + `&league=${league}`
        + `&page_size=${pageSize}`
        + `&place=${place}` : undefined;
    React.useEffect(() => {
        if (!leaderboardLoading && leaderboard && userComp && user.type === UserType.USER) {
            const lb = leaderboard.filter(t => t.league === userComp.league);
            console.log('userComp', userComp);
            console.log('leaderboard', leaderboard);
            console.log('lb', lb);
            console.log('place', place);
            for (let i = 0; i < lb.length; i++) {
                if (i >= 3) {
                    break;
                }
                const t = lb[i];
                if (t.team.id === userComp.teamId) {
                    setPlace(i + 1);
                }
            }
        }
    }, [leaderboard, leaderboardLoading, team, userComp, user]);
    const formatPlace = p => {
        switch (p) {
            case 1:
                return '1st';
            case 2:
                return '2nd';
            case 3:
                return '3rd';
            default:
                return '';
        }
    }

    const [studentNameInput, setStudentNameInput] = useState("");
    const acceptStudyTerms = useAcceptTerms();
    const hasMadeStudyChoice = user.hasAcceptedStudy !== undefined;
    const [studyAcceptanceOpen, setStudyAcceptanceOpen] = useState(!hasMadeStudyChoice);

    const handleAcceptStudyTerms = (accepted) => {
        acceptStudyTerms.mutate(accepted)
    }

    useEffect(() => {
        if (acceptStudyTerms.isSuccess) {
            dispatch(loadUserData());
            setStudyAcceptanceOpen(false);
        }
    }, [acceptStudyTerms.isSuccess]);

    const ActivityRow = ({ name, linkName, onClick, activityName, taskName, link, noCheckbox }) => {
        const isDone = user.completedActivities.find(a => a.eventId === selectedCompetition.id && a.name === activityName && a.task === taskName) !== undefined;
        const isInRequiredOptions = activityName && activityNames.some(a => a === serverActivityName(activityName));
        let isRequired = false;
        if (taskName === "Survey") {
            isRequired = true;
        } else if (isInRequiredOptions) {
            const requiredTasks = selectedCompetition?.settings?.requireActivities[serverActivityName(activityName)];
            const serverTaskName = taskName.toLowerCase();
            isRequired = requiredTasks !== undefined && requiredTasks[serverTaskName];
        }
        return (
            <>
                <GridRow>
                    <GridColumn width={6}>
                        <p>{name}</p>
                    </GridColumn>
                    <GridColumn width={4}>
                        {linkName ? (
                            link ? (
                                <Button fluid primary as='a' href={link} target="_blank">{linkName}</Button>
                            ) : (
                                <Button fluid onClick={onClick} primary>{linkName}</Button>
                            )
                        ) : <p>Complete in Application</p>}
                    </GridColumn>
                    <GridColumn width={2}>
                        {isRequired && !noCheckbox && user.type !== UserType.ADMIN && (<Checkbox
                            label="Complete"
                            checked={isDone}
                            onClick={() => { }}
                        />)}
                    </GridColumn>
                </GridRow>
            </>
        )
    }

    const Activity = ({ title, tabName, activityName, quizLink, before, after }) => {
        return (
            <>
                <GridRow>
                    <GridColumn>
                        <h2>{title}</h2>
                    </GridColumn>
                </GridRow>
                {before && before.map(data => (
                    <ActivityRow
                        name={data.name}
                        linkName={data.name}
                        link={data.link}
                        noCheckbox={true}
                    />
                ))}
                <ActivityRow
                    name="Lesson"
                    linkName="Watch"
                    activityName={activityName}
                    taskName="Lesson"
                    onClick={() => history.push(`/learning-platform?tab=${tabName}&content=Lesson`)}
                />
                <ActivityRow
                    name="Quiz"
                    linkName="Take Quiz"
                    activityName={activityName}
                    taskName="Quiz"
                    onClick={() => {
                        window.location.assign(quizLink);
                        const taskName = 'Quiz';
                        const isSame = (a) => (
                            a.eventId === selectedCompetition.id
                            && a.name === activityName
                            && a.task === taskName
                        );
                        const alreadyDone = user.completedActivities.find(a => isSame(a));
                        if (!alreadyDone) {
                            dispatch(completeActivityTask(selectedCompetition.id, activityName, taskName));
                        }
                    }}
                />
                <ActivityRow
                    name="Tutorial"
                    linkName="Watch"
                    activityName={activityName}
                    taskName="Tutorial"
                    onClick={() => history.push(`/learning-platform?tab=${tabName}&content=Tutorial`)}
                />
                <ActivityRow
                    name="Activity"
                    activityName={activityName}
                    taskName="Activity"
                />
                {after && after.map(data => (
                    <ActivityRow
                        name={data.name}
                        linkName={data.name}
                        link={data.link}
                        noCheckbox={true}
                    />
                ))}
            </>
        )
    }

    const makeQuizLink = (activityName) => {
        return `https://dashboard.spacecraft-vr.com/quiz?userId=${encodeURIComponent(user?.id)}&eventId=${encodeURIComponent(selectedCompetition?.id)}&teamId=${encodeURIComponent(team?.id)}&activityName=${encodeURIComponent(activityName)}`;
    }

    const renderContent = () => {
        if (!selectedCompetition || !selectedCompetition.id) {
            return (
                <Card.Content>
                    <Card.Header>You Must Select an Event</Card.Header>
                    <Card.Description>If you are not registered for an event, use the 'Events' tab above</Card.Description>
                </Card.Content>
            )
        }

        const now = new Date();
        const endDate = new Date(selectedCompetition.endDate);
        console.log('end date', selectedCompetition.endDate, endDate);

        return (
            <>
                <Card.Content>
                    <Card.Description style={{
                        fontSize: "1.8rem",
                        marginTop: "1.8rem",
                        marginBottom: "3rem",
                        textAlign: "center",
                    }}>
                        <Grid>
                            {(user.type === UserType.USER && user.nasaOrg) && (
                                <GridRow>
                                    {studyAcceptanceOpen ? (<GridColumn style={{
                                        textAlign: 'left',
                                    }}>
                                        <Button onClick={() => setStudyAcceptanceOpen(false)}>Hide</Button>
                                        <h1>TEXAS A&M UNIVERSITY HUMAN RESEARCH PROTECTION PROGRAM</h1>
                                        <div>
                                            <p><strong>Study Title</strong>: Engaging, Inspiring, and Training a Diverse and Inclusive Future STEM Workforce</p>
                                            <p><strong>Researchers contact information</strong>: Drs. Greg Chamitoff, Manish Dixit, & Kayla Rollins
                                                Sponsor: NASA</p>

                                            <h2>Why am I being asked to be in this study?</h2>
                                            <p>A research study is usually done to find a better way to help or treat people or to understand
                                                how well something works. You are being asked to take part in this research study because you are participating in Space Teams Labs program.</p>

                                            <h2>Why is this study being done?</h2>
                                            <p>The purpose of this study is to improve and learn about the impact of the Space Teams Labs program.</p>

                                            <h2>How long will the study last?</h2>
                                            <p>About 20 minutes after you complete the Space Teams Labs program.</p>

                                            <h2>What will I be asked to do in this study, if I agree to participate?</h2>
                                            <p>If it is okay with you and you agree to join this study, you will be asked to complete an online
                                                survey. Also, while you are participating in the Space Teams Lab program, the software will
                                                collect data on time spent on modules, number of sessions completed, and scores obtained per
                                                activity.</p>

                                            <h2>What should I know about a research study?</h2>
                                            <p>Your participation in this research is completely up to you. It is your choice whether or not to be
                                                in this research study. If you decide you do not want to participate, no one will be upset and
                                                there will be no penalty. You can ask all the questions you want before you decide.</p>

                                            <h2>What other choices do I have?</h2>
                                            <p>Instead of being in this research study, your choices may include: participate in the Space
                                                Teams Labs program and not complete the survey or have data collected on your participation.</p>

                                            <h2>What happens if I say “Yes”, but I change my mind later?</h2>
                                            <p>If you say yes know, you can still change your mind later without any penalty. Specifically, your
                                                choice not to be in this study will not negatively affect your present or future status with your class, school or STEM program (or Texas A&M University).</p>

                                            <h2>Could bad things happen to me if I join this study?</h2>
                                            <p>There is nothing bad that will happen to you.</p>

                                            <h2>What happens if I say “No, I do not want to be in this research”?</h2>
                                            <p>You can still participate in the Space Teams Labs program but your data will not be used.
                                                Could this research help me?
                                                This research will not help you, but we do hope to learn something new from this research.
                                                Someday we hope the information from this research will help improve the Space Teams Labs
                                                Experience for future students.</p>

                                            <h2>What happens to the information collected for the research?</h2>
                                            <p>Steps will be taken to limit the use of any personal information, including research study records, to only the people who have a need to see this information. We cannot promise complete secrecy, but keeping your information private is the goal.</p>

                                            <h2>Who can I talk to?</h2>
                                            <p>If you have questions, concerns, or complaints, about the research, talk to the research team at
                                                kcbraziel@tamu.edu and 979-845-8384.</p>

                                            <p>If your questions, concerns, or complaints are not being answered by the research team; or you
                                                want to talk to someone besides the research team; or you have questions about your rights as
                                                a research participant. you may call the Texas A&M University Human Research Protection
                                                Program (HRPP) by phone at 1-979-458-4067, toll free at 1-855-795-8636, or by email at
                                                irb@tamu.edu</p>

                                            <h2>Electronic Student Consent</h2>
                                            <p>Please type your name in the box below and click "Accept" to indicate your agreement to participate in this
                                                research.</p>
                                            <Form>
                                                <Input
                                                    name="studentName"
                                                    value={studentNameInput}
                                                    onChange={(e) => setStudentNameInput(e.target.value)}
                                                />
                                                <Button
                                                    disabled={acceptStudyTerms.isLoading || studentNameInput.trim() != decodeURIComponent(studentName).trim()}
                                                    onClick={() => handleAcceptStudyTerms(true)}
                                                >
                                                    Accept
                                                </Button>
                                            </Form>
                                            <p>Or click "Decline" below to decline</p>
                                            <Button
                                                onClick={() => handleAcceptStudyTerms(false)}
                                                disabled={acceptStudyTerms.isLoading}
                                            >
                                                Decline
                                            </Button>
                                        </div>
                                    </GridColumn>
                                    ) : (
                                        <GridColumn>
                                            {user.hasAcceptedStudy ? (
                                                <p>You have accepted the terms and conditions of the study</p>
                                            ) : (
                                                <p>You have declined the terms and conditions of the study</p>
                                            )}
                                            <Button onClick={() => setStudyAcceptanceOpen(true)}>Change Response</Button>
                                        </GridColumn>
                                    )}
                                </GridRow>
                            )}
                            <GridRow>
                                <GridColumn>
                                    <h2>Getting Started</h2>
                                </GridColumn>
                            </GridRow>
                            <ActivityRow
                                name="Space Teams Welcome Video"
                                linkName="Watch"
                                activityName="Welcome"
                                taskName="Welcome Video"
                                onClick={() => history.push(`/learning-platform?tab=Welcome&content=Video`)}
                            />
                            <ActivityRow
                                name="Countdown to Launch Checklist"
                                linkName="View"
                                activityName="Welcome"
                                taskName="Countdown Checklist"
                                onClick={() => history.push(`/learning-platform?tab=${encodeURIComponent("Getting Started")}&content=${encodeURIComponent("Launch Countdown")}`)}
                            />
                            <ActivityRow
                                name="Getting Started Video"
                                linkName="Watch"
                                activityName="Welcome"
                                taskName="Getting Started Video"
                                onClick={() => history.push(`/learning-platform?tab=${encodeURIComponent('Getting Started')}&content=${encodeURIComponent('Getting Started')}`)}
                            />
                            {!isAcademy && (<GridRow>
                                <GridColumn>
                                    <h2>Team Submissions</h2>
                                </GridColumn>
                            </GridRow>)}
                            {!isAcademy && (<ActivityRow
                                name="Team Patch & Photo Upload"
                                linkName="Submit"
                                link="https://docs.google.com/forms/d/e/1FAIpQLSflOccuxMFn9qtUusvdAK0pyPMzl42fCPCyyyB-BW2FPVw_ig/viewform?usp=sf_link"
                                noCheckbox={true}
                            />)}
                            {!isAcademy && (<ActivityRow
                                name="Team Video Upload"
                                linkName="Submit"
                                link="https://docs.google.com/forms/d/e/1FAIpQLSej-GxoEUeUZkx25OzpPzq2W7ooa4_OMqzKobqaGRgUuohJoQ/viewform?usp=sf_link"
                                noCheckbox={true}
                            />)}
                            <Divider />
                            <Divider />
                            <Activity
                                title="Activity 1 - Planetary Science"
                                activityName="Planet Builder"
                                tabName={LearningPlatformTab.PLANETS}
                                // quizLink={makeQuizLink("Planet Builder")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/cHF57JQFHaecQhAE8"
                                    : "https://forms.gle/3uqQvPVEYcwufsEk6"
                                }
                                before={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/live/Ojl0Zl4hZEs?si=a_4X-ZcDOY89NhiU",
                                        name: "Kickoff Webcast",
                                    }
                                ]}
                                after={isAcademy ? [
                                ] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/live/ail05sOTFDw?si=l7kRSN2OS1VKOdbE",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://forms.gle/123MPH3kEy3W5X9g8",
                                        name: "Daily Reflection",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLScff2fKnqGJJIWbyozqK87bYB_Zg8dktEv7QTDubmgiJoUxdQ/viewform?usp=sf_link",
                                        name: "Daily Uploads - Most Incredible Planet",
                                    },
                                ]}
                            />
                            <Divider />
                            <Activity
                                title="Activity 2 - Spacecraft Design"
                                activityName="Spacecraft Builder"
                                tabName={LearningPlatformTab.SPACECRAFT}
                                // quizLink={makeQuizLink("Spacecraft Builder")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/va5XXzjeYvfy49qQ9"
                                    : "https://forms.gle/pPAVW8Tq8rNkEaZBA"
                                }
                                before={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/watch?v=gpJyQE09Mog",
                                        name: "Kickoff Webcast",
                                    }
                                ]}
                                after={isAcademy ? [
                                ] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/watch?v=VXBTuEUMlQA",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://forms.gle/NYnWs7RoHd483Qoo7",
                                        name: "Daily Reflection",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLSf6hrs53MwF0V0UrI86JR69XJCLG_fp7uz0Derfjk52VyOtaA/viewform?usp=sf_link",
                                        name: "Daily Uploads - Coolest Spacecraft",
                                    },
                                ]}
                            />
                            <Divider />
                            <Activity
                                title="Activity 3 - Trajectory Design"
                                activityName="Trajectory Designer"
                                tabName={LearningPlatformTab.ORBITS}
                                // quizLink={makeQuizLink("Trajectory Designer")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/aF2BkweGxUmt3Xgv9"
                                    : "https://forms.gle/NYYeeDY3i5q2aZVB7"
                                }
                                before={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/watch?v=eohuAAbaZNI",
                                        name: "Kickoff Webcast",
                                    },
                                ]}
                                after={isAcademy ? [] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/watch?v=PEaM26b428c",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://forms.gle/B5sBVshmefpbLkis6",
                                        name: "Daily Reflection",
                                    },
                                ]}
                            />
                            <Divider />
                            <Activity
                                title="Activity 4 - Orbital Descent"
                                activityName="Orbital Descent"
                                tabName={LearningPlatformTab.LANDING}
                                // quizLink={makeQuizLink("Orbital Descent")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/tVnFgD6Bg7DReJ519"
                                    : "https://forms.gle/jchTv7nR1j14Lq4MA"
                                }
                                before={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/watch?v=7LAw6md6eWc",
                                        name: "Kickoff Webcast",
                                    },
                                ]}
                                after={isAcademy ? [] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/watch?v=p6hA49oBBu0",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLSecHN_-QnLpjNns3oukbBVMehj1rLvJus_UnrYHTCXN8mpWlA/viewform?usp=sf_link",
                                        name: "Daily Reflection",
                                    },
                                ]}
                            />
                            <Divider />
                            <Activity
                                title="Activity 5 - Habitat Builder"
                                activityName="Habitat Builder"
                                tabName={LearningPlatformTab.HABITAT}
                                // quizLink={makeQuizLink("Habitat Builder")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/UNKntd1mZd9uxPa7A"
                                    : "https://forms.gle/g9Zo4G574PQCz7ZM7"
                                }
                                before={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/watch?v=ISqpXYxZ2Yg",
                                        name: "Kickoff Webcast",
                                    },
                                ]}
                                after={isAcademy ? [
                                ] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/watch?v=jtzAhyTqu1I",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLSd5Fj65orF_O_LQ2T2Vd9j1GJqj9XzDQqf0fFyCwYIJKlTR3w/viewform?usp=sf_link",
                                        name: "Daily Reflection",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLSdMRSS805PZQSXU01jCKG_FkVaB6sNVpyu9NC5mAQt6MjM_mw/viewform?usp=sf_link",
                                        name: "Daily Uploads - Most Amazing Surface Habitat",
                                    },
                                ]}
                            />
                            <Divider />
                            <Activity
                                title="Activity 6 - Surface Operations"
                                activityName="Surface Operations"
                                tabName={LearningPlatformTab.SUSTAINABILITY}
                                // quizLink={makeQuizLink("Surface Operations")}
                                quizLink={user.nasaOrg
                                    ? "https://forms.gle/7JZ4mZg1FY5krDzn7"
                                    : "https://forms.gle/Y9Jf3bSddKFaPMZ6A"
                                }
                                ore={isAcademy ? [] : [
                                    {
                                        link: "https://www.youtube.com/watch?v=M_SFK2oF9_w",
                                        name: "Kickoff Webcast",
                                    },
                                ]}
                                after={isAcademy ? [] : [
                                    {
                                        link: "https://forms.gle/A9CeuzPQPXPgCWah8",
                                        name: "Questions for Speakers",
                                    },
                                    {
                                        link: "https://www.youtube.com/watch?v=S9g8w3Qk5o8",
                                        name: "Speaker Webcast",
                                    },
                                    {
                                        link: "https://docs.google.com/forms/d/e/1FAIpQLSeWvx83AHinRfAlDO_dPXNRLp3Cx5fBQ6OL2mHLtRxqQ9FzWA/viewform?usp=sf_link",
                                        name: "Daily Reflection",
                                    },
                                ]}
                            />
                            <GridRow>
                                <GridColumn>
                                    <h2>Finale</h2>
                                </GridColumn>
                            </GridRow>
                            <ActivityRow
                                name="Survey"
                                linkName="Take Survey"
                                activityName="Finale"
                                taskName="Survey"
                                onClick={() => {
                                    const surveyLink = user.nasaOrg ? "https://tamucehd.qualtrics.com/jfe/form/SV_6lP4eu3lpVNpwou" : "https://forms.gle/LUWdpG45PKxinkDM9";
                                    const taskName = 'Survey';
                                    const isSame = (a) => (
                                        a.eventId === selectedCompetition.id
                                        && a.name === 'Finale'
                                        && a.task === taskName
                                    );
                                    const alreadyDone = user.completedActivities.find(a => isSame(a));
                                    if (!alreadyDone) {
                                        dispatch(completeActivityTask(selectedCompetition.id, 'Finale', taskName));
                                    }
                                    window.location.assign(surveyLink);
                                }}
                            />
                            {!isAcademy && (<ActivityRow
                                name="Awards Ceremony"
                                linkName="Join"
                                activityName="Finale"
                                taskName="Awards Ceremony"
                                link={"https://www.youtube.com/watch?v=jMoIF0Q1rBU"}
                                noCheckbox={true}
                            />)}
                            <GridRow>
                                <GridColumn>
                                    <h2>Awards</h2>
                                </GridColumn>
                            </GridRow>
                            {shouldShowCertificate ? (<>
                                <GridRow>
                                    <GridColumn style={{ fontSize: '1rem' }}>
                                        <Form.Field>
                                            <label style={{ marginRight: '1rem' }}>Page Size</label>
                                            <Dropdown
                                                selection
                                                search
                                                placeholder="Select Page Size"
                                                value={pageSize}
                                                onChange={(e, { value }) => setPageSize(value)}
                                                options={[
                                                    { key: 'letter', value: 'letter', text: 'Letter (8.5x11)' },
                                                    { key: 'a4', value: 'a4', text: 'A4' },
                                                ]}
                                            />
                                        </Form.Field>
                                    </GridColumn>
                                </GridRow>
                                <ActivityRow
                                    name="Certificate of Completion"
                                    linkName="Download"
                                    activityName="Awards"
                                    taskName="Certificate of Completion"
                                    link={completionCertificateUrl}
                                    noCheckbox={true}
                                />
                                {place !== undefined && now.getTime() >= endDate && (
                                    <ActivityRow
                                        name={`${formatPlace(place)} Place Award`}
                                        linkName="Download"
                                        activityName="Awards"
                                        taskName={`${formatPlace(place)} Place Award`}
                                        link={placeCertificateUrl}
                                        noCheckbox={true}
                                    />
                                )}
                            </>
                            ) : (
                                <GridRow>
                                    <GridColumn>
                                        <p>Finish the required activities to earn your certificate</p>
                                    </GridColumn>
                                </GridRow>
                            )}
                        </Grid>
                    </Card.Description>
                </Card.Content>
            </>
        )
    }

    return (
        <div>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Card.Header>Activities</Card.Header>
                </Card.Content>
                {renderContent()}
            </Card>
        </div>
    );
};

export default Competitions;
