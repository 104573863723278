import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Dropdown, Form, Message } from "semantic-ui-react";
import { uploadStudents } from "../actions/organization";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag, OrgMode } from "../utils/enums";
import { CSVLink } from "react-csv";
import { setActiveNavTab } from "../actions/ui";
import { useOrganization } from "../queries/get-organization";

const Loading = () => {
  return <p>Loading...</p>;
};

const UploadStudents = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const organization = useOrganization(user.organizationId);
  const orgLoading = organization.isLoading;
  const org = organization.data;
  const orgMode = useSelector((state) => state.organization.mode);
  const comp = useSelector((state) => state.competitions.currentCompetition);
  const errors = useSelector((state) =>
    getTaggedErrors(state.errors, ErrorTag.STUDENT_UPLOAD)
  );
  const loading = useSelector(
    (state) => state.organization.studentUploadLoading
  );

  console.log('organization', organization.data);
  const compsList = organization.data?.license?.events ?? [];

  const [event, setEvent] = useState(null);
  const [filename, setFilename] = useState(null);
  const [file, setFile] = useState(null);
  const [errorData, setErrorData] = useState(null);
  const [submitError, setSubmitError] = useState(null);

  useEffect(() => {
    dispatch(setActiveNavTab(null));
  }, []);

  if (user.loading || orgLoading) {
    return <Loading />;
  }

  const handleAcceptedFiles = (files) => {
    setErrorData(null);
    const file = files[0];
    setFile(file);
    setFilename(file.name);
  };

  const clearFile = () => {
    setFilename(null);
    setFile(null);
  };

  const handleUpload = () => {
    if (!event) {
      setSubmitError('You must select an event above');
    } else {
      setSubmitError(null);
    }
    setErrorData(null);
    const onError = (data) => {
      console.log("data", data);
      clearFile();
      setErrorData(data);
    };
    dispatch(uploadStudents(org.id, event?.id, file, onError, history));
  };

  const uploadError = () => {
    return (
      <>
        <Message
          error
          header="Oops! Looks like there was a problem with that file"
          content={
            "Click the link below to download the CSV and check the new 'Errors'" +
            "column to see what went wrong."
          }
        />
        <CSVLink
          data={errorData}
          style={{ fontSize: "1.2rem" }}
          disabled={loading}
        >
          Download CSV
        </CSVLink>
      </>
    );
  };

  const renderFilezone = () => {
    if (file !== null) {
      return (
        <div style={{ paddingTop: "2rem" }}>
          <h4>Uploaded File:</h4>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button color="red" onClick={clearFile} icon="delete" />
            <p style={{ fontSize: "1.2rem", padding: "1rem" }}>{filename}</p>
          </div>
        </div>
      );
    }
    return (
      <>
        <Dropzone
          onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps }) => (
            <section
              style={{
                padding: "4rem",
                cursor: "pointer",
              }}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button>Click here to upload</Button>
              </div>
            </section>
          )}
        </Dropzone>
        <div>
          <p style={{ fontSize: "1.2rem" }}>
            The CSV template can be downloaded from the homepage using the
            'Download Codes' link in the 'Academy License' section. For each
            available code in that spreadsheet that you would like to use, fill
            out the required fields. The rows at the bottom that already contain
            data are students already registered to your organization. You can
            leave these as they will be ignored. Any codes in rows that you do
            not fill out will remain available. Make sure not to touch the last
            column titled 'Redeemed (DO NOT EDIT)' as this is used internally.
          </p>
          <small style={{ fontSize: "1rem" }}>
            NOTE: If you are using a Mac OS computer, please ensure that the file you are uploading is a CSV UTF-8 file. Your browser will attempt to automatically download the spreadsheet from the ‘Download Codes’ as a .numbers file. If this happens for you then please follow the following steps:

            <ol>
              <li>
                Open the newly generated Academy License spreadsheet
                (This will open in the Numbers application)
              </li>
              <li>
                Click on ‘File’ and then choose ‘Export’
              </li>
              <li>
                Export your spreadsheet as a CSV UTF-8 file by clicking on the CSV tab in your exporting options and choosing Unicode (UTF-8) as your Text Encoding
              </li>
              <li>
                Now upload your newly made CSV file
              </li>
            </ol>
          </small>
          <br/><br/>
          <small style={{ fontSize: "1rem" }}>
            NOTE: Uploading students via this Upload Students feature will grant them a temporary password for them to use as their first-time login.
            This temporary password is “SpaceTeams”
          </small>
          <br/><br/>
          <small style={{ fontSize: "1rem" }}>
            NOTE: This operation is all or nothing, so any errors in the CSV
            will cause the entire operation to fail. If there are errors with
            the upload, you will have the opportunity to download a copy of the
            CSV with the errors listed by each violating row. You can use this
            copy to fix these rows and try the upload again.
          </small>
        </div>
      </>
    );
  };

  return (
    <Card className="dashboard-card">
      <Card.Content>
        <Card.Header>Upload Students</Card.Header>
      </Card.Content>
      <Card.Content>
        <Form>
          {orgMode === OrgMode.ACADEMY && (<Form.Field inline style={{ fontSize: "1.4rem" }}>
            <label>Add to Event</label>
            <Dropdown
              name="event"
              text={event ? event.name : "None"}
              value={event}
            >
              <Dropdown.Menu>
                <Dropdown.Item text="None" onClick={() => setEvent(null)} />
                {compsList.map((c) => (
                  <Dropdown.Item
                    key={c.id}
                    text={c.name}
                    onClick={() => setEvent(c)}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Field>)}
        </Form>
        {renderFilezone()}
      </Card.Content>
      {!!errorData && <Card.Content>{uploadError()}</Card.Content>}
      {!!errors && (
        <Card.Content>
          <Message
            error
            header="Upload Students Error"
            content={errors[0].message || "Unknown error"}
          />
        </Card.Content>
      )}
      <Card.Content>
        <Button
          onClick={handleUpload}
          primary
          fluid
          disabled={file === null}
          loading={loading}
          style={{ maxWidth: "20rem" }}
        >
          Submit
        </Button>
        {submitError && (
          <Message
            error
            header="Upload Students Error"
            content={submitError}
          />
        )}
      </Card.Content>
    </Card>
  );
};

export default UploadStudents;
