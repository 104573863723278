import { SET_ACTIVE_NAV_TAB } from '../actions/types';
import { logout } from '../actions/user';
import store from '../store';

export const getToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    store.dispatch(logout());
    store.dispatch({ type: SET_ACTIVE_NAV_TAB, payload: "Home" });
    return null;
  }
  return token;
};
