/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CSVLink } from "react-csv";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Button } from "semantic-ui-react";
import { fetchAccessCodes } from "../actions/organization";

const OrgAccessCodes = ({ hideAddButton }) => {

    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector((state) => state.user);
    const { loggedIn, organizationId } = user;
    const accessCodes = useSelector((state) => state.organization.accessCodes);
    const loading = useSelector((state) => state.organization.accessCodesLoading);
    const competitions = useSelector((state) => state.competitions.activeComps);
    const currentComp = useSelector((state) => state.competitions.currentCompetition);

    React.useEffect(() => {
        if (loggedIn && !hideAddButton) {
            dispatch(fetchAccessCodes(organizationId));
        }
    }, [loggedIn]);

    const renderContent = () => {
        if (loading) {
            return "Loading...";
        }
        const compNames = competitions.reduce(
            (acc, c) => ({ ...acc, [c.id]: c.name }), {});
        const data = [
            ["Access Code (case-sensitive)", "Competition", "Email"],
            ...accessCodes.map(c => [c.code, compNames[c.competition], c.email])
        ];
        const numUsed = accessCodes.reduce((sum, c) => c.email ? (sum + 1) : sum, 0);
        return (
            <React.Fragment>
                <p style={{ fontSize: "1.2rem" }}>
                    {`Used Codes: ${numUsed} / ${accessCodes.length}`}
                </p>
                <CSVLink
                    data={data}
                    style={{ fontSize: "1.2rem" }}
                    disabled={loading}
                >
                    Download Codes
                </CSVLink>
            </React.Fragment>
        );
    };

    const addHeader = () => {
        if (currentComp && currentComp.isMembership) {
            return "License";
        } else {
            return "Access Codes";
        }
    }
    const addButtonText = () => {
        if (currentComp && currentComp.isMembership) {
            return "Manage License";
        } else {
            return "Add Codes";
        }
    }
    const handleAddClick = () => {
        if (currentComp && currentComp.isMembership) {
            history.push('/academy-licenses');
        } else {
            history.push('/add-access-codes');
        }
    }

    return (
        <Card className={`dashboard-card ${loading ? "dashboard-card--loading": ""}`} centered>
            <Card.Content>
                <Card.Header>{addHeader()}</Card.Header>
            </Card.Content>
            <Card.Content>
                {renderContent()}
            </Card.Content>
            {!hideAddButton && (
                <Card.Content extra>
                    <Button primary onClick={handleAddClick}>{addButtonText()}</Button>
                </Card.Content>
            )}
        </Card>
    );
}

export default OrgAccessCodes;
