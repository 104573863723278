import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { ErrorTag } from "../utils/enums";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import {
    Message,
    Form,
    Dropdown
} from "semantic-ui-react";
import countries from "../utils/countries";
import states from "../utils/us-states";
import { getSchoolsAndOrgs, addSchoolOrOrg } from "../actions/user";

const AddOrganizationForm = ({
    formState,
    setFormState
}) => {

    const dispatch = useDispatch();
    const schoolsAndOrgs = useSelector((state) => state.user.schoolsAndOrgs.list);
    const schoolsAndOrgsLoading = useSelector((state) => state.user.schoolsAndOrgs.loading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.CREATE_ORG));

    const countryOpts = countries.map(c => ({ key: c.name, text: c.name, value: c.name }));
    const schoolsAndOrgsOpts = schoolsAndOrgs.map(so => ({ key: so, text: so, value: so }));

    React.useEffect(() => {
        dispatch(getSchoolsAndOrgs());
    }, []);

    let err;

    return (

        <Form error={!!getFormError(errors)}>

            <Form.Input
                label="Organization Name"
                placeholder="Organization Name"
                value={formState.name}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, name: value }))}
                error={(err = getFieldError("name", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                label="Organization Nickname"
                placeholder="Organization Nickname"
                value={formState.nickname}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, nickname: value }))}
                error={(err = getFieldError("nickname", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Field>
                <label>Country</label>
                <Dropdown
                    selection
                    search
                    placeholder="Select Country"
                    value={formState.country}
                    onChange={(e, { value }) => setFormState(s => ({ ...s, country: value }))}
                    options={countryOpts}
                    error={(err = getFieldError("country", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Field>
            <Message
                error
                header="Registration Error"
                content={getFormError(errors)}
            />

        </Form>
    );

};

export default AddOrganizationForm;
