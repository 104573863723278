import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    Grid,
    Message,
    Container,
    Card,
    Item,
    Loader,
    Input,
    Dropdown,
    Button,
    Form,
    Header,
} from "semantic-ui-react";

import {
    getAffiliates,
    createAffiliate,
    updateAffiliate,
    removeAffiliate,
    createCoupon,
    updateCoupon,
    removeCoupon,
} from "../actions/affiliates";
import { getFieldError, getFormError } from "../utils/formErrors";
import { getTaggedErrors } from "../selectors/errors";
import { ErrorTag } from "../utils/enums";
import { clearErrors } from "../actions/errors";
import { openAddAffiliateModal } from "../actions/ui";

const Search = ({ search, setSearch, clear }) => {
    return (
        <Grid style={{ marginTop: "1rem" }}>
            <Grid.Row>
                <Grid.Column width={14}>
                    <Input
                        placeholder="Search"
                        fluid
                        value={search}
                        onChange={(e, { value }) => setSearch(value)}
                    />
                </Grid.Column>
                <Grid.Column width={2}>
                    <Button icon="close" onClick={clear} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

const KeyValue = ({ keyName, value }) => {
    return (
        <p>
            <span
                style={{
                    fontWeight: "bold",
                    fontSize: "1rem",
                }}
            >
                {`${keyName}: `}
            </span>
            {value}
        </p>
    );
};

const CouponTierItem = ({ amount, limit }) => (
    <div>
        <p>{`${amount * 100}% up to ${limit}`}</p>
    </div>
);

const EditCouponForm = ({
    formState,
    setFormState,
    newTierFormState,
    setNewTierFormState,
    updateLoading,
    handleAddTier,
    isAddingCouponTier,
    setIsAddingCouponTier,
    cancelAddingCouponTier,
    errors
}) => {
    let err;
    const setTierState = (i) => (updatedTier) => {
        setFormState(s => ({
            ...s,
            tiers: [
                ...formState.tiers.slice(0, i),
                { ...updatedTier },
                ...formState.tiers.slice(i + 1),
            ],
        }));
    };
    return (
        <Form error={!!getFormError(errors)}>
            <Form.Input
                label="Coupon Code"
                placeholder="Coupon Code"
                value={formState.code}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, code: value }))}
                error={(err = getFieldError("code", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                label="Default Discount"
                placeholder="Default Discount"
                value={formState.defaultDiscount}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, defaultDiscount: value }))}
                error={(err = getFieldError("defaultDiscount", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <div style={{
                marginLeft: "1rem",
            }}>
                <Item.Header>Tiers</Item.Header>
                {formState.tiers.map((tier, i) => (
                    <CouponTierForm
                        key={i}
                        tier={tier}
                        setTierState={setTierState(i)}
                    />
                ))}
                {!isAddingCouponTier && (
                    <Button
                        content="New Tier"
                        icon="plus"
                        primary
                        loading={updateLoading}
                        onClick={() => setIsAddingCouponTier(true)}
                    />
                )}
                {isAddingCouponTier && (
                    <React.Fragment>
                        <CouponTierForm
                            tier={newTierFormState}
                            setTierState={setNewTierFormState}
                        />
                        <Button
                            content="Add Tier"
                            primary
                            loading={updateLoading}
                            onClick={() => handleAddTier()}
                        />
                        <Button
                            content="Cancel"
                            onClick={cancelAddingCouponTier}
                        />
                    </React.Fragment>
                )}
            </div>
        </Form>
    )
};

const CouponItem = ({
    coupon,
    affiliateId,
    updateCoupon,
    isEditing,
    handleEditClick,
    handleCancelEditing,
    isAddingCouponTier,
    setIsAddingCouponTier,
    updateLoading,
    formState,
    setFormState,
    newTierFormState,
    setNewTierFormState,
    handleAddTier,
    cancelAddingCouponTier,
    handleRemoveCoupon,
    errors,
}) => {
    const renderEditButtons = () => {
        return (
            <React.Fragment>
                <Button
                    content={isEditing ? "Save" : "Edit"}
                    primary={isEditing}
                    loading={isEditing && updateLoading}
                    onClick={isEditing
                        ? updateCoupon
                        : handleEditClick
                    }
                />
                {isEditing && (
                    <Button
                        content="Cancel"
                        onClick={handleCancelEditing}
                    />
                )}
            </React.Fragment>
        );
    };
    if (isEditing) {
        return (
            <Item key={coupon.code} >
                <Item.Content>
                    <Item.Description>
                        <EditCouponForm
                            formState={formState}
                            setFormState={setFormState}
                            newTierFormState={newTierFormState}
                            setNewTierFormState={setNewTierFormState}
                            updateLoading={updateLoading}
                            handleAddTier={handleAddTier}
                            isAddingCouponTier={isAddingCouponTier}
                            setIsAddingCouponTier={setIsAddingCouponTier}
                            cancelAddingCouponTier={cancelAddingCouponTier}
                            errors={errors}
                        />
                    </Item.Description>
                    <Item.Extra>
                        {renderEditButtons()}
                        {errors && errors.length > 0 && errors.map(e => (
                            <Message error>{e.message}</Message>
                        ))}
                    </Item.Extra>
                </Item.Content>
            </Item >
        );
    }
    return (
        <React.Fragment>
            <Item key={coupon.code} >
                <Item.Content>
                    <Item.Description>
                        <KeyValue keyName="Code" value={coupon.code} />
                        <KeyValue keyName="Default Discount" value={coupon.defaultDiscount} />
                    </Item.Description>
                </Item.Content>
            </Item>
            {coupon.tiers.length > 0 && (
                <div style={{
                    marginBottom: "1rem",
                }}>
                    <Header size="tiny">Tiers</Header>
                    <Item.Group divided style={{
                        marginLeft: "2.2rem",
                        marginTop: "-1rem",
                    }}>
                        {coupon.tiers.map(t => (
                            <CouponTierItem
                                key={t.id}
                                amount={t.amount}
                                limit={t.upTo}
                            />
                        ))}
                    </Item.Group>
                </div>
            )}
            <div>
                {renderEditButtons()}
                <Button
                    content="Remove"
                    icon="delete"
                    color="red"
                    onClick={() => handleRemoveCoupon(affiliateId, coupon.code)}
                />
            </div>
        </React.Fragment>
    )
};

const CouponTierForm = ({ tier, setTierState, errors }) => {
    let err;
    return (
        <React.Fragment>
            <Form.Group>
                <Form.Input
                    label="Discount"
                    placeholder="Discount"
                    value={tier.amount}
                    onChange={({ target: { value } }) =>
                        setTierState({ ...tier, amount: value })
                    }
                    error={(err = getFieldError("amount", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
                <div style={{
                    marginTop: "2.7rem",
                }}>
                    <p>Up To</p>
                </div>
                <Form.Input
                    label="Limit"
                    placeholder="Limit"
                    value={tier.upTo}
                    onChange={({ target: { value } }) =>
                        setTierState({ ...tier, upTo: value })
                    }
                    error={(err = getFieldError("upTo", errors)) && {
                        content: err,
                        pointing: "below"
                    }}
                />
            </Form.Group>
        </React.Fragment>
    );
};

const AddCouponForm = ({
    formState,
    setFormState,
    errors
}) => {
    let err;
    return (
        <Form error={!!getFormError(errors)}>
            <Form.Input
                label="Coupon Code"
                placeholder="Coupon Code"
                value={formState.code}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, code: value }))}
                error={(err = getFieldError("code", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
            <Form.Input
                label="Default Discount"
                placeholder="Default Discount"
                value={formState.defaultDiscount}
                onChange={({ target: { value } }) => setFormState(s => ({ ...s, defaultDiscount: value }))}
                error={(err = getFieldError("defaultDiscount", errors)) && {
                    content: err,
                    pointing: "below"
                }}
            />
        </Form>
    )
};

const AffiliateItem = ({ affiliate }) => {
    const updateLoading = useSelector((state) => state.affiliates.updateLoading);
    const errors = useSelector((state) => getTaggedErrors(state.errors, ErrorTag.AFFILIATE_UPDATE));
    const dispatch = useDispatch();

    const [isEditing, setIsEditing] = React.useState(false);
    const [isAddingCoupon, setIsAddingCoupon] = React.useState(false);
    const [isAddingCouponTier, setIsAddingCouponTier] = React.useState(false);
    const initialFormState = {
        name: affiliate.name || "",
        code: affiliate.code || "",
    };
    const initialCouponFormState = {
        affiliateId: "",
        code: "",
        defaultDiscount: "",
        tiers: [],
    };
    const initialCouponTierState = {
        amount: "",
        upTo: "",
    };
    const [formState, setFormState] = React.useState({ ...initialFormState });
    const [couponFormState, setCouponFormState] = React.useState({ ...initialCouponFormState });
    const [editingCouponCode, setEditingCouponCode] = React.useState("");
    const [newCouponTierFormState, setNewCouponTierFormState] =
        React.useState({ ...initialCouponTierState });

    const handleEditButtonClick = () => {
        if (isEditing) {
            dispatch(updateAffiliate(affiliate.id, formState, () => {
                setIsEditing(false);
                dispatch(getAffiliates());
                setFormState({ ...initialFormState });
            }));
        } else {
            setIsEditing(true);
        }
    }

    const handleCancelEditing = () => {
        setIsEditing(false);
        setFormState({ ...initialFormState });
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
    };

    const handleAddCouponClick = (affiliateId) => {
        setCouponFormState(s => ({ ...s, affiliateId }));
        setIsAddingCoupon(true);
    };

    const handleAddCoupon = () => {
        dispatch(createCoupon(couponFormState, () => {
            dispatch(getAffiliates());
            setCouponFormState({ ...initialCouponFormState });
            setIsAddingCoupon(false);
        }));
    };

    const handleRemoveAffiliate = (id) => {
        dispatch(removeAffiliate(id, () => {
            dispatch(getAffiliates());
        }));
    };

    const handleEditCouponClick = (affiliateId, coupon) => {
        setCouponFormState({ ...coupon, affiliateId });
        setEditingCouponCode(coupon.code);
    };

    const handleCancelEditingCoupon = () => {
        setCouponFormState({ ...initialCouponFormState });
        setEditingCouponCode("");
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
    };

    const cancelAddingCouponTier = () => {
        setNewCouponTierFormState({ ...initialCouponTierState });
        setIsAddingCouponTier(false);
        dispatch(clearErrors(ErrorTag.AFFILIATE_UPDATE));
    };

    const handleEditCoupon = () => {
        dispatch(updateCoupon({ ...couponFormState }, () => {
            dispatch(getAffiliates());
            setCouponFormState({ ...initialCouponFormState });
            setEditingCouponCode("");
        }));
    };

    const handleRemoveCoupon = (affiliateId, code) => {
        dispatch(removeCoupon(affiliateId, code, () => {
            dispatch(getAffiliates());
        }));
    };

    const handleAddTier = () => {
        setCouponFormState({
            ...couponFormState,
            tiers: [
                ...couponFormState.tiers, newCouponTierFormState
            ],
        });
        setNewCouponTierFormState({ ...initialCouponTierState });
        setIsAddingCouponTier(false);
    };

    const renderEditButton = () => (
        <Item.Extra>
            <Button
                onClick={() => handleEditButtonClick()}
                primary={!!isEditing}
                content={isEditing ? "Save" : "Edit"}
                loading={updateLoading}
            />
        </Item.Extra>
    );

    if (isEditing) {
        let err;
        return (
            <Item>
                <Item.Content>
                    <Item.Header>{affiliate.name}</Item.Header>
                    <Item.Description>
                        <Form error={!!getFormError(errors)}>
                            <Form.Input
                                label="Affiliate Name"
                                placeholder="Affiliate Name"
                                value={formState.name}
                                onChange={({ target: { value } }) => setFormState(s => ({ ...s, name: value }))}
                                error={(err = getFieldError("name", errors)) && {
                                    content: err,
                                    pointing: "below"
                                }}
                            />
                            <Form.Input
                                label="Affiliate Code"
                                placeholder="Affiliate Code"
                                value={formState.code}
                                onChange={({ target: { value } }) => setFormState(s => ({ ...s, code: value }))}
                                error={(err = getFieldError("name", errors)) && {
                                    content: err,
                                    pointing: "below"
                                }}
                            />
                        </Form>
                    </Item.Description>
                    {renderEditButton()}
                    <Button
                        content="Cancel"
                        onClick={handleCancelEditing}
                    />
                    {errors && errors.length > 0 && errors.map(e => (
                        <Message error>{e.message}</Message>
                    ))}
                </Item.Content>
            </Item>
        );
    }

    return (
        <React.Fragment>
            <Item>
                <Item.Content>
                    <Item.Header>{affiliate.name}</Item.Header>
                    <Item.Description>
                        <KeyValue keyName="ID" value={affiliate.id} />
                        <KeyValue keyName="Name" value={affiliate.name} />
                        <KeyValue keyName="Code" value={affiliate.code} />
                        {renderEditButton()}
                        <Button
                            content="Remove"
                            icon="delete"
                            color="red"
                            onClick={() => handleRemoveAffiliate(affiliate.id)}
                        />
                    </Item.Description>
                </Item.Content>
            </Item>
            <div style={{ marginLeft: "1rem" }}>
                <Header size="small">Coupons</Header>
                <Item.Group divided style={{
                    marginTop: "-1rem",
                    marginLeft: "1.2rem",
                }}>
                    {affiliate.coupons.map(coupon => (
                        <CouponItem
                            key={coupon.code}
                            coupon={coupon}
                            affiliateId={affiliate.id}
                            updateCoupon={handleEditCoupon}
                            isEditing={coupon.code === editingCouponCode}
                            isAddingCouponTier={isAddingCouponTier}
                            setIsAddingCouponTier={setIsAddingCouponTier}
                            formState={couponFormState}
                            setFormState={setCouponFormState}
                            newTierFormState={newCouponTierFormState}
                            setNewTierFormState={setNewCouponTierFormState}
                            handleAddTier={handleAddTier}
                            updateLoading={updateLoading}
                            handleEditClick={() =>
                                handleEditCouponClick(affiliate.id, coupon)}
                            handleCancelEditing={handleCancelEditingCoupon}
                            handleRemoveCoupon={handleRemoveCoupon}
                            cancelAddingCouponTier={cancelAddingCouponTier}
                            errors={errors}
                        />
                    ))}
                </Item.Group>
                <div style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                }}>
                    {isAddingCoupon && (
                        <React.Fragment>
                            <AddCouponForm
                                formState={couponFormState}
                                setFormState={setCouponFormState}
                                updateLoading={updateLoading}
                                errors={errors}
                            />
                            <Button
                                onClick={handleAddCoupon}
                                primary
                                content="Add"
                                loading={updateLoading}
                                style={{ marginTop: "1rem" }}
                            />
                            <Button
                                onClick={() => setIsAddingCoupon(false)}
                                content="Cancel"
                                style={{ marginTop: "1rem" }}
                            />
                            {errors && errors.length > 0 && errors.map(e => (
                                <Message error>{e.message}</Message>
                            ))}
                        </React.Fragment>
                    )}
                    {!isAddingCoupon && (
                        <Button
                            onClick={() => handleAddCouponClick(affiliate.id)}
                            primary
                            icon="plus"
                            content="Add Coupon"
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

const AffiliateList = ({ affiliates, search }) => {
    const [affiliateList, setAffiliateList] = React.useState(affiliates);
    const [isFiltered, setIsFiltered] = React.useState(false);
    React.useEffect(() => {
        if (search !== "") {
            const regex = new RegExp(search, "i");
            setAffiliateList(affiliateList.filter(a => regex.test(a.name)));
            setIsFiltered(true);
        } else if (isFiltered && search === "") {
            setAffiliateList(affiliates);
            setIsFiltered(false);
        }
    }, [search]);

    return (
        <Item.Group divided>
            {affiliateList
                .map(a => (
                    <AffiliateItem
                        key={a.id}
                        affiliate={a}
                    />
                ))}
        </Item.Group>
    );
};


const Affiliates = () => {

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.affiliates.loading);
    const affiliates = useSelector((state) =>
        state.affiliates.list.sort((a, b) => a.name > b.name ? 1 : -1)
    );

    const [hasLoaded, setHasLoaded] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const clearSearch = () => {
        setSearch("");
    };

    // Loading
    React.useEffect(() => {
        if (!loading && !hasLoaded) {
            dispatch(getAffiliates());
            setHasLoaded(true);
        }
    }, [loading, hasLoaded]);

    const renderAffiliateList = () => {
        if (loading) {
            return <Loader />;
        }
        return (
            <AffiliateList
                affiliates={affiliates}
                search={search}
            />
        );
    };

    return (
        <Container>
            <Card
                className="dashboard-card"
                centered
            >
                <Card.Content>
                    <Grid style={{ marginTop: "1rem" }}>
                        <Grid.Row>
                            <Grid.Column width={10}>
                                <h3>Affiliate Management</h3>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button
                                    content="Add Affiliate"
                                    icon="plus"
                                    primary
                                    onClick={() => dispatch(openAddAffiliateModal())}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button
                                    icon="refresh"
                                    onClick={() => dispatch(getAffiliates())}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Card.Content>
                <Card.Content>
                    <h4>Search</h4>
                    <Search
                        search={search}
                        setSearch={setSearch}
                        clear={clearSearch}
                    />
                </Card.Content>
                <Card.Content>
                    {renderAffiliateList()}
                </Card.Content>
            </Card>
        </Container>
    );
};

export default Affiliates;
